import { useState, useCallback, useEffect } from 'react'
import { Button, Modal } from 'react-bootstrap'
import { Formik, Form } from 'formik'
import { toast } from 'react-toastify'

import { useEgresosConsolidados } from 'hooks'

import { SelectV2 } from 'components/v2'
import { TextareaBS } from 'components'

const initialValues = {
  id_estado_control_gestion: '',
  observacion_control_gestion: '',
}

const ControlGestionModal = ({ show, onHide, idOrdenEgreso, valores = {}, obtenerEgresosConsolidados, soloObservacion = false }) => {
  const [formikValues, setFormikValues] = useState(initialValues)

  const { handleObtenerEstadosControlGestion, handleActualizarEstadoControlGestion, handleActualizarObservacionControlGestion } = useEgresosConsolidados()
  const [estados, setEstados] = useState([])

  const actualizarControlGestion = async (values) => {
    try {
      let response = null

      if (soloObservacion) {
        const data = {
          observacion_control_gestion: values?.observacion_control_gestion,
        }
        response = await handleActualizarObservacionControlGestion(idOrdenEgreso, data)
      } else {
        const data = {
          id_estado_control_gestion: values.id_estado_control_gestion,
          observacion_control_gestion: values?.observacion_control_gestion,
        }
        response = await handleActualizarEstadoControlGestion(idOrdenEgreso, data)
      }

      toast.success(response.data.message)
    } catch (error) {
      toast.error(error.message)
    } finally {
      onHide()
      obtenerEgresosConsolidados()
    }
  }

  const obtenerEstadosControlGestion = useCallback(async () => {
    try {
      const response = await handleObtenerEstadosControlGestion()
      const data = response.data.result.map(d => ({
        value: d.id,
        label: d.label,
      }))

      setEstados(data)
    } catch (error) {
      setEstados([])
      toast.error(error.message)
    }
  }, [handleObtenerEstadosControlGestion])

  useEffect(() => {
    if (!soloObservacion) {
      obtenerEstadosControlGestion()
    }
  }, [soloObservacion, obtenerEstadosControlGestion])

  useEffect(() => {
    setFormikValues({
      ...initialValues,
      id_estado_control_gestion: valores?.id_estado_control_gestion || initialValues.id_estado_control_gestion,
      observacion_control_gestion: valores?.observacion_control_gestion || initialValues?.observacion_control_gestion,
    })
  }, [valores])

  if (!show) return null

  return (
    <Modal show={show} onHide={onHide} size='md' backdrop='static' centered>
      <Modal.Header closeButton>
        <Modal.Title>Control de gestión para OE #{idOrdenEgreso}</Modal.Title>
      </Modal.Header>

      <Modal.Body>
        <Formik
          initialValues={formikValues}
          onSubmit={actualizarControlGestion}
          enableReinitialize
        >
          {({ values, errors }) => {
            return (
              <Form>
                {!soloObservacion &&
                  <div className='row'>
                    <SelectV2
                      id='id_estado_control_gestion'
                      name='id_estado_control_gestion'
                      col='col-12'
                      label='Estado'
                      options={estados}
                      placeholder='Seleccionar un estado'
                    />
                  </div>
                }

                <div className={`row ${!soloObservacion ? 'mt-6' : ''}`}>
                  <div className='col-12'>
                    <TextareaBS id='observacion_control_gestion' name='observacion_control_gestion' label='Observación' rows={5} />
                  </div>
                </div>

                <div className='separator my-6' />

                <div className='row mt-6'>
                  <div className='col text-end'>
                    <Button
                      type='button'
                      variant='secondary'
                      className='me-3'
                      onClick={onHide}
                    >
                      <i className='bi bi-slash-circle me-1' />
                      Cancelar
                    </Button>

                    <Button type='submit' variant='primary'>
                      <i className='bi bi-save me-1' />
                      Guardar
                    </Button>
                  </div>
                </div>
              </Form>
            )
          }}
        </Formik>
      </Modal.Body>
    </Modal>
  )
}

export default ControlGestionModal