import { useCallback } from 'react'
import { toast } from 'react-toastify'
import Swal from 'sweetalert2'

import { useOutOrders } from 'hooks'

import { mostrarAdvertencia } from './utils'

export const useOrdenes = () => {
  const { loading, handleGetOutOrders, handleLiberarReserva } = useOutOrders()

  const obtenerOrdenes = useCallback(async (params = {}) => {
    try {
      const response = await handleGetOutOrders(params)
      return response
    } catch (error) {
      toast.error(error.message)
      throw error // Propaga el error
    }
  }, [handleGetOutOrders])

  const liberarReservaOrden = useCallback(async (idEgreso) => {
    try {
      const resp = await mostrarAdvertencia({
        html: `¿Liberar reserva de la <strong>OE #${idEgreso}</strong>?
          <p class='mt-2'>Se va a liberar todo el stock reservado por la misma</p>
          <p class='mt-4 text-muted'><strong>Atención:</strong> La Orden de egreso #${idEgreso} será CANCELADA si el Pedido de venta tiene una Orden en RESERVA PARCIAL. Sino, pasará al estado RESERVA PARCIAL.</p>
        `,
        showDenyButton: true,
        denyButtonText: 'No, cancelar',
        confirmButtonText: 'Si, liberar',
      })

      if (resp.isConfirmed) {
        const response = await handleLiberarReserva(idEgreso)
        toast.success(response.data.message)
      }
    } catch (error) {
      toast.error(error.message)
      throw error // Propaga el error
    }
  }, [handleLiberarReserva])

  return {
    loading,
    obtenerOrdenes,
    liberarReservaOrden,
  }
}