import { useState, useCallback, useEffect } from 'react'
import { useParams, Link } from 'react-router-dom'
import { OverlayTrigger, Tooltip as TooltipBS } from 'react-bootstrap'
import moment from 'moment'
import { helix } from 'ldrs'

import { useAuth } from 'app/modules/auth'
import { toAbsoluteUrl } from '_metronic/helpers'

import { Tooltip, Comments } from 'components'

import routes from 'app/routing/routes'

// Función para procesar las menciones
const formatearComentario = (comentario) => {
  return comentario.replace(/@\[(.+?)\]\((\d+)\)/g, (match, p1, p2) => {
    return `<span class="text-primary">${p1}</span>`
  })
}

const TabComentarios = ({ setTab, serviceComentarios }) => {
  const { id } = useParams()
  const { currentUser } = useAuth()

  const { loading: loadingComentarios, obtenerUsuarios, obtenerComentarios, crearComentario } = serviceComentarios
  const [usuarios, setUsuarios] = useState([])
  const [comentarios, setComentarios] = useState([])
  const [observaciones, setObservaciones] = useState('')

  const handleObtenerUsuarios = useCallback(async () => {
    try {
      const data = await obtenerUsuarios()
      setUsuarios(data)
    } catch (error) {
      setUsuarios([])
    }
  }, [obtenerUsuarios])

  const handleObtenerComentarios = useCallback(async () => {
    try {
      let params = {}
      const data = await obtenerComentarios(id, params)
      setComentarios(data)
    } catch (error) {
      setComentarios([])
    }
  }, [id, obtenerComentarios])

  const handleCrearComentario = async (file) => {
    await crearComentario(id, { note: observaciones })
    handleObtenerComentarios()
  }

  useEffect(() => {
    handleObtenerUsuarios()
    handleObtenerComentarios()
  }, [handleObtenerUsuarios, handleObtenerComentarios])

  useEffect(() => {
    setTab('comentarios')
  }, [])

  useEffect(() => {
    helix.register()
  }, [])

  return (
    <>
      {loadingComentarios
        ? <l-helix color="var(--bs-primary)" style={{ position: "absolute", left: "50%", marginTop: "100px" }} />
        :
          <>
            {/* <div className="position-fixed top-0 right-0 bottom-0 left-0 bg-black" style={{ opacity: "0.4" }}></div> */}

            <div className="rounded-top-left-xl bg-white d-flex flex-column w-110 max-w-md rounded-top-xl border dark-bg-gray-950" style={{ minHeight: "50vh" }}>
              <div className="flex-grow-1 p-4 d-grid gap-4">
                {comentarios.map(((msg, idx) => (
                  <div key={idx}>
                    {msg.user_id != currentUser.user_id
                      ?
                        <div className="d-flex align-items-start gap-2">
                          <OverlayTrigger
                            placement='top'
                            overlay={
                              <TooltipBS id={`tooltip-${msg.id}`}>
                                <div className='text-start'>
                                  <h2 className='m-0 p-0 fs-6'>{msg.user_name}</h2>
                                  <p className='m-0 p-0 mt-4'>Rol {msg.user_roles}</p>
                                </div>
                              </TooltipBS>
                            }
                          >
                            <div className='avatar-container'>
                              <img
                                src={toAbsoluteUrl(`/media/avatars/${msg.user_avatar}`)}
                                alt={msg?.user_name}
                                className="rounded-full"
                                height="30"
                                width="30"
                                style={{
                                  aspectRatio: "30/30",
                                  objectFit: "cover",
                                }}
                              />
                            </div>
                          </OverlayTrigger>

                          <div className="bg-light rounded-xl p-2 text-sm max-w-90vw leading-loose">
                            <strong className='text-primary'>
                              {msg?.user_name}
                              <Tooltip text={msg.user_roles}>
                                <span className='text-muted'> [{msg.user_roles.split(',')[0]}]</span>
                              </Tooltip>
                            </strong>

                            <p className="my-2" dangerouslySetInnerHTML={{ __html: formatearComentario(msg.note) }}></p>

                            <div className='row'>
                              <div className='col-9'>
                                <p className="text-sm text-muted text-start m-0 p-0">
                                  Desde Orden de Egreso <Link to={`${routes.OUT_ORDERS}/${msg.out_order_id}`}>#{msg.out_order_id}</Link>
                                </p>
                              </div>

                              <div className='col-3'>
                                <p className="text-sm text-muted text-end m-0 p-0">{msg.created_at ? moment(msg.created_at).format('DD/MM HH:mm') : ''}</p>
                              </div>
                            </div>
                          </div>
                        </div>
                      :
                        <div className="d-flex align-items-end justify-content-end gap-2">
                          <div className="bg-light rounded p-2 text-sm max-w-80vw leading-loose">
                            <strong className='text-primary'>
                              Vos
                              <Tooltip text={msg.user_roles}>
                                <span className='text-muted'> [{msg.user_roles.split(',')[0]}]</span>
                              </Tooltip>
                            </strong>

                            <p className="mb-1" dangerouslySetInnerHTML={{ __html: formatearComentario(msg.note) }}></p>

                            <div className='row'>
                              <div className='col-9'>
                                <p className="text-sm text-muted text-start m-0 p-0">
                                  Desde
                                  {msg?.sales_order_id
                                    ? <> Pedido de Venta <Link to={`${routes.VENTAS_PEDIDOS}/${msg.sales_order_id}`}>#{msg.sales_order_id}</Link></>
                                    : <> Orden de Egreso <Link to={`${routes.OUT_ORDERS}/${msg.out_order_id}`}>#{msg.out_order_id}</Link></>
                                  }
                                </p>
                              </div>

                              <div className='col-3'>
                                <p className="text-sm text-muted text-end m-0 p-0">{msg.created_at ? moment(msg.created_at).format('DD/MM HH:mm') : ''}</p>
                              </div>
                            </div>
                          </div>
                        </div>
                    }
                  </div>
                )))}
              </div>

              <div className='separator'></div>

              <div className='p-5'>
                <Comments usersData={usuarios} handlePostNote={handleCrearComentario} notes={observaciones} setNotes={setObservaciones} />
              </div>
            </div>
          </>
      }
    </>
  )
}

export default TabComentarios