import service from "../service";

const PATH = {
  transactionalDocuements: 'transactional-documents',
  reporteRemitos: 'transactional-documents/report/remitos',
  reporteFacturas: 'transactional-documents/report/facturas',
  reportRemitosSinComprobantesDetalle: 'transactional-documents/report/remitos/sin-comprobantes/detalle',
  reporteInventario: 'transactional-documents/report/inventario',
  reporteAnalisisStock: 'transactional-documents/report/analisis-stock',
  Incomes: "/incomes",
  InRequest: "/incomes/in-request",
  Event: "/event"
}

export class Reports {

  static getIncomes = async (params) => {
    const response = await service.get('incomes/in-request/report', {
      params
    })
    return response
  }

  static getTransactionalDocuments = async (params) => {
    const response = await service.get('/transactional-documents/report/v2', {
      params,
    })
    return response
  }

  static getVentas = async (params) => {
    const response = await service.get('/sales-orders/report', {
      params
    })
    return response
  }

  static getVentasDetalle = async (params) => {
    const response = await service.get('/sales-orders/report/detail', {
      params
    })
    return response
  };

  static getReporteRemitos = async (params) => {
    const response = await service.get(PATH.reporteRemitos, {
      params,
    })
    return response
  }

  static getReporteRemitosSinComprobantesDetalle = async (params) => {
    const response = await service.get(PATH.reportRemitosSinComprobantesDetalle, {
      params,
    })
    return response
  }

  static getReporteRemitosVerifarma = async (params) => {

    const response = await service.get(`${PATH.reporteRemitos}/verifarma`, {
      params,
      responseType: 'blob'
    })
    return response
  }


  static getReporteRemitosOsprera = async (ids) => {
    try {
        const response = await service.post(`${PATH.reporteRemitos}/osprera`, 
          { ids },  
          { responseType: 'blob',});

        const reader = new FileReader();
        reader.readAsDataURL(response.data); 
        return new Promise((resolve, reject) => {
            reader.onloadend = () => {
                const base64data = reader.result; 
                resolve(base64data); 
            };
            reader.onerror = (error) => {
                console.error("Error leyendo el blob:", error);
                reject(error);
            };
        });
    } catch (error) {
        console.error("Error en getReporteRemitosOsprera:", error);
        throw error;
    }
}


static getReporteRemitosUnionPersonal = async (ids) => {
  try {
      const response = await service.post(`${PATH.reporteRemitos}/unionPersonal`, 
        { ids },  
        { responseType: 'blob',});

      const reader = new FileReader();
      reader.readAsDataURL(response.data);

      return new Promise((resolve, reject) => {
          reader.onloadend = () => {
              const base64data = reader.result; 
              resolve(base64data);
          };
          reader.onerror = (error) => {
              console.error("Error leyendo el blob:", error);
              reject(error);
          };
      });
  } catch (error) {
      console.error("Error en getReporteRemitosUnionPersonal:", error);
      throw error;
  }
}



static getReporteRemitosCsvStaCruz = async (ids) => {
  try {
    const response = await service.post(`${PATH.reporteRemitos}/csvStaCruz`, 
      { ids },  
      {
        responseType: 'blob',
      }
    );
    const reader = new FileReader();
    reader.readAsDataURL(response.data);
    return new Promise((resolve, reject) => {
      reader.onloadend = () => {
        const base64data = reader.result;
        resolve(base64data);
      };
      reader.onerror = (error) => {
        console.error("Error leyendo el blob:", error);
        reject(error);
      };
    });
  } catch (error) {
    console.error("Error en getReporteRemitosCsvStaCruz:", error);
    throw error;
  }
};




  static getReporteInventario = async (params) => {
    const response = await service.get(PATH.reporteInventario, {
      params,
    })
    return response
  }

  static getReporteAnalisisStock = async (params) => {
    const response = await service.get(PATH.reporteAnalisisStock, {
      params,
    })
    return response
  }

  static getReporteFacturasMedife = async (ids) => {

    try {
        const response = await service.post(`${PATH.reporteFacturas}/medife`, 
          { ids },  
          {
            responseType: 'blob',
          });

        const reader = new FileReader();
        reader.readAsDataURL(response.data); // Convertir el blob en Base64

        return new Promise((resolve, reject) => {
            reader.onloadend = () => {
                const base64data = reader.result; // Contiene la cadena Base64
                //console.log("Cadena devuelta por getReporteFacturasMedife:", base64data);
                resolve(base64data); // Retorna la cadena Base64
            };
            reader.onerror = (error) => {
                console.error("Error leyendo el blob:", error);
                reject(error);
            };
        });
    } catch (error) {
        console.error("Error en getReporteFacturasMEdife:", error);
        throw error;
    }
}


static getReporteFacturasOsprera = async (ids) => {

  try {
      const response = await service.post(`${PATH.reporteFacturas}/osprera`, 
        { ids },  
        {
          responseType: 'blob',
        });

      const reader = new FileReader();
      reader.readAsDataURL(response.data);

      return new Promise((resolve, reject) => {
          reader.onloadend = () => {
              const base64data = reader.result; 
              resolve(base64data); 
          };
          reader.onerror = (error) => {
              console.error("Error leyendo el blob:", error);
              reject(error);
          };
      });
  } catch (error) {
      console.error("Error en getReporteFacturasMEdife:", error);
      throw error;
  }
}




}