import { useEffect, useState } from "react";
import { Button, Form, FormLabel, Modal, Textarea } from "react-bootstrap";
import Select from 'react-select';
import { toast } from "react-toastify";
import { helix } from 'ldrs'

import { useOutOrders, useWaves } from "hooks";

const labelStyle = {
    fontSize: "1.05rem",
    fontWeight: "500",
    color: "var(--bs-gray-800)"
}

const customStyles = {
    control:  (base, state) => ({
        ...base,
        boxShadow: "none",
        '&:hover': { border: '1px solid #bbbbbb' },
        border: state.isFocused ? "1px solid #bbbbbb" : "1px solid #efefef",
        minHeight: '43px',
        height: '43px',
    }),
    option: (provided, state) => ({
        ...provided,
        fontFamily: 'Arial, sans-serif',
        fontSize: '1.1rem',
    }),
    singleValue: (provided, state) => ({
        ...provided,
        fontFamily: 'Arial, sans-serif',
        fontSize: '1.1rem',
        fontWeight: '500'
    }),
    multiValue: (base) => ({
        ...base,
        fontSize: '1.1rem',
    }),
}

export function RegenerateWaybillModal({ show, onHide, out_order_id, remito_id, shipping_carrier_service_id, wave, f_cancelacion_remito }) {
    const { handleGetOutOrderSimulation, handleGetCodigosEventos, handleRegenerateWaybill, handleSendWaybill, loading } = useOutOrders()
    const {handleGetShippingCarrierServices, handleAddShippingCarrierService, loading: loadingShipping} = useWaves()
    const [opcionesRemito, setOpcionesRemito] = useState([])
    const [opcionesTransportista, setOpcionesTransportista] = useState([])
    const [transportista, setTransportista] = useState(null)
    const [simulate, setSimulate] = useState({})
    const [nroRemito, setNroRemito] = useState("")
    const [idTalonario, setIdTalonario] = useState("")
    const [eventsAvailable, setEventsAvailable] = useState(null);
    const [eventDefault, setEventDefault] = useState(null);
    const [eventInWaybill, setEventInWaybill] = useState(null);
    const [observations, setObservations] = useState("")

    const loadData = async () => {
        const resp = await handleGetOutOrderSimulation(out_order_id)

        /* Si hay productos trazables, carga los eventos disponibles y el evento default */
        if (resp?.data?.datos_traza) {
            const _eventos = await handleGetCodigosEventos({ out_order_id })

            if (_eventos?.data?.result) {
                const { event_default, events_available } = _eventos.data.result
                setEventDefault(event_default)
                setEventsAvailable(events_available?.map(e => ({
                    ...e,
                    value: e.id,
                    label: e.event_desc
                })))
            }
        }

        setSimulate(resp?.data)

        const op =resp?.data?.datos_remitos?.map(d => ({
            ...d,
            label: d.punto_venta,
            value: d.numero_completo
        }))
        setOpcionesRemito(op)

        const transportes = await handleGetShippingCarrierServices()
        const opcionesTransporte = transportes?.data?.result?.map(item => ({ value: item.id, label: `${item.shipping_carrier_name} - ${item.shipping_carrier_service}` }))
        setOpcionesTransportista(opcionesTransporte)
    }

    const getValueTransportista = () => {
        if (opcionesTransportista) {
            return opcionesTransportista.find(option => option.value == transportista)
        } else {
            return ""
        }
    }

    const sendWaybill = async () => {
        try {
            if (!transportista || transportista <= 0) {
                toast.error("Falta definir el transportista")
                return
            }
            if (!wave || !wave.id) {
                toast.error(`La Orden de egreso #{out_order_id} no está en una Ola`)
                return
            }
            if (!eventDefault && !eventInWaybill && simulate.datos_traza) {
                toast.error("Falta definir el evento de traza")
                return
            }

            const data = {
                punto_venta: idTalonario,
                remito_id: remito_id,
                observaciones: observations
            }

            if (!f_cancelacion_remito) {
                await handleRegenerateWaybill(data)
            } else {
                let auxData = {
                    id_talonario: idTalonario,
                    sin_factura: true
                }
                await handleSendWaybill(out_order_id, auxData)
            }

            toast.success(`La Orden de egreso #${out_order_id} fue procesada`)
            setObservations('')
            onHide()
        } catch (err) {
            console.log(err)
            toast.error(err?.message)
        }
    }

    const onChangeTransportista = async (e) => {
        if (!wave || !wave.id) {
            toast.error(`La Orden de egreso #${out_order_id} no está en una Ola`)
            return
        }

        try {
            await handleAddShippingCarrierService(wave.id, wave.wave_order_id, { shipping_carrier_service_id: e.value })
            setTransportista(e.value)
        } catch (err) {
            toast.error("Error al cambiar el transportista")
        }
    }

    useEffect(() => {
        if (show) {
            helix.register()
            setNroRemito("")
            loadData()
            setTransportista(shipping_carrier_service_id)
            setEventDefault(null)
            setEventInWaybill(null)
        }
    }, [show])

    return (
        <>
            <Modal size="lg" show={show} onHide={onHide} backdrop='static'>
                <Modal.Body>
                    <h3>Regenerar remito</h3>

                    {loading || loadingShipping
                        ?
                            <div className='text-center'>
                                <l-helix color="var(--bs-primary)" style={{ marginTop: "50px" }} />
                            </div>
                        :
                            <>
                                <div className="row mt-10">
                                    <div className="col">
                                        <FormLabel style={labelStyle}><b>Transportista</b></FormLabel>
                                        <Select
                                            options={opcionesTransportista}
                                            onChange={onChangeTransportista}
                                            styles={customStyles}
                                            value={getValueTransportista()}
                                            isDisabled={!f_cancelacion_remito}
                                        />
                                    </div>
                                </div>

                                <div className="row mt-10">
                                    <div className="col">
                                        <FormLabel style={labelStyle}><b>Punto de remito</b></FormLabel>
                                        <Select
                                            options={opcionesRemito}
                                            onChange={(e) => {
                                                setNroRemito(e.value)
                                                setIdTalonario(e.label)
                                            }}
                                            styles={customStyles}
                                            isDisabled={loadingShipping || loading}
                                        />
                                    </div>
                                </div>

                                {simulate?.datos_traza
                                    ?
                                        <>
                                            <div className="row mt-10">
                                                <div className="col">
                                                    <FormLabel style={labelStyle}><b>Evento de traza</b></FormLabel>
                                                    <Select
                                                        options={eventsAvailable}
                                                        onChange={(e) => setEventInWaybill(e)}
                                                        defaultValue= {eventDefault ? eventsAvailable.filter(e => e.id == eventDefault.id)[0] : null}
                                                        styles={customStyles}
                                                        isDisabled={loading || eventDefault!=null}
                                                    />
                                                </div>
                                            </div>
                                        </>
                                    : <></>
                                }

                                <div className="row mt-8">
                                    <div className="col">
                                        <label className="form-label"><b>Facturación:</b> {simulate?.datos_factura?.billing_type_label}</label>
                                    </div>
                                </div>

                                <div className="row mt-8">
                                    <div className="col">
                                        <label className="form-label"><b>Nro. Remito:</b> {nroRemito}</label>
                                    </div>
                                </div>

                                {!f_cancelacion_remito &&
                                    <div className="row mt-8">
                                        <div className="col">
                                            <FormLabel style={labelStyle}><b>Motivo</b></FormLabel>
                                            <Form.Control as="textarea" id='descripcion' name='descripcion' onChange={e => setObservations(e.target.value)} label='Motivo' />
                                        </div>
                                    </div>
                                }
                            </>
                    }
                </Modal.Body>

                <Modal.Footer style={{ borderTop: "0 none" }}>
                    <Button variant="secondary" onClick={() => onHide(null)} disabled={loading || loadingShipping}>Salir</Button>
                    <Button type="button" variant="primary" onClick={sendWaybill} disabled={loading || loadingShipping}>Remitir</Button>
                </Modal.Footer>
            </Modal>
        </>
    )
}