import { Link } from 'react-router-dom'
import moment from 'moment'

import { Tooltip } from 'components'

import routes from 'app/routing/routes'

export const COLUMNS = [
  {
    Header: <div className='text-center'>PV</div>,
    id: 'sales_order_id',
    accessor: (row) => (
      <div className='text-center'>
        <Tooltip text='Ver'>
          <Link to={`${routes.VENTAS_PEDIDOS}/${row.sales_order_id}`}>
            {row.sales_order_id}
          </Link>
        </Tooltip>
      </div>
    ),
  },
  {
    Header: <div className='text-center'>ID Comprobante</div>,
    id: 'id',
    accessor: (row) => (
      <div className='text-center'>
        {row.id}
      </div>
    ),
  },
  {
    Header: <div className=''>Tipo</div>,
    id: 'tipo',
    accessor: (row) => (
      <div className=''>
        {row.tipo}
      </div>
    ),
  },
  {
    Header: <div className=''>Número</div>,
    id: 'number',
    accessor: (row) => (
      <div className=''>
        {row.number}
      </div>
    ),
  },
  {
    Header: <div className='text-center'>CAE</div>,
    id: 'cae',
    accessor: (row) => (
      <div className='text-center'>
        {row?.cae ? row.cae : '-'}
      </div>
    ),
  },
  {
    Header: <div className='text-center'>Fecha</div>,
    id: 'date',
    accessor: (row) => (
      <div className='text-center'>
        {moment(row.date).format('DD/MM/YYYY')}
      </div>
    ),
  },
  {
    Header: <div className='text-center'>F. cancelación</div>,
    id: 'cancel_date',
    accessor: (row) => (
      <div className='text-center'>
        {row?.cancel_date ? moment(row.cancel_date).format('DD/MM/YYYY') : '-'}
      </div>
    ),
  },
  {
    Header: <div className='text-center'>Estado</div>,
    id: 'status',
    accessor: (row) => (
      <span className={ `badge ${row.label_color} w-100 justify-content-center`}>
        {row.label}
      </span>
    )
  },
]