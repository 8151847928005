export enum EstadoControlGestionI {
  PENDIENTE = 1,
  EN_PROCESO = 2,
  COMPLETADO = 3,
}

export const EstadoControlGestionLabelI = {
  [EstadoControlGestionI.PENDIENTE]: 'Pendiente',
  [EstadoControlGestionI.EN_PROCESO]: 'En proceso',
  [EstadoControlGestionI.COMPLETADO]: 'Completado',
}