import { useState } from 'react'
import { useNavigate } from 'react-router-dom'
import { Button, Divider } from '@mui/material'
import { toast } from 'react-toastify'

import { useAuth } from 'app/modules/auth'
import { useSalesOrders } from 'hooks/Sales'

import { Menu, MenuItem, ButtonPrimary, ButtonSecondary } from 'components'
import { SalesOrderStatusI, SalesOrdertStatusLabelI, OutOrderStatusI, OrderTypeI } from 'interfaces'

import routes from 'app/routing/routes'

import './styles.sass'

const MenuActions = ({ values, handleGetSalesOrder }) => {
  const [anchorEl, setAnchorEl] = useState(null)
  const open = Boolean(anchorEl)

  const handleClick = (event) => {
    setAnchorEl(event.currentTarget)
  }

  const handleClose = () => {
    setAnchorEl(null)
  }

  const { auth } = useAuth()
  const { handleUpdateSalesOrderStatus, handleClonseSalesOrder, handleCreateOutOrder } = useSalesOrders()

  const updateSalesOrderStatus = async (newStatus) => {
    try {
      const response = await handleUpdateSalesOrderStatus(values.id, newStatus)

      toast.success("Orden actualizada correctamente", {theme:"colored"})
    } catch (error) {
      toast.error(error.message)
    } finally {
      handleGetSalesOrder()
      handleClose()
    }
  }

  const cloneSalesOrder = async () => {
    try {
      const response = await handleClonseSalesOrder(values.id, auth?.user.id, 'token')

      toast.success("Orden clonada correctamente", {theme:"colored"})
    } catch (error) {
      toast.error(error.message)
    } finally {
      handleClose()
    }
  }

  const createOutOrder = async () => {
    try {
      const response = await handleCreateOutOrder(values.id, auth?.user.id, 'token')

      toast.success("Orden creada correctamente", {theme:"colored"})
    } catch (error) {
      toast.error(error.message)
    } finally {
      handleGetSalesOrder()
      handleClose()
    }
  }

  return (
    <>
      <Button
        id="basic-button"
        aria-controls={open ? 'sales-orders-actions-menu' : undefined}
        aria-haspopup="true"
        aria-expanded={open ? 'true' : undefined}
        onClick={handleClick}
        className='btn btn-sm btn-bg-light btn-active-color-primary'
      >
        <i className="bi bi-caret-down-square-fill ms-2" />
        Acciones
      </Button>

      <Menu id='sales-orders-actions-menu' row={values} anchorEl={anchorEl} open={open} handleClick={handleClick} handleClose={handleClose} noTooltip>
        {
          (values.sales_order_status_id === SalesOrderStatusI.BORRADOR || values.sales_order_status_id === SalesOrderStatusI.NO_AUTORIZADO)
          && values.order_type_id === OrderTypeI.PEDIDO_VENTA
          && <MenuItem handleClick={() => updateSalesOrderStatus(SalesOrderStatusI.PENDIENTE_AUTORIZACION_RENTA)} icon='bi bi-check-circle text-success' text={SalesOrdertStatusLabelI.PENDIENTE_AUTORIZACION_RENTA} />
        }

        {values.sales_order_status_id === SalesOrderStatusI.PENDIENTE_AUTORIZACION_RENTA &&
          <div>
            <MenuItem handleClick={() => updateSalesOrderStatus(SalesOrderStatusI.PENDIENTE_AUTORIZACION_CREDITO)} icon='bi bi-check-all text-success' text='Autorizar rentabilidad' />
            <MenuItem handleClick={() => updateSalesOrderStatus(SalesOrderStatusI.NO_AUTORIZADO)} icon='bi bi-x-circle text-danger' text='No autorizar' />
          </div>
        }

        {((values.order_type_id === OrderTypeI.PEDIDO_EGRESO && values.sales_order_status_id !== SalesOrderStatusI.AUTORIZADO) || values.sales_order_status_id === SalesOrderStatusI.PENDIENTE_AUTORIZACION_CREDITO) &&
          <div>
            <MenuItem handleClick={() => updateSalesOrderStatus(SalesOrderStatusI.AUTORIZADO)} icon='bi bi-check-all text-success' text='Autorizar' />
            <MenuItem handleClick={() => updateSalesOrderStatus(SalesOrderStatusI.NO_AUTORIZADO)} icon='bi bi-x-circle text-danger' text='No autorizar' />
          </div>
        }

        {values.sales_order_status_id === SalesOrderStatusI.AUTORIZADO &&
          <div>
            <MenuItem handleClick={createOutOrder} icon='bi bi-box-arrow-up-right text-success' text='Crear orden de egreso' />
            <MenuItem handleClick={() => updateSalesOrderStatus(SalesOrderStatusI.CANCELADO)} icon='bi bi-x-circle text-danger' text='Cancelar' />
          </div>
        }

        {!(
            values.sales_order_status_id === SalesOrderStatusI.ORDEN_EGRESO ||
            values.sales_order_status_id === SalesOrderStatusI.ANULADO
          ) && <Divider sx={{ '&': { borderColor: 'rgba(0,0,0,0.50)' } }} />
        }

        {!(values.sales_order_status_id === SalesOrderStatusI.ANULADO || values.sales_order_status_id === SalesOrderStatusI.ORDEN_EGRESO || values.sales_order_status_id === SalesOrderStatusI.AUTORIZADO) &&
          <MenuItem handleClick={() => updateSalesOrderStatus(SalesOrderStatusI.ANULADO)} icon='bi bi-trash text-danger' text='Cancelar' />
        }

        <MenuItem handleClick={cloneSalesOrder} icon='bi bi-clipboard-check-fill' text='Clonar' />
      </Menu>
    </>
  )
}

const Actions = ({ values, editing, handleEditing, submitRef, cancelRef, handleGetSalesOrder }) => {
  const navigation = useNavigate()

  return (
    <div className='d-flex align-items-center'>
      <div className='row'>
        {!editing
          ?
            <>
              <div className='col'>
                <ButtonSecondary text='Volver' handleClick={() => navigation(routes.VENTAS_PEDIDOS)} />
              </div>

              {(
                values.sales_order_status_id === SalesOrderStatusI.BORRADOR ||
                values.sales_order_status_id === SalesOrderStatusI.NO_AUTORIZADO ||
                values.sales_order_status_id === SalesOrderStatusI.CANCELADO
              ) &&
                <div className='col'>
                  <ButtonPrimary text='Editar' type='button' handleClick={handleEditing} />
                </div>
              }

              <div className='col'>
                <MenuActions values={values} handleGetSalesOrder={handleGetSalesOrder} />
              </div>
            </>
          :
            <>
              <div className='col'>
                <ButtonSecondary text='Cancelar' handleClick={() => cancelRef.current.click()} />
              </div>

              <div className='col'>
                <ButtonPrimary text='Guardar' type='button' handleClick={() => submitRef.current.click()} />
              </div>

              <div className='col'>
                <MenuActions values={values} handleGetSalesOrder={handleGetSalesOrder} />
              </div>
            </>
        }
      </div>
    </div>
  )
}

export default Actions