import { useState, useRef, useCallback, useMemo, useEffect } from 'react'
import { Link, useNavigate } from 'react-router-dom'
import { toast } from 'react-toastify'
import Swal from 'sweetalert2'
import { helix } from 'ldrs'

import { useAuth } from 'app/modules/auth'
import { useClickOutside, useWaybill } from 'hooks'

import { TableContainer } from 'containers'
import { CustomTable, AgrupacionModal } from 'components'
import ContextMenu from 'components/ContextMenu'

import { Filter, TableActions, UpdateShippingCarrierServiceModal } from './partials'
import { ShipmentResultModal, AddToDispatchModal, PrintDispatchGuideModal } from './partials'
import useGetColumns from './columns'

import routes from 'app/routing/routes'
import { currencyFormat } from 'utilities'

export const ESTADOS_ORDEN_ENVIO = {
  PENDIENTE_DESPACHO: 1,
  EN_DESPACHO: 2,
  EN_DISTRIBUCION: 4,
  ENTREGADA: 5,
  CANCELADO: 3
}

export const labelColor = (order_delivery_status_id) => {
  switch (order_delivery_status_id) {
    case ESTADOS_ORDEN_ENVIO.PENDIENTE_DESPACHO:
      return "estado-amarillo";
    case ESTADOS_ORDEN_ENVIO.EN_DESPACHO:
      return "estado-celeste";
    case ESTADOS_ORDEN_ENVIO.EN_DISTRIBUCION:
      return "estado-azul";
    case ESTADOS_ORDEN_ENVIO.ENTREGADA:
      return "estado-verde";
    case ESTADOS_ORDEN_ENVIO.CANCELADO:
      return "estado-rojo";
    default:
      return ""
  }
}

const initialObQuery = {
  pageNumber: 1,
  pageSize: 10,
  sortField: 'id',
  sortOrder: 'DESC',
  order_delivery_status_id: [ESTADOS_ORDEN_ENVIO.PENDIENTE_DESPACHO],
}

const ShipmentOrders = () => {
  const { currentUser } = useAuth()
  const navigate = useNavigate()

  const { loading, handleGetShipmentOrders, handlePrintDispatchGuide, handleMarkOrderAsDelivered, handleUpdateDeliveryResult, handleGetDispatchOrders } = useWaybill()
  const [data, setData] = useState([])

  const [queryMD, setQueryMD] = useState(initialObQuery)
  const [totalCount, setTotalCount] = useState(0)

  const [selectedRows, setSelectedRows] = useState([])
  const [updateShippingCarrierServiceModal, setUpdateShippingCarrierServiceModal] = useState(false)
  const [showShipmentResult, setShowShipmentResult] = useState(false);
  const [addToDispatchModal, setAddToDispatchModal] = useState(false);
  const [pendingDispatches, setPendingDispatches] = useState([])
  const [dispatchGuideModal, setDispatchGuideModal] = useState(false)

  const [showAgrupadasModal, setShowAgrupadasModal] = useState(false)
  const [modalTitle, setModalTitle] = useState('')
  const [idsAgrupados, setIdsAgrupados] = useState([])
  const [esPedido, setEsPedido] = useState(true)

  const handleRowSelection = useCallback((rowId, statusId, shippingCarrierId, order) => {
    if (selectedRows.length === 0) {
      setSelectedRows([{
        id: rowId,
        status_id: statusId,
        shipping_carrier_id: shippingCarrierId,
        shipping_carrier: order.shipping.carrier,
        shipping_carrier_service_code: order.shipping.shipping_code,
        shipping_carrier_service: order.shipping.service,
        shipping_type: order.shipping.shipping_type,
        checked: true
      }])
      return;
    }

    setSelectedRows((prevSelectedRows) => {
      if (prevSelectedRows.some(i => i.shipping_carrier_id !== shippingCarrierId)) {
        return prevSelectedRows
      }

      if (prevSelectedRows.some(i => i.id === rowId)) {
        return prevSelectedRows.filter(i => i.id !== rowId)
      } else {
        return [
          ...prevSelectedRows,
          {
            id: rowId,
            status_id: statusId,
            shipping_carrier_id: shippingCarrierId,
            shipping_carrier: order.shipping.carrier,
            shipping_carrier_service_code: order.shipping.shipping_code,
            shipping_carrier_service: order.shipping.service,
            shipping_type: order.shipping.shipping_type,
            checked: true
          }
        ]
      }
    })
  }, [selectedRows])

  const { COLUMNS, COLUMNS_2 } = useGetColumns(selectedRows, handleRowSelection)

  const getShipmentOrders = useCallback(async () => {
    try {
      let params = {}

      if (queryMD && queryMD?.pageNumber) {
        params = {
          ...params,
          ...queryMD,
        }
      }

      if (currentUser && currentUser?.shipment_orders) {
        const { shipment_orders } = currentUser;

        params = {
          ...params,
          id: shipment_orders?.id || '',
          dispatch_order_id: shipment_orders?.dispatch_order_id || '',
          sales_order_ids: shipment_orders?.sales_order_ids || '',
          out_order_ids: shipment_orders?.out_order_ids || '',
          from_date: shipment_orders?.from_date || '',
          to_date: shipment_orders?.to_date || '',
          direccion: shipment_orders?.direccion || '',
          localidad: shipment_orders?.localidad || '',
          provincia: shipment_orders?.provincia || '',
          shipping_carrier_service_id_filter: shipment_orders?.shipping_carrier_service_id_filter || [],
          order_delivery_status_id: shipment_orders?.order_delivery_status_id ? shipment_orders.order_delivery_status_id : [ESTADOS_ORDEN_ENVIO.PENDIENTE_DESPACHO]
        }
      }

      const response = await handleGetShipmentOrders(params)

      if (response) {
        const dataColored = response.data?.result.map(d => ({
          ...d,
          label_color: labelColor(d.order_delivery_status_id),
          dispatch_order_id: d.order_delivery_status_id == ESTADOS_ORDEN_ENVIO.PENDIENTE_DESPACHO ? null : d.dispatch_order_id,
          tiene_redespacho: Boolean(d.order_delivery_status_id == ESTADOS_ORDEN_ENVIO.PENDIENTE_DESPACHO && d.dispatch_order_id),
        }))

        setData(dataColored)
        setTotalCount(response.data.metadata.count)
      }
    } catch (error) {
      setData([])
      setTotalCount(0)
      toast.error(error.message)
    }
  }, [handleGetShipmentOrders, queryMD, currentUser])


  /* Menu contextual */
  const [showContextMenu, setShowContextMenu] = useState(false)
  const [currentPos, setCurrentPos] = useState({ x: 0, y: 0 })
  const [currentRow, setCurrentRow] = useState(null)
  const threeDotsRef = useRef(null);
  const contextRef = useRef()

  const handleContextMenu = (e, row) => {
    setCurrentRow(row)

    if (e) {
      e.preventDefault();
      setCurrentPos({ x: e.clientX, y: e.clientY, target: e.target.id })

      if (!showContextMenu) {
        setShowContextMenu(true);
      }
    }
  }

  useClickOutside(contextRef, () => {
    //Para que no cierre la primera vez que hace click en threeDots
    if (currentPos.target == 'threeDots') {
      setCurrentPos({ ...currentPos, target: '' })
      return
    }

    if (showContextMenu) {
      setShowContextMenu(false)
    }
  })

  const availableActions = () => {
    let actions = [];

    actions.push({
      label: "Ver",
      icon: "bi bi-pencil-square text-primary",
      action: actionEdit
    })

    if (currentRow?.order_delivery_status_id == ESTADOS_ORDEN_ENVIO.EN_DISTRIBUCION) {
      actions.push({
        label: "Entrega / Devolución",
        icon: "bi bi-bag-check text-primary",
        action: () => setShowShipmentResult(true)
      })
    }

    if (currentRow?.order_delivery_status_id === ESTADOS_ORDEN_ENVIO.PENDIENTE_DESPACHO) {
      actions.push({
        label: 'Cambiar transportista',
        icon: 'bi bi-pencil text-primary',
        action: updateShippingCarrierService
      })

      actions.push({
        label: "Enviar a revisión",
        icon: "bi bi-clipboard-check text-primary",
        action: sendToRevision
      })

      const availableDispatch = pendingDispatches.filter(p => p.shipping_carrier_name == currentRow.shipping_carrier_name)

      // if (availableDispatch.length > 0) {
      // if (currentRow.order_delivery_status_id == ESTADOS_ORDEN_ENVIO.PENDIENTE_DESPACHO) {
      actions.push({
        label: 'Agregar a despacho',
        icon: 'bi bi-truck text-primary',
        action: actionAddToDispatch
      })
      // }
    }

    actions.push({
      label: "Guía de despacho",
      icon: "bi bi-download text-success",
      action: () => actionPrint()
    })

    actions.push({
      label: "Eventos de la Orden",
      icon: "bi bi-clock-history text-primary",
      action: actionViewEvents
    })

    return actions
  }

  const actionEdit = () => {
    if (currentRow) {
      navigate(`${routes.LOGISTICS_SHIPMENT_ORDERS}/${currentRow.id}`)
    }
  }

  const sendToRevision = async()=>{
    try {
      const msg = `¿Enviar a revisión la órden de envío #${currentRow.id}?`
      const inputValue = '';
      const resp = await Swal.fire({
        title: "Advertencia",
        text: msg,
        icon: "warning",
        input: "textarea",
        inputValue,
        inputLabel: "Motivo",
        inputAttributes: {
          autocomplete:"off"
        },
        inputValidator: (value) => {
          if (!value || value.trim()=='') {
            return "Por favor, completar motivo de envío a revisión"
          }
        },
        showDenyButton: true,
        denyButtonText: "No, cancelar",
        confirmButtonText: "Si, enviar a revisión",
        customClass: { confirmButton: 'btn btn-primary', denyButton:"btn btn-secondary", inputLabel:"form-label", input:"mt-1" },
        reverseButtons: true
      })

      if (resp.isConfirmed) {
        const transport = {}
        transport.entregados = []
        transport.devueltos = []
        transport.revision = [{
          id: currentRow.id,
          observaciones: resp.value
        }]

        await handleUpdateDeliveryResult(transport)
        getShipmentOrders()
        toast.success("El pedido se envió a revisión", { theme: "colored" })
      }
    } catch (err) {
      toast.error(err.message, { theme: "colored" })
    }
  }

  const updateShippingCarrierService = () => {
    setUpdateShippingCarrierServiceModal(true)
  }

  const actionAddToDispatch = async () => {
    if (currentRow) {
      setAddToDispatchModal(true)
    }
  }

  const actionDelivered = async () => {
    if (currentRow) {
      try {
        const resp = await Swal.fire({
          title:"Envío entregado",
          text:`Confirma que el envío ${currentRow.id} se entregó?`,
          icon:"warning",
          showDenyButton:true,
          denyButtonText:"No, cancelar",
          confirmButtonText:"Si, confirmar",
          customClass: {confirmButton:'btn btn-primary', denyButton:"btn btn-secondary"},
          reverseButtons: true
        })

        if (resp.isConfirmed) {
          await handleMarkOrderAsDelivered({ order_delivery_id: [currentRow.id] })
          toast.success("Envío marcado como entregado", { theme: "colored" })
          getShipmentOrders()
        }
      } catch (err) {
        toast.error(err.message, { theme: "colored" })
        getShipmentOrders()
      }
    }
  }

  const actionPrint = async () => {
    if (currentRow) {
      const resp = await Swal.fire({
        title: `Guía de despacho #${currentRow.id}`,
        html: `
          <div style='text-align: left;'>
            <p><strong>Dirección:</strong> ${currentRow?.ship_domicilio?.trim()} (CP: ${currentRow?.ship_codigo_postal?.trim()}), ${currentRow?.ship_localidad?.trim()}</p>
            <p><strong>Cant. pedidos:</strong> ${currentRow.out_orders_ids.split(',').length}</p>
            <p><strong>Total:</strong> ${currencyFormat.format(currentRow.total)} <i>(incluye IVA)</i></p>
            <label for="swal-input1" class="fw-bold">Valor Declarado</label>
            <input id="swal-input1" class="swal2-input m-0" placeholder="Ingrese el valor declarado" type="text" value="0">
          </div>
        `,
        icon: "info",
        showDenyButton: true,
        denyButtonText: "Cancelar",
        confirmButtonText: "Imprimir",
        customClass: { confirmButton: 'btn btn-primary', denyButton: "btn btn-secondary" },
        reverseButtons: true,
        preConfirm: () => {
          return Swal.getPopup().querySelector('#swal-input1').value;
        }
      });

      if (resp.isConfirmed) {
        const data = {
          valorDeclarado: resp.value,
        }

        try {
          const response = await handlePrintDispatchGuide(currentRow.id, data)
          if (response.status === 200) {
            toast.success('¡Guía de Despacho descargada!')

            const blob = await response.data

            const url = URL.createObjectURL(blob)
            const a = document.createElement('a')
            a.href = url
            a.download = `guia-despacho-${currentRow.id}.pdf`
            a.style.display = 'none'
            document.body.appendChild(a)
            a.click()
            URL.revokeObjectURL(url)
          } else {
            toast.error('Error al descargar la Guía de Despacho')
          }
        } catch (error) {
          toast.error(error.message);
        }
      }
    }
  }

  const actionViewEvents = () => {
    //
  }
  /* Fin menu contextual */

  const getAvailableDispatches= async () => {
    const resp = await handleGetDispatchOrders()
    setPendingDispatches(resp?.data?.result?.filter(p => p.dispatch_order_status_id == 1) || [])
  }

  const onHideShipmentResult = async(data) => {
    if (data) {
      try {
        const resp = await Swal.fire({
          title: "Actualización de orden de envío",
          text: `¿Confirma la actualización de la orden de envío #${data.id}?`,
          icon: "warning",
          showDenyButton: true,
          denyButtonText: "No, cancelar",
          confirmButtonText: "Si, confirmar",
          customClass: { confirmButton: 'btn btn-primary', denyButton: "btn btn-secondary" },
          reverseButtons: true
        })

        if (resp.isConfirmed) {
          const { id, ...rest } = data
          await handleUpdateDeliveryResult(rest)
          toast.success("Orden de envío actualizada correctamente")
          getShipmentOrders()
        }
      } catch (err) {
        toast.error(err.message)
        getShipmentOrders()
      }
    }

    setShowShipmentResult(false)
  }

  const columns = useMemo(() => [
    ...COLUMNS,
    {
      Header: <p className='m-0 p-0 text-center'>IDs O. Egreso</p>,
      id: 'out_orders_ids',
      className: 'col-pedidos',
      accessor: (row) => (
        <p className='m-0 p-0 text-center'>
          {row.out_orders_ids?.split(',').length > 1
            ?
              <span
                className='cursor-pointer text-primary'
                onClick={() => {
                  setModalTitle('Órdenes de egreso')
                  setIdsAgrupados(row.out_orders_ids.split(','))
                  setEsPedido(false)
                  setShowAgrupadasModal(true)
                }}
              >
                OE agrupadas
              </span>
            : <Link to={`${routes.OUT_ORDERS}/${row.out_orders_ids}`}>{row.out_orders_ids}</Link>
          }
        </p>
      ),
    },
    {
      Header: <p className='m-0 p-0 text-center'>IDs Pedidos</p>,
      id: 'sales_orders_ids',
      className: 'col-pedidos',
      accessor: (row) => (
        <p className='m-0 p-0 text-center'>
          {row.sales_orders_ids?.split(',').length > 1
            ?
              <span
                className='cursor-pointer text-primary'
                onClick={() => {
                  setModalTitle('Pedidos de venta')
                  setIdsAgrupados(row.sales_orders_ids.split(','))
                  setEsPedido(false)
                  setShowAgrupadasModal(true)
                }}
              >
                Pedidos agrupados
              </span>
            : <Link to={`${routes.VENTAS_PEDIDOS}/${row.sales_orders_ids}`}>{row.sales_orders_ids}</Link>
          }
        </p>
      ),
    },
    ...COLUMNS_2,
    {
      Header: '',
      id: 'actions',
      className:'col-icons',
      accessor: (row) => <i id='threeDots' ref={threeDotsRef} className="bi bi-three-dots-vertical" style={{ fontSize: '1.5rem', cursor: 'pointer' }} onClick={event => handleContextMenu(event, row)} />
    }
  ], [COLUMNS, COLUMNS_2])

  const paginationOptions = {
    totalSize: totalCount,
    obQuery: queryMD ,
    setObQuery: setQueryMD
  };

  useEffect(() => {
    getShipmentOrders()
  }, [getShipmentOrders])

  useEffect(() => {
    getAvailableDispatches()
    helix.register()
  }, [])

  return (
    <>
      <ShipmentResultModal show={showShipmentResult} onHide={onHideShipmentResult} shipmentOrder={currentRow} />

      <AddToDispatchModal show={addToDispatchModal} onHide={() => setAddToDispatchModal(false)} shipmentOrder={currentRow} pendingDispatches={pendingDispatches} getShipmentOrders={getShipmentOrders} />

      <PrintDispatchGuideModal show={dispatchGuideModal} onHide={() => setDispatchGuideModal(false)} />

      <AgrupacionModal show={showAgrupadasModal} onClose={() => setShowAgrupadasModal(false)} title={modalTitle} ids={idsAgrupados} pedido={esPedido} />

      <UpdateShippingCarrierServiceModal
        show={updateShippingCarrierServiceModal}
        onHide={() => setUpdateShippingCarrierServiceModal(false)}
        data={{
          id: currentRow?.id,
          order_delivery_status_id: currentRow?.order_delivery_status_id,
          shipping_carrier_service_id: currentRow?.shipping_carrier_service_id,
        }}
        handleGetShipmentOrders={getShipmentOrders}
      />

      <TableContainer
        title='Órdenes de Envío'
        filter={<Filter loading={loading} queryMD={queryMD} setQueryMD={setQueryMD} />}
        actions={<TableActions selectedRows={selectedRows} handleSelectedRows={setSelectedRows} handleGetShipmentOrders={getShipmentOrders} />}
      >
        {loading && <l-helix color="var(--bs-primary)" style={{ position: "absolute", left: "50%", marginTop: "100px" }}></l-helix>}

        <CustomTable columns={columns} data={data} paginationOptions={paginationOptions} queryMD={queryMD} setQueryMD={setQueryMD} handleContextMenu={handleContextMenu} />
      </TableContainer>

      <ContextMenu
        showContextMenu={showContextMenu}
        setShowContextMenu={setShowContextMenu}
        actions={availableActions()}
        currentPos={currentPos}
        ref={contextRef}
      />
    </>
  )
}

export default ShipmentOrders