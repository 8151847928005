import { useState, useRef, useMemo, useEffect } from 'react'
import * as XLSX from 'xlsx/xlsx.mjs'
import moment from 'moment'
import { toast } from 'react-toastify'
import { helix } from 'ldrs'

import { useAuth } from 'app/modules/auth'
import { useEgresosConsolidados, useClickOutside } from 'hooks'

import { TableContainer } from 'containers'
import { CustomTable } from 'components'
import ContextMenu from 'components/ContextMenu'

import Filter from './Filter'
import ControlGestionModal from './ControlGestionModal'

import { COLUMNS } from './columns'

import { EstadoControlGestionI, EstadoControlGestionLabelI } from 'interfaces'

const estadoControlGestionColor = (idEstado) => {
  switch (idEstado) {
    case EstadoControlGestionI.PENDIENTE:
      return 'estado-celeste'
    case EstadoControlGestionI.EN_PROCESO:
      return 'estado-azul'
    case EstadoControlGestionI.COMPLETADO:
      return 'estado-verde'
    default:
      return 'estado-gris'
  }
}

const estadosControlGestion = (idEstado) => {
  switch (idEstado) {
    case EstadoControlGestionI.PENDIENTE:
      return EstadoControlGestionLabelI[EstadoControlGestionI.PENDIENTE]
    case EstadoControlGestionI.EN_PROCESO:
      return EstadoControlGestionLabelI[EstadoControlGestionI.EN_PROCESO]
    case EstadoControlGestionI.COMPLETADO:
      return EstadoControlGestionLabelI[EstadoControlGestionI.COMPLETADO]
    default:
      return 'Sin estado'
  }
}

const initialQuery = {
  pageNumber: 1,
  pageSize: 10,
  fecha_remito_desde: moment().subtract(1, 'month').format('YYYY-MM-DD'),
  fecha_remito_hasta: moment().format('YYYY-MM-DD'),
  diferencia_real: false,
  diferencia_real_pendiente: false,
}

const EgresosConsolidados = () => {
  const { currentUser } = useAuth()

  const [query, setQuery] = useState(initialQuery)
  const [totalCount, setTotalCount] = useState(0)

  const { loading, handleObtenerEgresosConsolidados } = useEgresosConsolidados()
  const [data, setData] = useState([])

  const [idOrdenEgreso, setIdOrdenEgreso] = useState(null)
  const [controlGestionModal, setControlGestionModal] = useState(false)
  const [valoresControlGestion, setValoresControlGestion] = useState(null)
  const [soloObservacion, setSoloObservacion] = useState(false)

  const obtenerEgresosConsolidados = async () => {
    try {
      let params = {}

      if (query && query.pageNumber) {
        params = {
          ...params,
          ...query,
        }
      }

      const response = await handleObtenerEgresosConsolidados(params)
      const data = response.data.result.map(d => ({
        ...d,
        EstadoControlGestionColor: estadoControlGestionColor(d.IdEstadoControlGestion),
      }))
      const count = response.data.metadata.count

      setData(data)
      setTotalCount(count)
    } catch (error) {
      setData([])
      setTotalCount(0)
      toast.error(error.message)
    }
  }

  const exportarBusqueda = async (values) => {
    try {
      const params = {
        ...query,
        ...values,
        pageNumber: 1,
        pageSize: undefined,
      }
      const response = await handleObtenerEgresosConsolidados(params)

      if (response.data.result) {
        const datos = response.data.result.map(d => {
          const data = {
            ...d,
            FechaRemito: d?.FechaRemito ? moment(d?.FechaRemito).format('DD/MM/YYYY') : '',
            FechaControlGestion: d?.FechaControlGestion ? moment(d?.FechaControlGestion).format('DD/MM/YYYY') : '',
          }
          delete data.IdEstadoOrdenEgreso
          delete data.IdEnvio
          delete data.IdEstadoControlGestion
          delete data.IdUsuarioControlGestion

          return data
        })

        let ws = XLSX.utils.json_to_sheet(datos)

        let wb = XLSX.utils.book_new()
        XLSX.utils.book_append_sheet(wb, ws, "Control de gestión")
        XLSX.writeFile(wb, `Egresos Consolidados ${moment().format("YYYYMMDD-HHmm")}.xlsx`)
      }
    } catch (error) {
      toast.error(error.message)
    }
  }

  /* Menu contextual */
  const [showContextMenu, setShowContextMenu] = useState(false)
  const [currentPos, setCurrentPos] = useState({ x: 0, y: 0 })
  const [currentRow, setCurrentRow] = useState(null)
  const threeDotsRef = useRef(null)
  const contextRef = useRef()

  const handleContextMenu = (e, row) => {
    setCurrentRow(row)

    if (e) {
      e.preventDefault()

      setCurrentPos({ x: e.clientX, y: e.clientY, target: e.target.id })

      if(!showContextMenu) {
        setShowContextMenu(true)
      }
    }
  }

  useClickOutside(contextRef, () => {
    //Para que no cierre la primera vez que hace click en threeDots
    if (currentPos.target === 'threeDots') {
      setCurrentPos({ ...currentPos, target: '' })
      return
    }

    if (showContextMenu) {
      setShowContextMenu(false)
    }
  })

  const availableActions = () => {
    let actions = []

    actions.push({
      label: 'Actualizar estado',
      icon: 'bi bi-pencil-square text-primary',
      action: () => {
        setIdOrdenEgreso(currentRow.IdOrdenEgreso)
        setValoresControlGestion({
          id_estado_control_gestion: currentRow?.IdEstadoControlGestion,
          observacion_control_gestion: currentRow?.ObservacionControlGestion,
        })
        setSoloObservacion(false)
        setControlGestionModal(true)
      }
    })

    actions.push({
      label: 'Editar observación',
      icon: 'bi bi-card-text text-warning',
      action: () => {
        setIdOrdenEgreso(currentRow.IdOrdenEgreso)
        setValoresControlGestion({
          observacion_control_gestion: currentRow?.ObservacionControlGestion
        })
        setSoloObservacion(true)
        setControlGestionModal(true)
      }
    })

    return actions
  }
  /* Fin menu contextual */

  const columns = useMemo(() => [
    ...COLUMNS,
    {
      Header: '',
      id: 'actions',
      className:'col-icons',
      accessor: (row) =>
        availableActions().length > 0 && <i ref={threeDotsRef} id='threeDots' className="bi bi-three-dots-vertical" style={{ fontSize: "1.5rem", cursor:"pointer" }} onClick={event => handleContextMenu(event, row)} />
    }
  ], [COLUMNS])

  const paginationOptions = {
    totalSize: totalCount,
    obQuery: query,
    setObQuery: setQuery,
  }

  useEffect(() => {
    obtenerEgresosConsolidados()
  }, [query])

  useEffect(() => {
    helix.register()
  }, [])

  return (
    <TableContainer
      title={'Egresos consolidados'}
      filter={<Filter loading={loading} query={query} setQuery={setQuery} handleExport={exportarBusqueda} />}
    >
      {loading && <l-helix color="var(--bs-primary)" style={{ position: "absolute", left: "50%", marginTop: "100px" }}></l-helix>}

      <CustomTable columns={columns} data={data || []} paginationOptions={paginationOptions} handleContextMenu={handleContextMenu} />

      <ControlGestionModal
        show={controlGestionModal}
        onHide={() => {
          setIdOrdenEgreso(null)
          setValoresControlGestion(null)
          setSoloObservacion(false)
          setControlGestionModal(false)
        }}
        idOrdenEgreso={idOrdenEgreso}
        valores={valoresControlGestion}
        obtenerEgresosConsolidados={obtenerEgresosConsolidados}
        soloObservacion={soloObservacion}
      />

      <ContextMenu
        ref={contextRef}
        showContextMenu={showContextMenu}
        setShowContextMenu={setShowContextMenu}
        actions={availableActions()}
        currentPos={currentPos}
      />
    </TableContainer>
  )
}

export default EgresosConsolidados