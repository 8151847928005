import { useState, useCallback, useRef, useMemo, useEffect } from 'react'
import { useNavigate } from 'react-router-dom'
import { useFormikContext } from 'formik'
import { Button } from 'react-bootstrap'
import { toast } from 'react-toastify'
import Swal from 'sweetalert2'

import { useAuth } from 'app/modules/auth'
import { useClickOutside, useCustomerSuppliers, usePatients } from 'hooks'

import { TableContainer } from 'containers'
import { CustomTable } from 'components'
import ContextMenu from 'components/ContextMenu'

import { PatientModal, PatientsColumns, PatientsFilter } from '.'
import { PatientModal as AddPatientModal } from 'containers/Modals'

import routes from 'app/routing/routes'
import { RoleI } from 'interfaces'

const initialQuery = {
  pageNumber: 1,
  pageSize: 10,
}

const PatientsTab = ({ editing, handleEditing, handleGetCustomerSupplier }) => {
  const { currentUser } = useAuth()

  const navigate = useNavigate()
  const { values } = useFormikContext();
  const [modal, setModal] = useState(false)

  const [query, setQuery] = useState(initialQuery)
  const [totalCount, setTotalCount] = useState(0)

  const { COLUMNS } = PatientsColumns()

  const [addPatientModal, setAddPatientModal] = useState(false)
  const [patientInfo, setPatientInfo] = useState(null)

  const { handleGetCustomerSupplierPatients, handleAddCustomerSupplierPatient, handleDeleteCustomerSupplierPatient } = useCustomerSuppliers()
  const [patients, setPatients] = useState([])
  const { loading: loadingPatients, handleCreatePatient, handleUpdatePatient } = usePatients()

  const handleCloseModal = () => {
    setPatientInfo(null)
    setModal(false)
  }

  const handleShowModal = () => {
    setModal(true)
  }

  const getPatients = useCallback(async () => {
    try {
      let params = {
        sortOrder: 'DESC',
      }

      if (query && query.pageNumber) {
        params = {
          ...params,
          ...query,
        }
      }

      const response = await handleGetCustomerSupplierPatients(values.id, params)
      setPatients(response.data.result)
      setTotalCount(response.data.metadata.count)
    } catch (error) {
      toast.error(error.message)
      setPatients([])
      setTotalCount(0)
    }
  }, [handleGetCustomerSupplierPatients, values.id, query])

  const createPatient = async (patient) => {
    try {
      // Se crea el paciente
      const responsePatient = await handleCreatePatient(patient)
      toast.success(responsePatient.message)

      // Se vincula el paciente creado con el cliente / proveedor
      const responseCustomer = await handleAddCustomerSupplierPatient(values.id, responsePatient.id)
      toast.success(responseCustomer.message)

      setAddPatientModal(false)
      setPatientInfo(null)
    } catch (error) {
      toast.error(error.message)
    } finally {
      handleGetCustomerSupplier()
      getPatients()
    }
  }

  const updatePatient = async (patientId, patientData) => {
    // console.log("UPDATE", patientData)
    try {
      let data = {
        ...patientData,
        contact_id: patientData?.contact_id ? patientData.contact_id : null,
        birthdate: patientData?.birthdate ? patientData.birthdate : null,
        genre_id: patientData?.genre_id ? patientData.genre_id : null,
        health_insurance: {
          ...patientData?.health_insurance,
          health_insurance_id: patientData.health_insurance?.health_insurance_id || '',
          affiliate_number: patientData.health_insurance?.affiliate_number || '',
          plan: patientData.health_insurance?.plan || '',
        }
      }
      delete data.id
      delete data.documentation_type_name
      delete data.genre_label

      let response = await handleUpdatePatient(patientId, data)
      toast.success(response.message)
    } catch (error) {
      toast.error(error.message)
    } finally {
      setAddPatientModal(false)
      setPatientInfo(null)
      handleEditing(false)
      handleGetCustomerSupplier()
      getPatients()
    }
  }

  const handleAddPatient = async (patient) => {
    try {
      if (patient) {
        const { documentation_type_name, ... rest } = patient
        await handleUpdatePatient(patient.id, rest)

        const response = await handleAddCustomerSupplierPatient(values.id, patient.id)
        toast.success(response.message)
      }
    } catch (error) {
      toast.error(error.message)
    } finally {
      setModal(false)
      handleGetCustomerSupplier()
      getPatients()
    }
  }

  const handleDeletePatient = async (patientId) => {
    try {
      const resp = await Swal.fire({
        title: 'Advertencia',
        text: `¿Quiere desvincular al paciente #${patientId} del cliente/proveedor #${values.id}?`,
        icon: 'warning',
        showDenyButton: true,
        denyButtonText: 'No, cancelar',
        confirmButtonText: `Si, desvincular`,
        customClass: { confirmButton: 'btn btn-primary', denyButton: 'btn btn-secondary' },
        reverseButtons: true,
      })

      if (resp.isConfirmed) {
        const response = await handleDeleteCustomerSupplierPatient(values.id, patientId, 'token')
        toast.success(response.message)
      }
    } catch (error) {
      toast.error(error.message)
    } finally {
      handleEditing(false)
      handleGetCustomerSupplier()
      getPatients()
    }
  }

  /* Menu contextual */
  const [showContextMenu, setShowContextMenu] = useState(false)
  const [currentPos, setCurrentPos] = useState({ x: 0, y: 0 })
  const [currentRow, setCurrentRow] = useState(null)
  const threeDotsRef = useRef(null)
  const contextRef = useRef()

  const handleContextMenu = (e, row) => {
    setCurrentRow(row)

    if (e) {
      e.preventDefault()

      setCurrentPos({ x: e.clientX, y: e.clientY, target: e.target.id })

      if(!showContextMenu) {
        setShowContextMenu(true)
      }
    }
  }

  useClickOutside(contextRef, () => {
    //Para que no cierre la primera vez que hace click en threeDots
    if (currentPos.target === 'threeDots') {
      setCurrentPos({ ...currentPos, target: '' })
      return
    }

    if (showContextMenu) {
      setShowContextMenu(false)
    }
  })

  const availableActions = () => {
    let actions = []

    actions.push({
      label: "Ver",
      icon: "bi bi-eye text-primary",
      action: () => navigate(`${routes.PATIENTS}/${currentRow?.id}`)
    })

    actions.push({
      label: 'Editar',
      icon: 'bi bi-pencil-square text-primary',
      action: () => {
        let data = {
          ...currentRow,
        }
        setPatientInfo(data)
        setAddPatientModal(true)
      }
    })

    actions.push({
      label: 'Desvincular',
      icon: 'bi bi-trash text-danger',
      action: () => handleDeletePatient(currentRow?.id)
    })

    return actions
  }
  /* Fin menu contextual */

  const columns = useMemo(() => [
    ...COLUMNS,
    {
      Header: '',
      id: 'actions',
      className:'col-icons',
      accessor: (row) =>
        <>
          <i id='threeDots' className="bi bi-three-dots-vertical" style={{ fontSize: "1.5rem", cursor:"pointer" }} onClick={event => handleContextMenu(event, row)} ref={threeDotsRef} />
        </>
    }
  ], [COLUMNS])

  const paginationOptions = {
    totalSize: totalCount,
    obQuery: query,
    setObQuery: setQuery,
  }

  useEffect(() => {
    getPatients()
  }, [getPatients])

  return (
    <>
      <PatientModal show={modal} onClose={handleCloseModal} onSave={handleAddPatient} />

      <AddPatientModal
        show={addPatientModal}
        onHide={() => {
          setPatientInfo(null)
          setAddPatientModal(false)
        }}
        values={patientInfo}
        onSave={async (values) => {
          if (!values.documentation && !values.health_insurance.affiliate_number) {
            toast.error('Debe completar el documento y/o nro. de afiliado del paciente')
            return
          }

          if (values.documentation && !values.documentation_type_id) {
            toast.error('Debe seleccionar un tipo de documento para el paciente')
            return
          }

          if (patientInfo) {
            await updatePatient(patientInfo.id, values)
          } else {
            await createPatient(values)
          }
        }}
        saving={loadingPatients}
      />

      {currentUser.roles.some(r => r.id != RoleI.COORDINACION) &&
        <div className='row mb-8 text-end'>
          <div className="col">
            <Button className='BSButton me-2' variant='outline-success' onClick={handleShowModal}>Relacionar paciente</Button>
            <Button className='BSButton' variant='outline-primary' onClick={() => setAddPatientModal(true)}>Añadir paciente</Button>
          </div>
        </div>
      }

      <div className="row">
        <div className="col">
          <TableContainer>
            <PatientsFilter handleData={setPatients} handleTotalCount={setTotalCount} query={query} handleQuery={setQuery} />

            <CustomTable columns={columns} data={patients || []} paginationOptions={paginationOptions} handleContextMenu={handleContextMenu} />
          </TableContainer>

          <ContextMenu
            showContextMenu={showContextMenu}
            setShowContextMenu={setShowContextMenu}
            actions={availableActions()}
            currentPos={currentPos}
            ref={contextRef}
          />
        </div>
      </div>
    </>
  )
}

export default PatientsTab