import { useState, useEffect } from "react";
import { Formik, Form } from "formik";
import { Button, Modal } from "react-bootstrap";
import { SelectField, TextareaBS, InputBS } from "components";
import { useFormikContext } from "formik";
import { useDepots, useGetProductDetail } from "hooks";
import { toast } from "react-toastify";

const EditWarehouseModal = ({ row, onClose, getDepots, show, spaceData }) => {
  const { setFieldValue, values, handleSubmit, resetForm } = useFormikContext();
  const {
    handleGetCustomerSupplier,
    handleGetDepotName,
    handleGetBusinessUnitName,
    handleGetWarehouseName,
    handleGetSpaceData,
    updateDepot,
  } = useDepots();

  const { productFamilies, productMakers } = useGetProductDetail();

  const [customerSupplierOptions, setCustomerSupplierOptions] = useState([]);
  const [businessUnitOptions, setBusinessUnitOptions] = useState([]);
  const [depotGralOptions, setDepotGralOptions] = useState([]);
  const [depotOptions, setDepotOptions] = useState([]);
  const [storage_space_name, set_storage_space_name] = useState("");
  const [loading, setLoading] = useState(true);
  const [customerSupplier_id, set_customer_suppliers_id] = useState(0);
  const [business_unit_id, set_business_unit_id] = useState(0);
  const [general_depot_id, set_general_depot_id] = useState(0);
  const [warehouse_id, set_warehouse_id] = useState(0);
  

  

  // Fetch space data and customer supplier ID
  useEffect(() => {
    const fetchSpaceData = async () => {
      try {
        const result = await handleGetSpaceData(row);
        if (result && result.length > 0) {
          const data = result[0];
          console.log("data-----", data);
          set_customer_suppliers_id(data.customer_suppliers_id);
          set_business_unit_id(data.business_unit_id);
          set_general_depot_id(data.general_depot_id);
          set_warehouse_id(data.warehouse_id);
          set_storage_space_name(data.storage_space_name);
        }
        // Solo cambiar el estado de loading cuando se carguen todos los datos
        setLoading(false);
      } catch (error) {
        console.error("Error fetching space data:", error);
        setLoading(false); // Asegúrate de desactivar el loading si ocurre un error
      }
    };
  
    if (row) {
      fetchSpaceData();
    }
  }, [row, handleGetSpaceData]);
  


    // Set default value for owner when options are loaded
    useEffect(() => {
      if (customerSupplierOptions.length > 0) {
        const defaultOption = customerSupplierOptions.find(option => option.value === customerSupplier_id);
        if (defaultOption) {
          setFieldValue("owner", defaultOption.value);
        }
      }
    }, [customerSupplierOptions, customerSupplier_id, setFieldValue]);
  
  
    useEffect(() => {
    if (businessUnitOptions.length > 0) {
      const defaultOption = businessUnitOptions.find(option => option.value === business_unit_id);
      if (defaultOption) {
        setFieldValue("u_negocio", defaultOption.value);
      }
      }
    }, [businessUnitOptions, business_unit_id, setFieldValue]);

    useEffect(() => {
      if (depotGralOptions.length > 0) {
        const defaultOption = depotGralOptions.find(option => option.value === general_depot_id);
        if (defaultOption) {
          setFieldValue("depotGral", defaultOption.value);
        }
      }
    }, [depotGralOptions, general_depot_id, setFieldValue]);

    useEffect(() => {
      if (depotOptions.length > 0) {
        const defaultOption = depotOptions.find(option => option.value === warehouse_id);
        if (defaultOption) {
          setFieldValue("deposit", defaultOption.value);
        }
      }
    }, [depotOptions, warehouse_id, setFieldValue]);

    useEffect(() => {
      if (storage_space_name.length > 0) {  
        setFieldValue("space", storage_space_name); // Seteamos el valor del campo "space"
      }
    }, [storage_space_name, setFieldValue]);
    

  // Fetch all options
  useEffect(() => {
    const fetchOptions = async () => {
      try {
        const customerSupplier = await handleGetCustomerSupplier();
        const depots = await handleGetDepotName();
        const businessUnits = await handleGetBusinessUnitName();
        const warehouses = await handleGetWarehouseName();
  
        setCustomerSupplierOptions(
          customerSupplier?.map(({ id, business_name }) => ({
            label: business_name,
            value: id,
          })) || []
        );
  
        setDepotGralOptions(
          depots?.map(({ id, name }) => ({
            label: name,
            value: id,
          })) || []
        );
  
        setBusinessUnitOptions(
          businessUnits?.map(({ id, name }) => ({
            label: name,
            value: id,
          })) || []
        );
  
        setDepotOptions(
          warehouses?.map(({ warehouse_id, warehouse_name, cool_type_name }) => ({
            label: warehouse_name,
            value: warehouse_id,
            coolType: cool_type_name,
          })) || []
        );
        
        // Cuando todas las opciones se carguen, también se desactiva el loading
        setLoading(false);
      } catch (error) {
        console.error("Error fetching options:", error);
        setLoading(false); // Asegúrate de desactivar el loading si ocurre un error
      }
    };
  
    fetchOptions();
  }, [
    handleGetCustomerSupplier,
    handleGetDepotName,
    handleGetBusinessUnitName,
    handleGetWarehouseName,
  ]);
  



  // Update temperature based on selected depot
  useEffect(() => {
    const selectedWarehouse = depotOptions.find(
      (option) => option.value === values.deposit
    );

    if (selectedWarehouse) {
      setFieldValue("temperature", selectedWarehouse.coolType || "");
    } else {
      setFieldValue("temperature", "");
    }
  }, [values.deposit, depotOptions, setFieldValue]);

  const handleSave = async () => {
    try {
      let response;
      values.space_id = row.space_id;
      if(values.space_id) {
        response = await updateDepot(values);
      }
      if (response.status === 200) {
        toast.success("Éxito al editar depósito");
        //resetForm(); 
      } else {
        toast.error("Error al editar depósito");
      }
    } catch (error) {
      toast.error("Error al editar depósito");
      console.error("Error al editar depósito:", error);
    } finally {
      onClose();
      getDepots();
    }
  };

  const handleClose = () => {
    //resetForm();
    onClose();
  };

  return (
    <Modal show={show} onHide={handleClose} backdrop="static" centered>
      <Modal.Header closeButton={!loading}>
        <Modal.Title>Editar depósito</Modal.Title>
      </Modal.Header>

      <Modal.Body>
        <Form
          onSubmit={(e) => {
            e.preventDefault();
            handleSave();
          }}
        >
          <div className="row mt-5">
            <div className="col">
              <SelectField
                id="depotGral"
                name="depotGral"
                options={depotGralOptions}
                label="Depósito General"
                placeholder="Seleccionar depósito general"
                disabled={loading}
              />
            </div>
          </div>

          <div className="row mt-5">
            <div className="col">
              <SelectField
                id="deposit"
                name="deposit"
                options={depotOptions}
                label="Depósito"
                placeholder="Seleccionar depósito"
                disabled={loading}
              />
            </div>
          </div>

          <div className="row mt-5">
            <div className="col">
              <SelectField
                id="u_negocio"
                name="u_negocio"
                options={businessUnitOptions}
                label="U. negocio"
                placeholder="Seleccionar U. negocio"
                disabled={loading}
              />
            </div>
          </div>

          <div className="row mt-5">
            <div className="col">
              <InputBS
                id="temperature"
                name="temperature"
                label="Temperatura"
                placeholder="Temperatura"
                disabled={true}
              />
            </div>
          </div>

          <div className="row mt-5">
            <div className="col">
              <InputBS
                id="space"
                name="space"
                label="Espacio"
              />
            </div>
          </div>



          <SelectField
            id="owner"
            name="owner"
            label="Dueño"
            placeholder="Seleccionar dueño"
            options={customerSupplierOptions}
            disabled={loading}
          />

          <div className="separator my-8"></div>

          <div className="row">
            <div className="col-12 text-end">
              <Button
                type="button"
                variant="secondary"
                onClick={handleClose}
                className="me-3"
                disabled={loading}
              >
                <i className="bi bi-slash-circle" />
                Cancelar
              </Button>

              <Button type="submit" variant="primary" disabled={loading}>
                <i className="bi bi-save me-2" />
                {loading ? "Cargando..." : "Guardar"}
              </Button>
            </div>
          </div>
        </Form>
      </Modal.Body>
    </Modal>
  );
};

export default EditWarehouseModal;
