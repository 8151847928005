import { useState, useCallback, useEffect } from 'react'
import { useParams, useLocation, Link } from 'react-router-dom'
import { OverlayTrigger, Tooltip as TooltipBS } from 'react-bootstrap'
import moment from 'moment'

import { useAuth } from 'app/modules/auth'
import { useSalesOrders, useUsers } from 'hooks'
import { Comments, Tooltip } from 'components'

import { toAbsoluteUrl } from '_metronic/helpers'

import './article.css'
import { toast } from 'react-toastify'

import { RoleNameI } from 'interfaces'
import routes from 'app/routing/routes'

const CommentsTab = () => {
  const { id } = useParams()
  const { auth } = useAuth()
  const location = useLocation()

  const { handleGetNotes, handleAddNote } = useSalesOrders()

  const { handleGetUsers } = useUsers()
  const [userData, setUserData] = useState([])

  const [localNotes, setLocalNotes] = useState([])
  const [observations, setObservations] = useState("")

  const getLocalNotes = useCallback(async () => {
    try {
      const response = await handleGetNotes(id)

      const defineRoles = (roles) => {
        let formatted_roles = roles.slice(0, -1)
        formatted_roles = formatted_roles.split(',')

        if (Array.isArray(formatted_roles)) {
          formatted_roles = formatted_roles.map(fr => RoleNameI[fr])
        } else {
          formatted_roles = RoleNameI[formatted_roles]
        }

        return formatted_roles
      }

      const data = response.data.result.map(d => ({
        ...d,
        user_roles: d?.user_roles ? defineRoles(d.user_roles) : 'Sin rol'
      }))

      setLocalNotes(data)
    } catch (error) {
      toast.error(error.message)
    }
  }, [handleGetNotes, id])

  const getUsers = useCallback(async () => {
    const response = await handleGetUsers()
    const data = response.data.result.map((user) => ({
      id: user.id,
      display: user.name
    }))

    setUserData(data);
  }, [handleGetUsers])

  // Función para procesar las menciones
  const formatNote = (note) => {
    return note.replace(/@\[(.+?)\]\((\d+)\)/g, (match, p1, p2) => {
      return `<span class="text-primary">${p1}</span>`
    })
  }

  const handleSubmit = async () => {
    try {
      await handleAddNote(id, { note: observations })
      setObservations("")
    } catch (error) {
      toast.error(error.message)
    } finally {
      getLocalNotes()
    }
  }

  useEffect(() => {
    getUsers()
    getLocalNotes()
  }, [getUsers, getLocalNotes])

  return (
    <>
      <div className="position-fixed top-0 right-0 bottom-0 left-0 bg-black" style={{ opacity: "0.4" }}></div>

      <div className="rounded-top-left-xl bg-white d-flex flex-column w-110 max-w-md rounded-top-xl border dark-bg-gray-950" style={{ minHeight: "50vh" }}>
        <div className="flex-grow-1 p-4 d-grid gap-4">
          {localNotes.map(((msg, idx) => (
            <div key={idx}>
              {msg.user_id != auth.user.user_id
                ?
                  <div className="d-flex align-items-start gap-2">
                    <OverlayTrigger
                      placement='top'
                      overlay={
                        <TooltipBS id={`tooltip-${msg.id}`}>
                          <div className='text-start'>
                            <h2 className='m-0 p-0 fs-6'>{msg.user_name}</h2>

                            <p className='m-0 p-0 mt-4'>Rol{msg.user_roles.length > 1 ? 'es' : ''}:</p>
                            <ul className='list-style-none m-0 p-0'>
                              {msg.user_roles.map((role, index) => (
                                <li key={index} className='m-0 p-0'><strong>{role}</strong></li>
                              ))}
                            </ul>
                          </div>
                        </TooltipBS>
                      }
                    >
                      <div className='avatar-container'>
                        <img
                          src={toAbsoluteUrl(`/media/avatars/${msg.user_avatar}`)}
                          alt={msg?.user_name}
                          className="rounded-full"
                          height="30"
                          width="30"
                          style={{
                            aspectRatio: "30/30",
                            objectFit: "cover",
                          }}
                        />
                      </div>
                    </OverlayTrigger>

                    <div className="bg-light rounded-xl p-2 text-sm max-w-90vw leading-loose">
                      <strong className='text-primary'>{msg?.user_name} <span className='text-muted'>[{msg.user_roles[0]}]</span></strong>

                      <p className="my-2" dangerouslySetInnerHTML={{ __html: formatNote(msg.note) }}></p>

                      <div className='row'>
                        <div className='col-9'>
                          <p className="text-sm text-muted text-start m-0 p-0">
                            Desde Orden de Egreso <Link to={`${routes.OUT_ORDERS}/${msg.out_order_id}`}>#{msg.out_order_id}</Link>
                          </p>
                        </div>

                        <div className='col-3'>
                          <p className="text-sm text-muted text-end m-0 p-0">{msg.created_at ? moment(msg.created_at).format('DD/MM HH:mm') : ''}</p>
                        </div>
                      </div>
                    </div>
                  </div>
                :
                  <div className="d-flex align-items-end justify-content-end gap-2">
                    <div className="bg-light rounded p-2 text-sm max-w-80vw leading-loose">
                      <strong className='text-primary'>Vos <span className='text-muted'>[{msg.user_roles[0]}]</span></strong>

                      <p className="mb-1" dangerouslySetInnerHTML={{ __html: formatNote(msg.note) }}></p>

                      <div className='row'>
                        <div className='col-9'>
                          <p className="text-sm text-muted text-start m-0 p-0">
                            Desde
                            {msg?.sales_order_id
                              ? <> Pedido de Venta <Link to={`${routes.VENTAS_PEDIDOS}/${msg.sales_order_id}`}>#{msg.sales_order_id}</Link></>
                              : <> Orden de Egreso <Link to={`${routes.OUT_ORDERS}/${msg.out_order_id}`}>#{msg.out_order_id}</Link></>
                            }
                          </p>
                        </div>

                        <div className='col-3'>
                          <p className="text-sm text-muted text-end m-0 p-0">{msg.created_at ? moment(msg.created_at).format('DD/MM HH:mm') : ''}</p>
                        </div>
                      </div>
                    </div>
                  </div>
              }
            </div>
          )))}
        </div>

        <div className='separator'></div>

        <div className='p-5'>
          <Comments usersData={userData} handlePostNote={handleSubmit} notes={observations} setNotes={setObservations} />
        </div>
      </div>
    </>
  )
}

export default CommentsTab