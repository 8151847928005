import service from "../service";

const PATH = {
  Products: "/product",
  Article: "/product/var",
  ArticleMed: "/article-med",
}

export class Articles {
  static getArticles = async (params) => {
    const response = await service.get(PATH.Article + params)
    return response
  }

  static getArticlesV2 = async (params) => {
    const response = await service.get(PATH.Article, {
      params,
    })
    return response
  }

  static getArticle = async (params) => {
    const { product_id, product_var_id } = params
    const response = await service.get(`/product/${product_id}/var/${product_var_id}`)

    return response
  }
  
  static searchArticle = async (params) => {
    const response = await service.get(`${PATH.Article}/search`, params)
    return response
  }


  static createArticle = async (data) => {
    const { product_id, ...rest } = data
    const response = await service.post(`/product/${product_id}/var`, rest)

    return response
  }

  static updateArticle = async (data) => {
    const {product_id, id, ...rest} = data

    const response = await service.put(`${PATH.Products}/${product_id}/var/${id}`, rest)
    return response
  }

  static enableDisableArticle = async (data) => {
    const {cancel_date, id, ...rest} = data
    const response = await service.put(`${PATH.Products}/var/${id}/${cancel_date}`, rest)
    return response
  }

  static updateArticleCost = async (data) => {
    const {product_var_id, ...rest} = data
    const response = await service.put(`${PATH.Article}/${product_var_id}/costos`, rest)
    return response
  }

  static getArticlesMed = async () => {
    const response = await service.get(PATH.ArticleMed)
    return response
  }

  static getArticleMed = async (id) => {
    const response = await service.get(`${PATH.ArticleMed}/${id}`)
    return response
  }

  static createArticleMed = async (data) => {
    const response = await service.post(PATH.ArticleMed, data)
    return response
  }

  static updateArticleMed = async (id, data) => {
    const response = await service.patch(`${PATH.ArticleMed}/${id}`, data)
    return response
  }

  static getStock = async (id) => {
    const response = await service.get(`${PATH.Products}/var/${id}/stock`)
    return response
  }

  static getMovements = async (id, params) => {
    const response = await service.get(`${PATH.Products}/var/${id}/stock-movements`, {
      params,
    })
    return response
  }

  static getArticleStockDetails = async (params) => {
    const {id, ...rest} = params;
    const response = await service.get(`${PATH.Products}/var/${id}`, {rest})
    return response
  }

}