import { useEffect, useState } from 'react'
import { useLocation, useNavigate } from 'react-router-dom'
import { Formik, Form } from 'formik'
import { AssignmentOutlined } from '@mui/icons-material'
import { toast } from 'react-toastify';

import { useAuth } from 'app/modules/auth'
import { useSalesOrders } from 'hooks/Sales'

import { DetailContainer, TabsContainer } from 'containers'
import { GeneralTab } from './partials'
import { FormButton } from 'components'

import { initialValues, schema, submitAdapter } from './constants'

import { OrderTypeI, RealUserI, RoleI } from 'interfaces';
import routes from 'app/routing/routes';

const AddSalesOrder = () => {
  const { currentUser } = useAuth()
  const { state } = useLocation();
  const navigate = useNavigate()

  const { loading, handleAddSalesOrder } = useSalesOrders()
  const [orderTypeId, setOrderTypeId] = useState(OrderTypeI.PEDIDO_VENTA);
  const [title, setTitle] = useState("Nuevo pedido de venta");

  const handleSubmit = async (values) => {
    try {
      let data = submitAdapter(values)
      data = {
        ...data,
        user_id: currentUser?.id ? currentUser.id : currentUser?.user?.id,
        dispatch_date: values?.dispatch_date ? values.dispatch_date : null,
        order_type_id:orderTypeId
      }
      const response = await handleAddSalesOrder(data, 'token')
      toast.success("Registro creado correctamente", {theme:"colored"})
      navigate(`${routes.VENTAS_PEDIDOS}/${response.data.id}`)
    } catch (error) {
      toast.error(error.message)
    }
  }

  useEffect(()=>{
    if(state){
      setOrderTypeId(state.type)
      if(state.type==OrderTypeI.PEDIDO_VENTA){
        setTitle("Nuevo pedido de venta")
      }
      else if(state.type==OrderTypeI.PEDIDO_VENTA_ALMACENAMIENTO_PROPIO){
        setTitle("Nuevo pedido de venta con almacenamiento propio")
      }
      else if (state.type==OrderTypeI.PEDIDO_EGRESO){
        setTitle("Nuevo pedido de egreso")
      }
    }   
  }, [state])

  return (
    <DetailContainer
      title={title}
      tipoOrdenV2={state.type}
      irVersion2={
        currentUser.roles.some(r => r.id = RoleI.ADMIN) ||
        [RealUserI.LEANDRO_FERNANDEZ, RealUserI.MALENA_CABALLERO, RealUserI.VANESA_BATAGLIA].includes(currentUser.user_id)
      }
    >
      <Formik
        initialValues={initialValues}
        validationSchema={schema}
        onSubmit={handleSubmit}
        enableReinitialize
      >
        {({ values, errors }) => {
          return (
            <Form className="d-flex flex-column" encType="multipart/form-data">
              <TabsContainer
                initialValue='general'
                tabs={[{ value: 'general', label: "General", icon: <AssignmentOutlined />, show: true }]}
                tabsContent={[<GeneralTab state={state}/>]}
              />

              <FormButton loading={loading} />
            </Form>
          )
        }}
      </Formik>
    </DetailContainer>
  )
}

export default AddSalesOrder