import { FC, useState, useEffect, useRef, useMemo } from 'react'
import { Button } from 'react-bootstrap'
import { Formik } from 'formik'
import CreateWarehouseModal from './CreateWarehouseModal'
import EditWarehouseModal from './EditWarehouseModal'
import { TableContainer } from 'containers'
import { CustomTable } from 'components'
import Filter from './Filter'
import useGetColumns from './columns'
import useDepots from 'hooks/Inventory/useDepots'
import { helix } from 'ldrs'
import ContextMenu from 'components/ContextMenu'
import { useStock, useClickOutside } from 'hooks'
import Swal from 'sweetalert2'
import { toast } from 'react-toastify'
import { Depots } from '../../../../services/models/Depots';

const initialObQuery = {
  pageNumber: "1",
  pageSize: "10"
}

const Wharehouse = () => {
  const [data, setData] = useState([])
  const { COLUMNS } = useGetColumns()
  const [filter, setFilter] = useState("")
  const [queryMD, setQueryMD] = useState(initialObQuery)
  const [totalCount, setTotalCount] = useState(0)
  const { handleGetDepots, loading, handleGetSpaceData } = useDepots()
  const [warehouseModal, setWarehouseModal] = useState(false)
  const [editWarehouseModal, setEditWarehouseModal] = useState(false)
  const rowsPerPage = 10


  const getDepots = async () => {
    try {
      if (queryMD && queryMD.pageNumber) {
  
        const idFilter = new URLSearchParams(filter).get('id_filter') || '';
        const spaceFilter = new URLSearchParams(filter).get('space') || '';
        const u_negocioFilter = new URLSearchParams(filter).get('u_negocio') || '';        
        const ownerFilter = new URLSearchParams(filter).get('owner') || '';  
        const depositFilter = new URLSearchParams(filter).get('deposit') || '';  
        const warehouseFilter = new URLSearchParams(filter).get('warehouse') || '';  
        const temperatureFilter = new URLSearchParams(filter).get('temperature') || ''; 
  
        const query = { filter, queryMD }
        const response = await handleGetDepots(query)
  
        if (response.result && response.result.length > 0) {
          const data = []
          response.result.forEach((obj) => {
            obj.wharehouse.forEach((wharehouse) => {
              wharehouse.storage_spaces.forEach((space) => {

                if (
                  (!idFilter || (space.id && space.id.toString() === idFilter)) &&
                  (!u_negocioFilter || (space.business_unit_id && space.business_unit_id.toString()===u_negocioFilter)) &&
                  (!spaceFilter || (space.name && space.name.toLowerCase().includes(spaceFilter.toLowerCase()))) &&
                  (!ownerFilter || (space.customer_supplier_name && space.customer_supplier_name.toLowerCase().includes(ownerFilter.toLowerCase()))) &&
                  (!depositFilter || (space.general_depot_name && space.general_depot_name.toLowerCase().includes(depositFilter.toLowerCase()))) &&
                  (!warehouseFilter || (space.warehouse_name && space.warehouse_name.toLowerCase().includes(warehouseFilter.toLowerCase()))) &&
                  (!temperatureFilter || (space.cool_type_name && space.cool_type_name.toLowerCase().includes(temperatureFilter.toLowerCase())))
                ) {
                  data.push({
                    space_id: space.id,
                    business_unit_name: space.business_unit_name,
                    space_storage_name: space.name,
                    owner: space.customer_supplier_name,
                    general_depot: space.general_depot_name,
                    wharehouse: space.warehouse_name,
                    warehouse_temp: space.cool_type_name,
                  })
                }
              })
            })
          })
          setData(data)
          setTotalCount(data.length)
  
          console.log("Datos encontrados:", data)  
        } else {
          setData([])
          setTotalCount(0)
          console.log("No se encontraron resultados.")
        }
      }
    } catch (error) {
      setData([])
      console.log(error)
    }
  }

  const columns = useMemo(() => {
    let cols = [...COLUMNS]

    if (!loading) {
      cols.push({
        Header: '',
        id: 'actions',
        className:'col-icons',
        accessor: (row) => (
          <i ref={threeDotsRef} id='threeDots' className="bi bi-three-dots-vertical" style={{ fontSize: "1.5rem", cursor:"pointer" }} onClick={event => handleContextMenu(event, row)} />
        )
      })
    }

    return cols
  }, [COLUMNS, loading])

  const handlePageChange = (pageNumber) => {
    if (pageNumber >= 1 && pageNumber <= totalPages) {
      setQueryMD((prevQuery) => ({
        ...prevQuery,
        pageNumber: pageNumber.toString(),
      }))
    }
  }


  /* Menu contextual */
    const [showContextMenu, setShowContextMenu] = useState(false)
    const [currentPos, setCurrentPos] = useState({ x: 0, y: 0 })
    const [currentRow, setCurrentRow] = useState(null)
    const threeDotsRef = useRef(null)
    const contextRef = useRef()
  
    const handleContextMenu = (e, row) => {
      setCurrentRow(row)
  
      if (e) {
        e.preventDefault()
        setCurrentPos({ x: e.clientX, y: e.clientY, target: e.target.id })
  
        if (!showContextMenu) {
          setShowContextMenu(true)
        }
      }
    }
  
    useClickOutside(contextRef, () => {
      // Para que no cierre la primera vez que hace click en threeDots
      if (currentPos.target === 'threeDots') {
        setCurrentPos({ ...currentPos, target: '' })
        return
      }
  
      if (showContextMenu) {
        setShowContextMenu(false)
      }
    })
  
    const availableActions = () => {
      let actions = []

      actions.push({
        label: "Editar",
        icon: "bi bi-pencil-square text-primary fs-3",
        action: () => editDeposit(currentRow)
      })
  
      actions.push({
        label: 'Eliminar',
        icon: 'bi bi-trash text-danger fs-3',
        action: () => deleteDeposit(currentRow)
      })
  
      return actions
    }

const editDeposit = async (row) => {
  try {
    if (row) {
      setEditWarehouseModal(true);
    }
  } catch (err) {
    toast.error(err.message);
  }
};

      


    const deleteDeposit = async (row) => {
      try {
        if (row) { 
          const msg = `Va a eliminar el deposito [${row.space_id}] ${row.space_storage_name}, ¿confirma?`;
  
          const resp = await Swal.fire({
            title: "Advertencia",
            text: msg,
            icon: "warning",
            showDenyButton: true,
            denyButtonText: "No, cancelar",
            confirmButtonText: "Si, eliminar",
            customClass: { confirmButton: 'btn btn-primary', denyButton:"btn btn-secondary" },
            reverseButtons: true
          });
  
          if (resp.isConfirmed) {
            await Depots.cancelDepot(row.space_id);
            toast.success('Deposito eliminado correctamente');
            getDepots();
          }
        }
      } catch (err) {
        toast.error(err.message);
      }

    };

  const totalPages = Math.ceil(totalCount / rowsPerPage)

  const currentData = data.slice(
    (queryMD.pageNumber - 1) * rowsPerPage,
    queryMD.pageNumber * rowsPerPage
  )

  const paginationOptions = {
    totalSize: totalCount,
    obQuery: queryMD,
    setObQuery: setQueryMD
  }

  useEffect(() => {
    getDepots()
  }, [handleGetDepots, filter, queryMD])

  useEffect(() => {
    helix.register()
  }, [])

  return (
    <>
      <Formik
        initialValues={{
          depotGral: '',
          deposit: '',
          u_negocio: '',
          temperature: '',
          space: '',
          owner: ''
        }}
        onSubmit={(values) => {
          console.log('Formulario enviado:', values)
          setWarehouseModal(false)
        }}
      >
        {({ handleSubmit }) => (
          <>
            <CreateWarehouseModal
              show={warehouseModal}
              onClose={() => setWarehouseModal(false)}
              getDepots={getDepots}
              onSubmit={handleSubmit}
            />

            <EditWarehouseModal
              row={currentRow}
              onClose={() => setEditWarehouseModal(false)}
              getDepots={getDepots}
              show={editWarehouseModal}
              //spaceData={handleGetSpaceData(currentRow)}
              //onSubmit={handleSubmit}
            />

            <TableContainer
              createButton={
                <Button
                  variant="primary"
                  onClick={() => setWarehouseModal(true)}
                  disabled={loading}
                >
                  <i className="bi bi-plus-lg me-2" />
                  Nuevo depósito
                </Button>
              }
              title="Depósitos"
              filter={<Filter setFilter={setFilter} queryMD={queryMD} setQueryMD={setQueryMD} />}
            >
              {loading && (
                <l-helix
                  color="var(--bs-primary)"
                  style={{ position: 'absolute', left: '50%', marginTop: '100px' }}
                ></l-helix>
              )}

              <CustomTable
                columns={columns}
                data={currentData}
                paginationOptions={paginationOptions}
                queryMD={queryMD}
                setQueryMD={setQueryMD}
              />
            </TableContainer>
            <ContextMenu
              ref={contextRef}
              showContextMenu={showContextMenu}
              setShowContextMenu={setShowContextMenu}
              actions={availableActions()}
              currentPos={currentPos}
            />
          </>
        )}
      </Formik>
    </>
  )
}

export default Wharehouse
