import { useState, useCallback } from 'react'

import { EgresosConsolidados } from 'services'

const useEgresosConsolidados = () => {
  const [loading, setLoading] = useState(false)

  const handleObtenerEgresosConsolidados = useCallback(async (params) => {
    try {
      setLoading(true)
      const response = await EgresosConsolidados.obtenerEgresosConsolidados(params)

      return response
    } catch (error) {
      throw error
    } finally {
      setLoading(false)
    }
  }, [])

  const handleActualizarEstadoControlGestion = useCallback(async (id, data) => {
    try {
      setLoading(true)
      const response = await EgresosConsolidados.actualizarEstadoControlGestion(id, data)

      return response
    } catch (error) {
      throw error
    } finally {
      setLoading(false)
    }
  }, [])

  const handleActualizarObservacionControlGestion = useCallback(async (id, data) => {
    try {
      setLoading(true)
      const response = await EgresosConsolidados.actualizarObservacionControlGestion(id, data)

      return response
    } catch (error) {
      throw error
    } finally {
      setLoading(false)
    }
  }, [])

  const handleObtenerEstadosControlGestion = useCallback(async (params) => {
    try {
      setLoading(true)
      const response = await EgresosConsolidados.obtenerEstadosControlGestion(params)

      return response
    } catch (error) {
      throw error
    } finally {
      setLoading(false)
    }
  }, [])

  return {
    loading,
    handleObtenerEgresosConsolidados,
    handleActualizarEstadoControlGestion,
    handleActualizarObservacionControlGestion,
    handleObtenerEstadosControlGestion,
  }
}

export default useEgresosConsolidados