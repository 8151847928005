import { useState, useCallback, useEffect } from 'react'
import { Formik, Form } from 'formik'
import { Button } from 'react-bootstrap'
import { AsyncTypeahead } from 'react-bootstrap-typeahead'
import moment from 'moment'
import { toast } from 'react-toastify'
import 'react-bootstrap-typeahead/css/Typeahead.css';

import { useCustomerSuppliers, useEgresosConsolidados } from 'hooks'

import { InputV2, SelectV2 } from 'components/v2'
import { Checkbox, Label } from 'components'

const initialValues = {
  id: '',
  remito: '',
  fecha_remito_desde: moment().subtract(1, 'month').format('YYYY-MM-DD'),
  fecha_remito_hasta: moment().format('YYYY-MM-DD'),
  id_estado_control_gestion: [],
  id_cliente: [],
  diferencia_real: false,
  diferencia_real_pendiente: false,
}

const Filter = ({ loading, query, setQuery, handleExport }) => {
  const [values, setValues] = useState(initialValues)

  const { handleGetCustomerSuppliers } = useCustomerSuppliers()
  const [cargandoClientes, setCargandoClientes] = useState(false)
  const [clientes, setClientes] = useState([])
  const [clientesSeleccionados, setClientesSeleccionados] = useState([])

  const { handleObtenerEstadosControlGestion } = useEgresosConsolidados()
  const [estados, setEstados] = useState([])

  const filter = (values) => {
    const params = {
      ...query,
      ...values,
      id: values?.id || undefined,
      remito: values?.remito || undefined,
      fecha_remito_desde: values?.fecha_remito_desde || undefined,
      fecha_remito_hasta: values?.fecha_remito_hasta || undefined,
      id_estado_control_gestion: values?.id_estado_control_gestion || undefined,
      id_cliente: values?.id_cliente || undefined,
      diferencia_real: values?.diferencia_real || undefined,
      diferencia_real_pendiente: values?.diferencia_real_pendiente || undefined,
      pageNumber: 1,
    }

    setQuery(params)
  }

  const buscarClientes = async (query) => {
    setCargandoClientes(true)

    try {
      let params = {
        cliente: query,
      }
      const response = await handleGetCustomerSuppliers(null, null, params)
      const data = response.data.result.map(d => ({
        id: d.id,
        business_name: d.business_name,
        documentation: d.documentation,
        label: `[${d.id}] ${d.business_name} | ${d.documentation_type_name}: ${d.documentation}`
      }))

      setClientes(data)
    } catch (error) {
      setClientes([])
      toast.error(error.message)
    } finally {
      setCargandoClientes(false)
    }
  }

  const obtenerEstadosControlGestion = useCallback(async () => {
    try {
      let params = {}
      const response = await handleObtenerEstadosControlGestion(params)
      const data = response.data.result.map(d => ({
        value: d.id,
        label: d.label,
      }))

      setEstados(data)
    } catch (error) {
      setEstados([])
      toast.error(error.message)
    }
  }, [handleObtenerEstadosControlGestion])

  useEffect(() => {
    obtenerEstadosControlGestion()
  }, [obtenerEstadosControlGestion])

  return (
    <Formik
      initialValues={values}
      onSubmit={filter}
      enableReinitialize
    >
      {({ values, errors, setFieldValue, submitForm }) => {
        // console.log(errors)

        return (
          <Form>
            <div className='row'>
              <InputV2 id='id' name='id' col='col-2' label='Nro.' muted disabled={loading} />

              <InputV2 id='remito' name='remito' col='col-3' label='Remito' muted disabled={loading} />

              <InputV2 id='fecha_remito_desde' name='fecha_remito_desde' type='date' col='col-2' label='Desde F. remito' muted disabled={loading} />
              <InputV2 id='fecha_remito_hasta' name='fecha_remito_hasta' type='date' col='col-2' label='Hasta F. remito' muted disabled={loading} />

              <SelectV2
                id='id_estado_control_gestion'
                name='id_estado_control_gestion'
                col='col-3'
                label='Estado Ctrl. Gestión'
                noLabel
                options={estados}
                placeholder='Seleccionar estado(s)'
                isMulti
                disabled={loading}
              />
            </div>

            <div className='row'>
              <div className='col-8'>
                <AsyncTypeahead
                  id='clientes-asynctypeahead'
                  isLoading={cargandoClientes}
                  onSearch={(query) => {
                    if (query.length >= 1) {
                      buscarClientes(query)
                    }
                  }}
                  options={clientes}
                  labelKey={opt => opt.label}
                  onChange={selectedOptions => {
                    // Asigna directamente el array de IDs de las opciones seleccionadas
                    setFieldValue('id_cliente', selectedOptions.map(option => option.id))

                    // Actualizo el estado local de clientes seleccionados
                    setClientesSeleccionados(selectedOptions)
                  }}
                  selected={clientesSeleccionados.filter(cliente => values.id_cliente.includes(cliente.id))}
                  placeholder='Buscar cliente (ID, CUIT, Razón social)'
                  multiple
                  disabled={loading}
                />
                <p className='text-muted'>Cliente(s)</p>
              </div>

              <div className='col-2'>
                <div className='d-flex flex-column justify-content-between align-items-start'>
                  <div className='d-flex flex-row justify-content-between align-items-center mt-2'>
                    <Checkbox id='diferencia_real' disabled={loading} />
                    <Label htmlFor='diferencia_real' label='Diferencia real' styles='mb-0 ms-4' />
                  </div>
                </div>
              </div>

              <div className='col-2'>
                <div className='d-flex flex-column justify-content-between align-items-start'>
                  <div className='d-flex flex-row justify-content-between align-items-center mt-2'>
                    <Checkbox id='diferencia_real_pendiente' disabled={loading} />
                    <Label htmlFor='diferencia_real_pendiente' label='Dif. Real + pendiente' styles='mb-0 ms-4' />
                  </div>
                </div>
              </div>
            </div>

            <div className='row'>
              <div className='col text-end'>
                <Button
                  type='button'
                  variant='secondary'
                  className='me-2'
                  onClick={() => {
                    handleExport(filter(values))
                  }}
                  disabled={loading}
                >
                  <i className='bi bi-file-excel' />
                  Exportar
                </Button>

                <Button
                  type='button'
                  variant='secondary'
                  className='me-2'
                  onClick={() => {
                    setFieldValue('id', '')
                    setFieldValue('remito', '')
                    setFieldValue('fecha_remito_desde', '')
                    setFieldValue('fecha_remito_hasta', '')
                    setFieldValue('id_estado_control_gestion', [])
                    setFieldValue('id_cliente', [])
                    setFieldValue('diferencia_real', false)
                    setFieldValue('diferencia_real_pendiente', false)

                    setClientesSeleccionados([])

                    submitForm()
                  }}
                  disabled={loading}
                >
                  <i className='bi bi-magic me-1' />
                  Limpiar
                </Button>

                <Button type='submit' variant='primary' disabled={loading}>
                  <i className='bi bi-search me-1' />
                  Buscar
                </Button>
              </div>
            </div>
          </Form>
        )
      }}
    </Formik>
  )
}

export default Filter