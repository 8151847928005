export interface AgentTypeI {
  id: number | string;
  name: string;
  is_active: number | boolean;
}

export interface GetAgentTypesI {
  result: AgentTypeI[];
}

export interface GetAgentTypeI {
  result: AgentTypeI;
}

export enum AgentTypesI {
  DROGUERIA = 1,
  LABORATORIO = 2,
  DISTRIBUIDORA = 3,
  OPERADOR_LOGISTICO = 4,
  FARMACIA = 5,
  ESTABLECIMIENTO_ASISTENCIAL = 6,
  LAB_MEZCLA_INTRAVENOSA = 7,
  ESTABLECIMIENTO_ESTATAL = 8,
  BOTIQUIN_FARMACIA = 9,
  PAIS = 10,
}