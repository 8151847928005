import { useEffect } from 'react'
import moment from 'moment'

import { SalesOrderStatusI } from 'interfaces'

const Subheader = ({ pedido }) => {
  const subheaderItems = [
    { label: 'Unidad de negocio', value: pedido?.business_unit_name || '-' },
    { label: 'F. creación', value: pedido?.created_at ? moment(pedido.created_at).format('DD/MM/YYYY') : '-'},
    { label: 'Cliente', value: pedido?.customer_supplier_business_name || '-' },
    { label: 'F. autorización', value: pedido?.profitability_auth_sent_date ? moment(pedido.profitability_auth_sent_date).format('DD/MM/YYYY HH:mm') : '-' },
    { label: 'Cond. pago', value: pedido?.payment_condition_label || '-' },
    [SalesOrderStatusI.CANCELADO, SalesOrderStatusI.NO_AUTORIZADO].includes(pedido.sales_order_status_id)
      ? { label: 'Motivo', value: pedido?.revocation_notes || '-'}
      : null,
    { label: 'Observaciones', value: pedido?.observations || '-' },
  ]

  useEffect(() => {}, [pedido])

  return (
    <div className='mt-7'>
      {Boolean(pedido?.cancelado_parcial) &&
        <div className='d-flex justify-content-start align-items-start'>
          <span className='bi bi-code-square text-danger' />
          <strong className='m-0 ms-2 mb-2 text-danger'>Pedido cancelado parcialmente</strong>
        </div>
      }

      {subheaderItems.map((i, _idx) => {
        if (i) {
          return (
            <div key={`item-${_idx}`} className='d-flex justify-content-start align-items-start mt-2'>
              <div className='w-20'>
                <p className='m-0 p-0'>{i?.label}:</p>
              </div>

              <div className='w-80'>
                <p className='m-0 p-0 fw-bold'>{i?.value}</p>
              </div>
            </div>
          )
        }
      })}
    </div>
  )
}

export default Subheader