export enum RealUserI {
    ADMIN = 1,
    LEANDRO_FERNANDEZ = 20,
    MALENA_CABALLERO = 24,
    VANESA_BATAGLIA = 26,
}

export enum RoleI {
    ADMIN = 1,
    VENTAS = 2,
    CONTROL_RENTAS = 3,
    CONTROL_CRED = 4,
    COMPRAS = 5,
    OPERACIONES_GTE = 6,
    FARMACIA = 7,
    CONTROL_COMPRAS = 8,
    VENTAS_GTE = 9,
    OPERACIONES = 10,
    COORDINACION = 11,
    IMPUESTOS = 12,
    PRESENTACION = 13,
    OPERACIONES_ADMIN = 14,
    VENTAS_PERMISOS = 15,
}

export enum RoleNameI {
    ADMIN = 'Admin',
    VENTAS = 'Ventas',
    CONTROL_RENTAS = 'Control Rentas',
    CONTORL_CRED = 'Control Cred.',
    COMPRAS = 'Compras',
    OPERACIONES_GTE = 'Operaciones Gte.',
    FARMACIA = 'Farmacia',
    CONTROL_COMPRAS = 'Control Compras',
    VENTAS_GTE = 'Ventas Gte.',
    OPERACIONES = 'Operaciones',
    COORDINACION = 'Coordinación',
    IMPUESTOS = 'Impuestos',
    PRESENTACION = 'Presentación',
    OPERACIONES_ADMIN = 'Operaciones Admin',
    VENTAS_PERMISOS = 'Ventas Permisos',
}

export enum RoleNameMatchI {
    ADMIN = 'ADMIN',
    VENTAS = 'VENTAS',
    CONTROL_RENTAS = 'CONTROL_RENTAS',
    CONTORL_CRED = 'CONTROL_CRED',
    COMPRAS = 'COMPRAS',
    OPERACIONES_GTE = 'OPERACIONES_GTE',
    FARMACIA = 'FARMACIA',
    CONTROL_COMPRAS = 'CONTROL_COMPRAS',
    VENTAS_GTE = 'VENTAS_GTE',
    OPERACIONES = 'OPERACIONES',
    COORDINACION = 'COORDINACION',
    IMPUESTOS = 'IMPUESTOS',
    PRESENTACION = 'PRESENTACION',
    OPERACIONES_ADMIN = 'OPERACIONES_ADMIN',
    VENTAS_PERMISOS = 'VENTAS_PERMISOS',
}

export const RoleColorsI = {
    [RoleNameMatchI.ADMIN]: '#FF5733',              // Naranja fuerte
    [RoleNameMatchI.VENTAS]: '#33C1FF',             // Azul claro
    [RoleNameMatchI.CONTROL_RENTAS]: '#FF33A8',     // Rosa
    [RoleNameMatchI.CONTORL_CRED]: '#FFB533',       // Naranja suave
    [RoleNameMatchI.COMPRAS]: '#33FF57',            // Verde
    [RoleNameMatchI.OPERACIONES_GTE]: '#8E33FF',    // Púrpura
    [RoleNameMatchI.FARMACIA]: '#33FFA5',           // Verde agua
    [RoleNameMatchI.CONTROL_COMPRAS]: '#FFC533',    // Amarillo
    [RoleNameMatchI.VENTAS_GTE]: '#335CFF',         // Azul oscuro
    [RoleNameMatchI.OPERACIONES]: '#FF3385',        // Rojo rosado
    [RoleNameMatchI.COORDINACION]: '#FF8233',       // Naranja intermedio
    [RoleNameMatchI.IMPUESTOS]: '#FFD333',          // Amarillo dorado
    [RoleNameMatchI.PRESENTACION]: '#33FFD8',       // Verde menta
    [RoleNameMatchI.OPERACIONES_ADMIN]: '#A633FF',  // Morado vibrante
    [RoleNameMatchI.VENTAS_PERMISOS]: '#3389FF',    // Azul intermedio

}