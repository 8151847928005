import { useState, useEffect } from 'react'
import { Formik, Form } from 'formik'
import { Button, Modal } from 'react-bootstrap'

import { InputBS } from 'components'

import { waybillSchema } from './schema'

const UpdateWaybillModal = ({ values, show, onClose, onSave }) => {
  const [initialValues, setInitialValues] = useState({
    waybill_number: '',
  })

  const [waybillNumberError, setWaybillNumberError] = useState([])

  useEffect(() => {
    setInitialValues((prevInitialValues) => ({
      ...prevInitialValues,
      waybill_number: values.waybill_number,
    }))
  }, [values])

  if (!show) return null

  return (
    <Modal show={show} onHide={onClose} backdrop='static' centered>
      <Modal.Header closeButton>
        <Modal.Title>
          <h4>Modificar remito de la Orden de Ingreso #{values.in_order_id}</h4>
        </Modal.Title>
      </Modal.Header>

      <Modal.Body>
        <Formik
          initialValues={initialValues}
          validationSchema={waybillSchema}
          onSubmit={(data) => {
            onSave(data)
            onClose()
          }}
        >
          {({ values, errors }) => {
            return (
              <Form>
                <div className='row'>
                  <div className='col-12'>
                    <InputBS
                      id='waybill_number'
                      name='waybill_number'
                      label='Remito'
                      autoComplete="off"
                      minLength={12}
                      maxLength={20}
                      onBlur={() => !(values.waybill_number.length > 12 && values.waybill_number.length <= 20) ? setWaybillNumberError(true) : setWaybillNumberError(false)}
                    />
                    {waybillNumberError == true && <p className='text-danger'>El remito debe tener como mínimo 12 caracteres y no superar los 20 caracteres (incluyendo la letra)</p>}
                  </div>
                </div>

                <div className='separator my-8'></div>

                <div className='text-end'>
                  <Button type='button' variant='secondary' className='me-3' onClick={onClose}>
                    <span className='bi bi-slash-circle me-2' />Cancelar
                  </Button>

                  <Button type='submit' variant='primary'>
                    <i className='bi bi-save' />Modificar
                  </Button>
                </div>
              </Form>
            )
          }}
        </Formik>
      </Modal.Body>
    </Modal>
  )
}

export default UpdateWaybillModal