import { useEffect } from 'react'
import { useParams } from 'react-router-dom'
import { OverlayTrigger, Tooltip, Button } from 'react-bootstrap'
import { toast } from 'react-toastify'
import Swal from 'sweetalert2'

import { useOutOrders, useWaybill, useTransactionalDocuments } from 'hooks'

import { Tooltip as TooltipBS } from 'components'

import { labelColor } from '../'

import { OutOrderStatusI } from 'interfaces'

const Header = ({ outOrder, handleEventosModal, mostrarLoadingSimulation, badgeFactura, mostrarNroComprobante, mostrarDescargaFactura, labelTraza, anmatValidate }) => {
  const { id } = useParams()
  const { handlePrintWaybill, handlePrintTraza } = useOutOrders()
  const { handlePrintDispatchGuide, handleGetShipmentOrder } = useWaybill()
  const { handlePrintFactura } = useTransactionalDocuments()

  const descargarGuia = async () => {
    if (outOrder) {
      let ship = await handleGetShipmentOrder(outOrder.delivery?.id)

      if (ship && ship.data && ship.data.result) {
        ship = ship.data.result

        const resp = await Swal.fire({
          title: `Guía de despacho #${ship.id}`,
          html: `
            <div style='text-align: left;'>
              <p><strong>Dirección:</strong> ${ship.address_ship?.formatted_address || ''}</p>
              <p><strong>Cant. pedidos:</strong> ${outOrder.detail?.length}</p>
              <p><strong>Total:</strong> $ ${Number(outOrder.total).toFixed(2)}</p>
              <label for="swal-input1">Valor Declarado</label>
              <input id="swal-input1" class="swal2-input m-0" placeholder="Ingrese el valor declarado" type="text" value="0">
            </div>
          `,
          icon: "info",
          showDenyButton: true,
          denyButtonText: "Cancelar",
          confirmButtonText: "Imprimir",
          customClass: { confirmButton: 'btn btn-primary', denyButton: "btn btn-secondary" },
          reverseButtons: true,
          preConfirm: () => {
            return Swal.getPopup().querySelector('#swal-input1').value
          }
        })

        if (resp.isConfirmed) {
          const data = {
            valorDeclarado: resp.value,
          }

          try {
            const response = await handlePrintDispatchGuide(outOrder.delivery.id, data)
            if (response.status === 200) {
              toast.success('¡Guía de Despacho descargada!')

              const blob = await response.data

              const url = URL.createObjectURL(blob)
              const a = document.createElement('a')
              a.href = url
              a.download = `guia-despacho-${outOrder.delivery.id}.pdf`
              a.style.display = 'none'
              document.body.appendChild(a)
              a.click()
              URL.revokeObjectURL(url)
            } else {
              toast.error('Error al descargar la Guía de Despacho')
            }
          } catch (error) {
            toast.error(error.message);
          }
        }
      }
    }
  }

  const printWaybill = async () => {
    try {
      let copias = 2
      const response = await handlePrintWaybill(id, copias)

      // const arrayBuffer = await response.arrayBuffer()
      if (response.status === 200) {
        toast.success('Remito descargado!')

        // Crear un Blob a partir de la respuesta
        const blob = await response.data

        // Crear un enlace de descarga
        const url = URL.createObjectURL(blob)
        const a = document.createElement('a')
        a.href = url
        a.download = `remito-${id}.pdf`
        a.style.display = 'none'
        document.body.appendChild(a)
        a.click()
        URL.revokeObjectURL(url)

      } else {
        // Manejar errores si la solicitud no fue exitosa
        console.error('Error al descargar el PDF')
      }
    } catch (error) {
      toast.error(error.message)
    }
  }

  const printFactura = async () => {
    try {
      const response = await handlePrintFactura(outOrder.afip_codigo_interno_tipo_comprobante, outOrder.afip_id)

      // const arrayBuffer = await response.arrayBuffer()
      if (response.status === 200) {
        toast.success('¡Comprobante descargado!')

        // Crear un Blob a partir de la respuesta
        const blob = await response.data

        // Crear un enlace de descarga
        const url = URL.createObjectURL(blob)
        const a = document.createElement('a')
        a.href = url
        a.download = `factura-${outOrder.afip_id}.pdf`
        a.style.display = 'none'
        document.body.appendChild(a)
        a.click()
        URL.revokeObjectURL(url)
      } else {
        // Manejar errores si la solicitud no fue exitosa
        toast.error('Error al descargar la factura')
      }
    } catch (error) {
      toast.error(error.message)
    }
  }

  const printTraza = async () => {
    try {
      const response = await handlePrintTraza(outOrder.id)

      // const arrayBuffer = await response.arrayBuffer()
      if (response.status === 200) {
        toast.success('Comprobante descargado!')

        // Crear un Blob a partir de la respuesta
        const blob = await response.data

        // Crear un enlace de descarga
        const url = URL.createObjectURL(blob)
        const a = document.createElement('a')
        a.href = url
        a.download = `traza-${outOrder.id}.pdf`
        a.style.display = 'none'
        document.body.appendChild(a)
        a.click()
        URL.revokeObjectURL(url)
      } else {
        // Manejar errores si la solicitud no fue exitosa
        toast.error('Error al descargar el comprobante de traza')
      }
    } catch (error) {
      toast.error(error.message)
    }
  }


  useEffect(() => {}, [outOrder])

  return (
    <>
      <>
        <span className={`badge ${labelColor(outOrder?.out_order_status_id)} justify-content-center fw-normal`} style={{ width: "180px" }}>
          {outOrder.out_order_status_label}
        </span>

        <TooltipBS text='Ver eventos'>
          <Button variant='primary' className='ms-4 px-2 py-1' onClick={() => handleEventosModal(true)}>
            <span className='bi bi-clock-history fs-3' />
          </Button>
        </TooltipBS>

        <div className='mt-7'>
          <table>
            <tbody>
              <tr>
                <td style={{ width: "120px" }}>Cliente:</td>
                <td>
                  <span className='fw-bold'>{outOrder?.customer_supplier_id ? `[${outOrder.customer_supplier_id}] ` : ''}{outOrder?.customer_supplier_business_name}</span>
                </td>
              </tr>

              {Boolean(outOrder?.remito_para_terceros) && outOrder.id_tercero_para_remitir !== null &&
                <tr>
                  <td style={{ width: "120px" }}>Remitir a:</td>

                  <td>
                    <span className='fw-bold'>{outOrder?.tercero_para_remitir}</span>
                  </td>
                </tr>
              }

              <tr>
                <td style={{ width: "120px" }}>Cond. de pago:</td>
                <td>
                  <span className='fw-bold'>{outOrder?.payment_condition_label}</span>
                </td>
              </tr>

              {(outOrder.out_order_status_id == OutOrderStatusI.CANCELADO || outOrder.out_order_status_id == OutOrderStatusI.NO_AUTORIZADO) &&
                <tr>
                  <td style={{ width: "120px" }}>Motivo:</td>
                  <td><span className='fw-bold'>{outOrder?.revocation_notes}</span></td>
                </tr>
              }
            </tbody>
          </table>
        </div>
      </>

      {outOrder.wave_id &&
        <div>
          <table>
            <tbody>
              <tr>
                <td style={{ width: "120px" }}>Ola:</td>
                <td colSpan={2}><span className='fw-bold'>{outOrder.wave_id}</span> ({outOrder.wave_name})</td>
              </tr>

              {(outOrder.delivery && outOrder.dispatch_order_id) &&
                <tr>
                  <td style={{ width: "120px" }}>Despacho:</td>

                  <td colSpan={2}>
                    <span className='fw-bold'>{outOrder.dispatch_order_id}</span>
                    <span> / Transporte: {outOrder.delivery.shipping_carrier_name}{outOrder.delivery.shipping_carrier_service ? ` (${outOrder.delivery.shipping_carrier_service})` : ''}</span>
                  </td>
                </tr>
              }

              {outOrder.delivery &&
                <tr>
                  <td style={{ width: "120px" }}>Orden Envío:</td>

                  <td style={{ width: "200px" }}>
                    <span className='fw-bold'>{outOrder.delivery.id}</span>
                  </td>

                  <td>
                    <OverlayTrigger overlay={<Tooltip>Descargar guía de despacho</Tooltip>}>
                      <span onClick={descargarGuia}>
                        <i className='bi bi-download text-success ps-5 cursor-pointer' />
                      </span>
                    </OverlayTrigger>
                  </td>
                </tr>
              }

              {outOrder.remito_id &&
                <tr>
                  <td style={{ width: "120px" }}>Remito:</td>
                  <td style={{ width: "200px" }}>
                    <span className="fw-bold">{outOrder.remito_nro_cbt_completo}</span></td>
                  <td>
                    <OverlayTrigger overlay={<Tooltip>Descargar remito</Tooltip>}>
                      <span onClick={printWaybill}>
                        <i className='bi bi-download text-success ps-5 cursor-pointer' />
                      </span>
                    </OverlayTrigger>
                  </td>
                </tr>
              }

              <tr>
                <td>{outOrder.afip_codigo_interno_tipo_comprobante!=3 ? 'Factura:' : 'Nota de Crédito:'}</td>
                <td style={{ width: "200px" }}>
                  {mostrarLoadingSimulation && <l-line-spinner color="var(--bs-primary)" size="20"></l-line-spinner>}
                  {badgeFactura.mostrar && <span className='fw-bold'>{badgeFactura.label}</span>}
                  {mostrarNroComprobante && <span className='fw-bold'>{outOrder.afip_nro_cbt_completo}</span>}
                </td>
                <td>
                  {mostrarDescargaFactura &&
                    <OverlayTrigger overlay={<Tooltip>Descargar factura</Tooltip>}>
                      <span onClick={printFactura}>
                        <i className='bi bi-download text-success ps-5 cursor-pointer' />
                      </span>
                    </OverlayTrigger>
                  }
                </td>
              </tr>

              {(outOrder?.trazables && outOrder.remito_id && outOrder.remito_id > 0) &&
                <tr>
                  <td style={{ width: "120px" }}>Comp. traza:</td>
                  <td style={{ width: "200px" }} className='fw-bold'>
                    {labelTraza}
                    { outOrder.remito_nro_cbt_completo?.replace(/-/g, "") != anmatValidate?.remito && anmatValidate?.remito?.trim()!='' && anmatValidate?.remito!=null && 
                      <OverlayTrigger overlay={<Tooltip>El remito trazado no corresponde al remito actual</Tooltip>}>
                        <span>
                          <i className='bi bi-exclamation-circle text-danger ps-5 ' />
                        </span>
                      </OverlayTrigger>
                    }
                  </td>
                  <td>
                    {true &&
                      <OverlayTrigger overlay={<Tooltip>Descargar comprobante</Tooltip>}>
                        <span onClick={printTraza}>
                          <i className='bi bi-download text-success ps-5 cursor-pointer' />
                        </span>
                      </OverlayTrigger>
                    }
                  </td>
                </tr>
              }

              {outOrder?.in_request_ids &&
                <tr>
                  <td style={{ width: "120px" }}>Ped. Devolución:</td>
                  <td colSpan={2}><span className='fw-bold'>{outOrder?.in_request_ids}</span></td>
                </tr>
              }

              <tr>
                <td style={{ width: "120px" }}>Observaciones:</td>
                <td colSpan={2}><span>{outOrder?.observations}</span></td>
              </tr>

              <tr>
                <td style={{ width: "120px" }}>Obs. Despacho:</td>
                <td colSpan={2}><span>{outOrder?.dispatch_observations}</span></td>
              </tr>
            </tbody>
          </table>
        </div>
      }
    </>
  )
}

export default Header