import { useState, useEffect } from 'react'
import { useFormikContext } from 'formik'
import { toast } from 'react-toastify'
import { CustomTable, SelectField } from 'components'
import {helix} from 'ldrs'
import { useOutOrders } from 'hooks'
import { Button, OverlayTrigger, Tooltip } from 'react-bootstrap'
import Select from 'react-select';
import { useAuth } from 'app/modules/auth'
import { RoleI } from 'interfaces'

const customStyles = {
  control:  (base, state) => ({
    ...base,
    boxShadow: "none",
    '&:hover': { border: '1px solid #bbbbbb' },
    border: state.isFocused ? "1px solid #bbbbbb" : "1px solid #efefef",
    minHeight: '30px',      // Cambiar la altura mínima del control
    padding: '0', 
  }),
  valueContainer: (provided, state) => ({
    ...provided,
    padding: '2px 8px',     // Reducir el padding interno del contenedor de valor
  }),
  input: (provided, state) => ({
    ...provided,
    margin: '0',            // Eliminar márgenes en el input
    padding: '0',           // Ajustar el padding del input
  }),
  option: (provided, state) => ({
    ...provided,
    fontFamily: 'Arial, sans-serif',
    fontSize: '1rem',
  }),
  // singleValue: (provided, state) => ({
  //   ...provided,
  //   fontFamily: 'Arial, sans-serif',
  //   fontSize: '1rem',
  //   fontWeight: '500'
  // }),
};

const AnmatTab = ({ anmatValidate, cancelarTraza, retrazar, loadingTraza, out_order_id, outOrder }) => {

  const [eventDefault, setEventDefault] = useState(null);
  const [eventsAvailable, setEventsAvailable] = useState(null);
  const [eventToSend, setEventToSend] = useState(null);
  const { handleGetCodigosEventos, loading } = useOutOrders()

  const loadEventTypes = async ()=>{
    const _eventos = await handleGetCodigosEventos({out_order_id})
    if(_eventos?.data?.result){
        const {event_default, events_available} = _eventos.data.result;
        setEventsAvailable(events_available?.map(e=>({...e, value:e.id, label:e.event_desc})))
        setEventDefault(event_default)
    }
  }

  const handleRetrazar = ()=>{
    if(eventDefault){
      retrazar(null)
    } 
    else{
      if(!eventToSend){
        toast.error("Falta definir el evento de traza")
        return
      }
      retrazar(eventToSend)
    } 
  }

  const columns =[
    {
      Header: "Artículo",
      accessor: 'articulo',
    },
    {
      Header:  () => (<div style={{textAlign:"center"}}>Nro. serie</div>),
      id: 'serie',
      accessor: (row) =>(
        <div className="text-center">
          {row.serie}
        </div>
      ) 
    },
    {
      Header: () => (<div style={{textAlign:"center"}}>GTIN</div>),
      id: 'gtin',
      accessor: (row) => (
        <div className='text-center'>{row.gtin}</div>
      )
    },
    // {
    //   Header: () => (<div style={{textAlign:"center"}}>GTIN</div>),
    //   id: 'gtin',
    //   accessor: (row) => (
    //     <div className='text-center'>{row.gtin}</div>
    //   )
    // },
    {
      Header: () => (<div style={{textAlign:"center"}}>T. Ingreso</div>),
      id: 'transac_ingreso',
      accessor: (row) => (
        <div className='text-center'>{row.transac_ingreso}</div>
      )
    },
    {
      Header: () => (<div style={{textAlign:"center"}}>T. Egreso</div>),
      id: 'transac_egreso',
      accessor: (row) => (
        <div className='text-center'>{row.transac_egreso}</div>
      )
    },
    {
      Header: () => (<div style={{textAlign:"center"}}>Fc. Ingreso</div>),
      id: 'factura_ingreso',
      accessor: (row) => (
        <div className='text-center'>{row.factura_ingreso}</div>
      )
    },
    {
      Header: () => (<div style={{textAlign:"center"}}>Rem. Ingreso</div>),
      id: 'remito_ingreso',
      accessor: (row) => (
        <div className='text-center'>{row.remito_ingreso}</div>
      )
    },
    // {
    //   Header:  () => (<div style={{textAlign:"center"}}>Lote</div>),
    //   id:"lot_number",
    //   accessor: (row) =>(
    //     <div className="text-center">
    //       {row.lot_number}
    //     </div>
    //   ) 
    // },
    // {
    //   Header:  () => (<div style={{textAlign:"center"}}>Inf. prov.</div>),
    //   id:"informado_proveedor",
    //   accessor: (row) =>(
    //     row?.anmat_traced_at &&
    //       <>
    //         <div className={ `bi bi-check-lg text-success text-center`} style={{fontSize:"1.5rem"}}/>
    //       </>
    //   ) 
    // },
    {
      Header:  () => (<div style={{textAlign:"center"}}>Trazado</div>),
      id:"traced_status",
      accessor: (row) => {
        if(row){
              if(row.anmat_traced_error){
                return <>
                  <OverlayTrigger placement='top' overlay={<Tooltip id='tooltip-top'>{row.anmat_traced_error.split("GTIN")[0].trim()}</Tooltip>}>
                    <div className={ `bi bi-slash-circle text-danger text-center`} style={{fontSize:"1.5rem"}} />
                  </OverlayTrigger>
                </>
              }
              if(row.anmat_transaction_id){
                return <>
                  <div className={ `bi bi-check-lg text-success text-center`} style={{fontSize:"1.5rem"}} />
                </>
              }
              if(row.traced==0){
                return <>
                  <p>N/D</p>
                </>
              }
        }
      }
    },
    // {
    //   Header:  () => (<div style={{textAlign:"center"}}>En stock</div>),
    //   id:"stock_id",
    //   accessor: (row) =>(
    //     row?.stock_id &&
    //       <>
    //         <div className={ `bi bi-check-lg text-success text-center`} style={{fontSize:"1.5rem"}} />
    //       </>
    //   ) 
    // },
    // {
    //   Header: '',
    //   id: 'actions',
    //   className:'col-icons',
    //   accessor: (row) => (
    //     row?.anmat_traced_error && row?.anmat_traced_error != '' &&
    //       <>
    //         <div className={ `bi bi-check fs-2 text-success text-center`} />
    //       </>
        
    //   )
    // }
  ]

  useEffect(()=>{
    if(eventDefault && eventsAvailable){
      setEventToSend(eventsAvailable.filter(e=>e.id==eventDefault.id)[0])
    }
    else{
      setEventToSend(null)
    }
  }, [eventDefault, eventsAvailable])

  useEffect(()=>{
    helix.register()
    loadEventTypes();
    setEventDefault(null)
    setEventToSend(null)
  }, [])


  return (
    <>

          {anmatValidate?.items?
            <>
            <div className="row">
              <div className="col">

                
                  <table>
                    <tbody>
                        { anmatValidate?.remito && anmatValidate?.remito?.trim!='' && 
                          <>
                            <tr>
                              <td style={{width:"130px"}}>Fecha de traza:</td>
                              <td><b>{anmatValidate.fecha}</b></td>
                            </tr>
                            <tr>
                              <td style={{width:"130px"}}>Remito trazado:</td>
                              <td><b>{anmatValidate.remito}</b></td>
                            </tr>
                            <tr>
                              <td style={{width:"130px"}}>Nro. transacción:</td>
                              <td><b>{anmatValidate.transacANMAT}</b></td>
                            </tr>
                            <tr>
                              <td style={{width:"130px"}}>GLN origen:</td>
                              <td><b>{anmatValidate.glnOrigen}</b></td>
                            </tr>
                            <tr>
                              <td style={{width:"130px"}}>GLN destino:</td>
                              <td><b>{anmatValidate.glnDestino}</b></td>
                            </tr>  
                          </>
                        } 
                        <tr>
                          <td style={{width:"130px"}}>Evento:</td>
                          <td style={{width:"500px"}}>
                            <Select
                                options={eventsAvailable}
                                onChange={(e)=>{
                                    setEventToSend(e)
                                }}
                                value= {eventToSend || null}
                                styles={customStyles}
                                className={``}
                                isDisabled={ loading || eventDefault!=null}
                            />
                          </td>
                        </tr>                 
                    </tbody>
                  </table>
                

              </div>
            </div>
            
            <div className="row">
              { anmatValidate?.items?
                <div className="col text-end">
                  {anmatValidate?.remito && anmatValidate?.remito?.trim!='' && 
                    <Button variant='secondary' style={{width:"130px"}} className='me-3' onClick={cancelarTraza} disabled={loadingTraza}>Cancelar traza</Button>
                  }
                  {((anmatValidate.transacANMAT == null || anmatValidate.transacANMAT?.trim() == '') && outOrder?.remito_id != null) &&
                    <Button variant='primary' style={{width:"130px"}} onClick={handleRetrazar} 
                    disabled={loadingTraza }> Retrazar </Button>}
                </div>
                :
                <></>
              }
            </div>

            {/* {loadingTraza && 
                <div className='text-center'>
                  <div className='spinner-border text-success' />
                </div>
            } */}

            <CustomTable columns={columns} data={anmatValidate?.items ||[]} />


            
          </>
          :
          <>
            <p className='fst-italic'>La órden no tiene artículos trazados</p>
          </>
          }
  </>
  )
}

export default AnmatTab