import { useState, useRef, useCallback, useEffect } from 'react'
import { useParams, useNavigate, Link } from "react-router-dom"
import { Formik, Form } from 'formik'
import { toast } from 'react-toastify'

import { useClickOutside } from 'hooks'
import useTransactionalDocuments from 'hooks/useTransactionalDocuments'

import { DetailContainer } from 'containers'
import { InputBS, SelectField, Tooltip } from 'components'
import ContextMenu from 'components/ContextMenu'

import { Actions } from './partials'
import { initialValues, dataAdapter } from './constants'
import { TransactionalDocumentTypeI } from 'interfaces'
import routes from 'app/routing/routes'

const transactionalDocumentTypes = [
  { value: 1, label: "Factura" },
  { value: 2, label: "Nota de Débito" },
  { value: 3, label: "Nota de Crédito" },
  { value: 4, label: "Remito" },
]

const AdministrativeVoucherDetail = () => {
  const { id, transactional_document_type_id } = useParams()
  const navigate = useNavigate()
  const submitRef = useRef(null)
  const cancelRef = useRef(null)

  const [editing, setEditing] = useState(false)

  const { handleGetDocument } = useTransactionalDocuments()
  const [document, setDocument] = useState(initialValues)

  const getDocument = useCallback(async () => {
    try {
      const response = await handleGetDocument(transactional_document_type_id, id)
      const data = dataAdapter(response.data.result)
      setDocument(data)
    } catch (error) {
      toast.error(error.message)
      navigate(routes.ADMINISTRATIVE_VOUCHERS)
    }
  }, [id, transactional_document_type_id, handleGetDocument])

  useEffect(() => {
    getDocument()
  }, [getDocument])

  return (
    <DetailContainer
      title={`Detalle del documento #${id}`}
      buttons={<Actions editing={editing} handleEdit={setEditing} submitRef={submitRef} cancelRef={cancelRef} />}
    >
      <Formik
        initialValues={document}
        // validationSchema={{}}
        // onSubmit={handleSubmit}
        enableReinitialize
      >
        {({ values, errors }) => {
          return (
            <Form className='d-flex flex-column'>
              <div className='row my-8'>
                <div className='col-6'>
                  <InputBS id='customer_supplier_name' name='customer_supplier_name' label='Cliente/Proveedor' disabled />
                </div>

                <div className='col-3'>
                  <SelectField
                    id='transactional_document_type_id'
                    name='transactional_document_type_id'
                    label='Tipo'
                    options={transactionalDocumentTypes}
                    disabled
                  />
                </div>

                <div className='col-3'>
                  <InputBS id='date' name='date' type='date' label='Fecha' disabled />
                </div>
              </div>

              <div className='row mb-8'>
                <div className='col-6'>
                  <InputBS id='number' name='number' label='Remito' disabled />
                </div>
                <div className='col-6'>
                  <InputBS id='cae' name='cae' label='CAE' disabled />
                </div>
              </div>

              {values?.out_order_id && (
                <div className='row mb-8'>
                  <div className='col-3'>
                    <label className='form-label'>Orden de Egreso</label><br/>
                    <Tooltip text='Ir a OE'>
                      <Link to={`${routes.OUT_ORDERS}/${values.out_order_id}`}>OE #{values.out_order_id}</Link>
                    </Tooltip>
                  </div>

                  {values?.sales_order_id && (
                    <div className='col-3'>
                      <label className='form-label'>Pedido</label><br/>
                      <Tooltip text='Ir a pedido'>
                        <Link to={`${routes.VENTAS_PEDIDOS}/${values.sales_order_id}`}>Pedido #{values.sales_order_id}</Link>
                      </Tooltip>
                    </div>
                  )}
                </div>
              )}
            </Form>
          )
        }}
      </Formik>
    </DetailContainer>
  )
}

export default AdministrativeVoucherDetail