import { useState, useCallback, useEffect } from 'react'
import { useFormikContext } from 'formik'
import { Typeahead } from 'react-bootstrap-typeahead'
import { Button } from 'react-bootstrap'
import { toast } from 'react-toastify'

import { useAuth } from 'app/modules/auth'

import { PatientModal, ShipmentAddressModal } from 'containers'
import { Label, Textarea } from 'components'
import { CheckboxContainer, CheckboxV2, InputV2, SelectV2 } from 'components/v2'

import Modal from './Modal'

import { HomeDeliveryOptionI } from 'interfaces'
import { opcionesEntregaEspecial } from './utils'

import { RoleI, SalesOrderStatusI } from 'interfaces'

import routes from 'app/routing/routes'

const TabDestinatario = ({ loading, editing, obtenerPedido, setTab, serviceDestinatario }) => {
  const { currentUser } = useAuth()
  const { values, setFieldValue, touched, errors } = useFormikContext()

  const {
    loadingPacientes, obtenerPacientesDelCliente,
    loadingTerceros, obtenerClientesParaRemitir,
    loadingNuevoPaciente, crearYVincularPaciente,
    drofarTraza, obtenerDireccionesTrazaDrofar,
    clienteTraza, clienteEnvio, obtenerDireccionesTrazaCliente, obtenerDireccionesEnvioCliente,
    pacienteEnvio, obtenerDireccionesEnvioPaciente, direccionEnvioPaciente, crearActualizarDireccionPaciente,
  } = serviceDestinatario

  const [entregaEspecial, setEntregaEspecial] = useState(opcionesEntregaEspecial)
  const [pacientes, setPacientes] = useState([])
  const [modalPaciente, setModalPaciente] = useState(false)
  const [terceros, setTerceros] = useState([])
  const [direccionesTraza, setDireccionesTraza] = useState([])
  const [direccionesEnvio, setDireccionesEnvio] = useState([])
  const [direccionesPaciente, setDireccionesPaciente] = useState([])
  const [infoDireccionPaciente, setInfoDireccionPaciente] = useState(null)
  const [modalDireccion, setModalDireccion] = useState(false)
  const [modificarObservaciones, setModificarObservaciones] = useState(false)
  const [modificarObservacionesDespacho, setModificarObservacionesDespacho] = useState(false)

  const handleObtenerPacientesDelCliente = useCallback(async () => {
    try {
      const data = await obtenerPacientesDelCliente(values?.customer_supplier_id)
      setPacientes(data)
    } catch (error) {
      setPacientes([])
    }
  }, [values?.customer_supplier_id, obtenerPacientesDelCliente])

  const crearPaciente = async (paciente) => {
    try {
      await crearYVincularPaciente(values?.customer_supplier_id, paciente)
      setModalPaciente(false)
    } catch (error) {
      // Nada, ya se encarga la función crearYVincularPaciente
    } finally {
      handleObtenerPacientesDelCliente()
    }
  }

  const handleObtenerTercerosParaRemitir = useCallback(async () => {
    try {
      const data = await obtenerClientesParaRemitir(values?.customer_supplier_id)
      setTerceros(data)
    } catch (error) {
      setTerceros([])
    }
  }, [values?.customer_supplier_id, obtenerClientesParaRemitir])

  const cargarDireccionesTraza = useCallback(async () => {
    try {
      if (values?.home_delivery_option_id == HomeDeliveryOptionI.NO) {
        const direccionesTrazaCliente = await obtenerDireccionesTrazaCliente(values?.customer_supplier_id)
        setDireccionesTraza(direccionesTrazaCliente)
      } else if (
        values?.home_delivery_option_id == HomeDeliveryOptionI.CLIENTE ||
        values?.home_delivery_option_id == HomeDeliveryOptionI.PACIENTE ||
        values?.home_delivery_option_id == HomeDeliveryOptionI.FARMACIA
      ) {
        const direccionesTrazaDrofar = await obtenerDireccionesTrazaDrofar()
        setDireccionesTraza(direccionesTrazaDrofar)
      }
    } catch (error) {
      toast.error('Error al obtener las direcciones de trazabilidad')
    }
  }, [values?.home_delivery_option_id, obtenerDireccionesTrazaCliente, obtenerDireccionesTrazaDrofar])

  const cargarDireccionesEnvio = useCallback(async () => {
    try {
      if (values?.home_delivery_option_id == HomeDeliveryOptionI.CLIENTE) {
        const direccionesEnvioCliente = await obtenerDireccionesEnvioCliente(values?.customer_supplier_id)
        setDireccionesEnvio(direccionesEnvioCliente)
      } else if (values?.home_delivery_option_id == HomeDeliveryOptionI.PACIENTE) {
        if (values?.patient_id) {
          const direccionesEnvioPaciente = await obtenerDireccionesEnvioPaciente(values?.patient_id)

          setDireccionesEnvio(direccionesEnvioPaciente.map(d => {
            const { id, name, address: addr, gln } = d

            const textoGLN = gln ? ` | GLN: ${gln}` : ''
            let label = `${name} - ${addr.domicilio}, ${addr.localidad_completa}${textoGLN}`

            let direccion = {
              value: id,
              label: label
            }

            return direccion
          }))

          setDireccionesPaciente(direccionesEnvioPaciente)
        } else {
          toast.error('Seleccione un paciente para obtener sus direcciones de envío')
          return
        }
      }
    } catch (error) {
      toast.error('Error al obtener las direcciones de envío')
    }
  }, [values?.home_delivery_option_id, values?.patient_id, obtenerDireccionesEnvioCliente, obtenerDireccionesEnvioPaciente])

  /* Cambio de tipo de Entrega especial */
  useEffect(() => {
    if (Boolean(editing)) {
      // if (values?.home_delivery_option_id !== HomeDeliveryOptionI.NO) {
      //   setFieldValue('address_id_ship', '')
      // }

      if (Boolean(values?.to_patient)) {
        setEntregaEspecial(opcionesEntregaEspecial)
      } else {
        setFieldValue('patient_id', '')
        setEntregaEspecial(opcionesEntregaEspecial.filter(o => o.value !== 2)) // 2: A paciente

        if (values?.home_delivery_option_id == HomeDeliveryOptionI.PACIENTE) {
          setFieldValue('home_delivery_option_id', HomeDeliveryOptionI.NO)
        }
      }
    }
  }, [editing, values?.home_delivery_option_id, values?.to_patient])

  /* Obtener pacientes del Cliente */
  useEffect(() => {
    if (Boolean(editing) && Boolean(values.to_patient)) {
      handleObtenerPacientesDelCliente()
    }
  }, [editing, values.to_patient])

  /* Obtener terceros para remitir */
  useEffect(() => {
    if (Boolean(editing) && Boolean(values?.remito_para_terceros)) {
      handleObtenerTercerosParaRemitir()
    }
  }, [editing, values?.remito_para_terceros])

  /* Obtener direcciones (trazabilidad +- entrega) */
  useEffect(() => {
    if (Boolean(editing)) {
      cargarDireccionesTraza()

      if (
        values?.home_delivery_option_id !== HomeDeliveryOptionI.NO &&
        values?.home_delivery_option_id !== HomeDeliveryOptionI.FARMACIA
      ) {
        cargarDireccionesEnvio()
      }
    }
  }, [editing, values?.home_delivery_option_id])

  /* Obtener direcciones de traza y envío si el paciente cambia */
  useEffect(() => {
    if (Boolean(editing)) {
      cargarDireccionesEnvio()
    }
  }, [editing, values?.patient_id])

  return (
    <>
      <div className='row mb-8'>
        {/* FECHA DE ENTREGA */}
        <InputV2
          col='col-2'
          id='dispatch_date'
          name='dispatch_date'
          type='date'
          label='Fecha de entrega'
          disabled={!editing || loading}
        />

        <div className='col-6' />

        {/* CREAR PACIENTE */}
        {Boolean(values?.to_patient) &&
          <div className='col-4 text-end'>
            <Button
              type='button'
              variant='primary'
              onClick={() => setModalPaciente(true)}
              style={{ marginTop: '20px' }}
              disabled={loading}
            >
              <i className='bi bi-plus-circle'/>
              Crear paciente
            </Button>
          </div>
        }

        <PatientModal show={modalPaciente} onHide={() => setModalPaciente(false)} onSave={crearPaciente} saving={loadingNuevoPaciente} />
      </div>

      <div className='row mb-8'>
        {/* DESTINATARIO */}
        <CheckboxContainer col='col-3' htmlFor='requires_coordination' label='Destinatario'>
          <CheckboxV2 id='requires_coordination' htmlFor='requires_coordination' label='¿Requiere coordinación?' disabled={!editing || loading} />
          <CheckboxV2 id='to_patient' htmlFor='to_patient' label='¿Entrega a paciente?' disabled={!editing || loading} />
          <CheckboxV2 id='remito_para_terceros' htmlFor='remito_para_terceros' label='Remito a nombre de tercero' disabled={!editing || loading} />
        </CheckboxContainer>

        {/* ENTREGA ESPECIAL */}
        <SelectV2
          col='col-3'
          id='home_delivery_option_id'
          name='home_delivery_option_id'
          label='Entrega especial'
          options={entregaEspecial}
          disabled={!editing || loading}
        />

        {/* PACIENTE */}
        {Boolean(values?.to_patient) &&
          <>
            <div className={values?.patient_id ? 'col-4' : 'col-6'}>
              <Label label='Paciente' htmlFor='patient_id' />

              <Typeahead
                id='patient_id'
                name='patient_id'
                labelKey={opt => opt.display_name}
                options={pacientes || []}
                filterBy={['id', 'first_name', 'last_name', 'documentation', 'documentation_type_name', 'affiliate_number']}
                prompText={loadingPacientes ? 'Cargando...' : 'Buscar paciente'}
                searchText='Buscando...'
                placeholder={loadingPacientes ? 'Cargando...' : 'Buscar paciente'}
                emptyLabel='No se encontró el paciente'
                onChange={value => {
                  if (!value || value.length == 0) {
                    setFieldValue('patient_id', '')
                    setFieldValue('patient_fullname', '')
                    setFieldValue('address_id_ship', '')
                  } else {
                    let { id, display_name } = value[0]

                    setFieldValue('patient_id', id)
                    setFieldValue('patient_fullname', display_name)
                  }
                }}
                onBlur={e => {
                  if (e.target.value == '') {
                    setFieldValue('patient_id', '')
                    setFieldValue('patient_fullname', '')
                  }
                }}
                minLength={3}
                className={`${(errors?.patient_id && touched?.patient_id) ? 'border border-danger' : ''}`}
                defaultInputValue={values?.patient_id ? values?.patient_fullname : ''}
                disabled={!editing || loading || loadingPacientes}
              />
            </div>

            {values?.patient_id &&
              <div className='col-2' style={{ marginTop: '29px' }}>
                <a href={`${routes.PATIENTS}/${values?.patient_id}`} target="_blank" rel="noopener noreferrer">
                  Ver paciente
                </a>
              </div>
            }
          </>
        }

        {/* REMITO PARA TERCEROS */}
        {values?.remito_para_terceros &&
          <>
            {editing
              ?
                <SelectV2
                  col={`col-4${Boolean(values?.to_patient) ? ' mt-8' : ''}`}
                  id='id_tercero_para_remitir'
                  name='id_tercero_para_remitir'
                  options={terceros}
                  label='Tercero para remitir'
                  placeholder='Seleccionar tercero para remitir'
                  disabled={!editing || loading || loadingTerceros}
                />
              :
                <InputV2
                  col={`col-4${Boolean(values?.to_patient) ? ' mt-8' : ''}`}
                  id='tercero_para_remitir_nombre'
                  name='tercero_para_remitir_nombre'
                  label='Tercero para remitir'
                  disabled
                />
            }
          </>
        }
      </div>

      {/* DIRECCIÓN DE TRAZABILIDAD */}
      <div className='row my-8'>
        {editing
          ?
            <SelectV2
              col='col-12'
              id='address_id_traza'
              name='address_id_traza'
              label={(
                values?.home_delivery_option_id == HomeDeliveryOptionI.NO ||
                values?.home_delivery_option_id == HomeDeliveryOptionI.FARMACIA
              )
                ? 'Dirección de trazabilidad / entrega'
                : 'Dirección de trazabilidad'
              }
              options={direccionesTraza}
              placeholder={
                drofarTraza || clienteTraza
                  ? 'Cargando...'
                  : (
                    values?.home_delivery_option_id == HomeDeliveryOptionI.NO ||
                    values?.home_delivery_option_id == HomeDeliveryOptionI.FARMACIA
                  )
                    ? 'Seleccione una dirección de trazabilidad / entrega'
                    : 'Seleccione una dirección de trazabilidad'
              }
              disabled={!editing || loading || drofarTraza || clienteTraza}
            />
          :
            <InputV2
              col='col-12'
              id='direccion_traza'
              name='direccion_traza'
              label={(
                values?.home_delivery_option_id == HomeDeliveryOptionI.NO ||
                values?.home_delivery_option_id == HomeDeliveryOptionI.FARMACIA
              )
                ? 'Dirección de trazabilidad / entrega'
                : 'Dirección de trazabilidad'
              }
              disabled
            />
        }
      </div>

      {/* DIRECCIÓN DE ENVÍO */}
      {(
        values?.home_delivery_option_id == HomeDeliveryOptionI.CLIENTE ||
        values?.home_delivery_option_id == HomeDeliveryOptionI.PACIENTE
      ) &&
        <div className='row my-8'>
          {editing
            ?
              <SelectV2
                col={editing ? 'col-8' : 'col-12'}
                id='address_id_ship'
                name='address_id_ship'
                label='Domicilio de entrega'
                options={direccionesEnvio}
                placeholder={clienteEnvio || pacienteEnvio ? 'Cargando...' : 'Seleccione un domicilio de entrega'}
                disabled={!editing || loading || clienteEnvio || pacienteEnvio}
              />
            : <InputV2 col={editing ? 'col-8' : 'col-12'} id='direccion_ship' name='direccion_ship' label='Domicilio de entrega' disabled />
          }

          {/* Botones de edición y creación de dirección */}
          {editing && values?.home_delivery_option_id == HomeDeliveryOptionI.PACIENTE &&
            <div className='col-sm-auto pt-6 d-flex flex-wrap'>
              <Button
                type='button'
                variant='secondary'
                onClick={() => {
                  let direccion = direccionesPaciente.find(a => a.id == values?.address_id_ship)
                  setInfoDireccionPaciente(direccion)
                  setModalDireccion(true)
                }}
                className='me-4'
                disabled={loading || !values?.address_id_ship || values?.home_delivery_option_id != HomeDeliveryOptionI.PACIENTE}
              >
                <i className='bi bi-pencil-square' />
                Editar dirección
              </Button>

              <Button
                variant='primary'
                type='button'
                onClick={() => {
                  setInfoDireccionPaciente(null)
                  setModalDireccion(true)
                }}
              >
                <i className='bi bi-plus-circle' />
                Crear dirección
              </Button>
            </div>
          }
        </div>
      }

      {/* MODAL PARA CREAR/EDITAR DOMICILIO DE ENTREGA */}
      <ShipmentAddressModal
        show={modalDireccion}
        onClose={() => setModalDireccion(false)}
        values={infoDireccionPaciente}
        onSave={async (data) => {
          try {
            await crearActualizarDireccionPaciente(values?.patient_id, values?.address_id_ship, data)
            setModalDireccion(false)
            cargarDireccionesEnvio()
          } catch (error) {
            // Ya se encarga la función crearActualizarDireccionPaciente
          }
        }}
      />

      {/* OBSERVACIONES */}
      <div className='row my-8'>
        <div className='col-12'>
          <Label htmlFor='observations' label='Observaciones del pedido (se imprime en remito y factura)' />
          {
            !modificarObservaciones &&
            [SalesOrderStatusI.PENDIENTE_AUTORIZACION_RENTA, SalesOrderStatusI.PENDIENTE_AUTORIZACION_CREDITO].includes(values?.sales_order_status_id) &&
            currentUser.roles.some(r => [RoleI.ADMIN, RoleI.CONTROL_RENTAS, RoleI.CONTROL_CRED, RoleI.VENTAS, RoleI.VENTAS_GTE].includes(r.id)) &&
              <i
                className='bi bi-pencil-square text-primary fs-6 cursor-pointer ms-2'
                onClick={() => {
                  setModificarObservaciones(modificarObservaciones ? false : true)
                }}
              />
          }

          <Textarea id='observations' name='observations' disabled={!editing || loading} />
        </div>
      </div>

      <Modal show={modificarObservaciones} onClose={() => setModificarObservaciones(false)} obtenerPedido={obtenerPedido} />

      {/* OBSERVACIONES DE DESPACHO */}
      <div className='row mb-8'>
        <div className='col-12'>
        <Label htmlFor='dispatch_observations' label='Observaciones despacho (se imprime en guía de despacho)' />
          {
            !modificarObservacionesDespacho &&
            [SalesOrderStatusI.PENDIENTE_AUTORIZACION_RENTA, SalesOrderStatusI.PENDIENTE_AUTORIZACION_CREDITO].includes(values?.sales_order_status_id) &&
            currentUser.roles.some(r => [RoleI.ADMIN, RoleI.CONTROL_RENTAS, RoleI.CONTROL_CRED, RoleI.VENTAS, RoleI.VENTAS_GTE].includes(r.id)) &&
              <i
                className='bi bi-pencil-square text-primary fs-6 cursor-pointer ms-2'
                onClick={() => {
                  setModificarObservacionesDespacho(modificarObservacionesDespacho ? false : true)
                }}
              />
          }

          <Textarea id='dispatch_observations' name='dispatch_observations' disabled={!editing || loading} />
        </div>
      </div>

      <Modal dispatch={true} show={modificarObservacionesDespacho} onClose={() => setModificarObservacionesDespacho(false)} obtenerPedido={obtenerPedido} />
    </>
  )
}

export default TabDestinatario