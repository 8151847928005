import { useState, useCallback } from "react"
import { toast } from 'react-toastify'

import { useCustomerSuppliers, usePatients, useShipmentAddresses } from "hooks"

const opcionesDireccionTraza = (direccion, concatenarGLN = false) => {
  const { id, name, address: addr, gln } = direccion

  const textoGLN = concatenarGLN && gln ? ` | GLN: ${gln}` : ''

  return {
    value: id,
    label: `${name} - ${addr?.domicilio}, ${addr?.localidad_completa}${textoGLN}`
  }
}

export const useDestinatario = () => {
  const { handleGetCustomerSupplierPatients, handleGetClientesParaRemitir, handleAddCustomerSupplierPatient, handleGetCustomerSupplierShipmentAddresses } = useCustomerSuppliers()
  const { handleCreatePatient, handleGetPatientAddresses, handleAddPatientAddress, handleUpdatePatientAddress } = usePatients()
  const { handleGetShipmentAddresses } = useShipmentAddresses()

  const [loadingPacientes, setLoadingPacientes] = useState(false)
  const [loadingTerceros, setLoadingTerceros] = useState(false)
  const [loadingNuevoPaciente, setLoadingNuevoPaciente] = useState(false)
  const [drofarTraza, setDrofarTraza] = useState(false)
  const [clienteTraza, setClienteTraza] = useState(false)
  const [clienteEnvio, setClienteEnvio] = useState(false)
  const [pacienteEnvio, setPacienteEnvio] = useState(false)
  const [direccionEnvioPaciente, setDireccionEnvioPaciente] = useState(false)

  /**
   * @async Obtener pacientes del cliente
   * @param idCliente ID del Cliente
   * @returns {Promise<Array<any>>}
   */
  const obtenerPacientesDelCliente = useCallback(async (idCliente) => {
    setLoadingPacientes(true)

    try {
      const params = {
        is_enabled: 1,
      }
      const response = await handleGetCustomerSupplierPatients(idCliente, params)
      const data = Array.isArray(response.data.result)
        ? response.data.result.map(d => {
          let nombreCompleto = `${d.first_name} ${d.last_name}`
          let documento = d?.documentation ? ` | ${d.documentation_type_name}: ${d.documentation}` : ''
          let nroAfiliado = d?.affiliate_number ? ` | Nro. afiliado: ${d.affiliate_number}` : ''

          const dataPaciente = {
            ...d,
            display_name: `[${d.id}] ${nombreCompleto}${documento}${nroAfiliado}`,
          }

          return dataPaciente
        })
        : []

      return data
    } catch (error) {
      toast.error(error.message)
      throw error // Propaga el error
    } finally {
      setLoadingPacientes(false)
    }
  }, [handleGetCustomerSupplierPatients])

  /**
   * @async Obtener clientes para remitir (por default: solo activos, ordenado por 'id' ASC)
   * @param {number} idCliente ID del Cliente
   * @param query Parámetros de búsqueda/filtros
   * @returns {Promise<Array<any>>}
   */
  const obtenerClientesParaRemitir = useCallback(async (idCliente, query) => {
    setLoadingTerceros(true)

    try {
      const params = {
        ...query,
        id_cliente: idCliente,
        activo: 1,
        sortField: 'id',
        sortOrder: 'ASC',
      }
      const response = await handleGetClientesParaRemitir(params)
      const data = response.data.result.map(d => ({
        value: d.id_cliente_para_remitir,
        label: `[${d.id_cliente_para_remitir}] ${d.para_remitir}`,
      }))

      return data
    } catch (error) {
      toast.error(error.message)
      throw error // Propaga el error
    } finally {
      setLoadingTerceros(false)
    }
  }, [handleGetClientesParaRemitir])

  /**
   * Crear paciente  y vincularlo con el cliente
   * @async
   * @function
   * @param {number} idCliente - ID del Cliente
   * @param {any} dataPaciente - Información del paciente
   * @returns {Promise<any>}
   */
  const crearYVincularPaciente = useCallback(async (idCliente, dataPaciente) => {
    setLoadingNuevoPaciente(true)

    try {
      // Se crea el paciente
      const responsePaciente = await handleCreatePatient(dataPaciente)

      // Se vincula el paciente creado con el cliente
      const responseCliente = await handleAddCustomerSupplierPatient(idCliente, responsePaciente.id)

      toast.success(responseCliente.message)

      return
    } catch (error) {
      toast.error(error.message)
      throw error // Propaga el error
    } finally {
      setLoadingNuevoPaciente(false)
    }
  }, [handleCreatePatient, handleAddCustomerSupplierPatient])

  const obtenerDireccionesTrazaDrofar = useCallback(async () => {
    setDrofarTraza(true)

    try {
      let params = {
        is_ours: 1,
        has_traceability: 1,
        is_enabled: 1,
      }
      const response = await handleGetShipmentAddresses(null, null, params)
      const data = response.data.result.map(d => opcionesDireccionTraza(d, true))

      return data
    } catch (error) {
      toast.error(error.message)
      throw error // Propaga el error
    } finally {
      setDrofarTraza(false)
    }
  }, [handleGetShipmentAddresses])

  const obtenerDireccionesTrazaCliente = useCallback(async (idCliente) => {
    setClienteTraza(true)

    try {
      let params = {
        has_traceability: 1,
        is_enabled: 1,
      }
      const response = await handleGetCustomerSupplierShipmentAddresses(idCliente, params)
      const data = response.data.result.map(d => opcionesDireccionTraza(d, true))

      return data
    } catch (error) {

    } finally {
      setClienteTraza(false)
    }
  }, [handleGetCustomerSupplierShipmentAddresses])

  const obtenerDireccionesEnvioCliente = useCallback(async (idCliente) => {
    setClienteEnvio(true)

    try {
      let params = {
        is_enabled: 1,
      }
      const response = await handleGetCustomerSupplierShipmentAddresses(idCliente, params)
      const data = response.data.result.map(d => opcionesDireccionTraza(d, false))

      return data
    } catch (error) {
      toast.error(error.message)
      throw error // Propaga el error
    } finally {
      setClienteEnvio(false)
    }
  }, [handleGetCustomerSupplierShipmentAddresses])

  const obtenerDireccionesEnvioPaciente = useCallback(async (idPaciente) => {
    setPacienteEnvio(true)

    try {
      let params = {
        has_traceability: 0,
        is_enabled: 1,
      }
      const response = await handleGetPatientAddresses(idPaciente, params)
      const data = response.data.result

      return data
    } catch (error) {
      toast.error(error.message)
      throw error // Propaga el error
    } finally {
      setPacienteEnvio(false)
    }
  }, [handleGetPatientAddresses])

  const crearActualizarDireccionPaciente = useCallback(async (idPaciente, idDireccionEnvio, direccion) => {
    setDireccionEnvioPaciente(true)

    try {
      if (direccion?.id) {
        const response = await handleUpdatePatientAddress(idPaciente, idDireccionEnvio, direccion)
        toast.success(response.data.message)
      } else {
        const response = await handleAddPatientAddress(idPaciente, direccion)
        toast.success(response.message)
      }
    } catch (error) {
      toast.error(error.message)
      throw error // Propaga el error
    } finally {
      setDireccionEnvioPaciente(false)
    }
  }, [handleUpdatePatientAddress, handleAddPatientAddress])

  return {
    loadingPacientes,
    obtenerPacientesDelCliente,
    loadingTerceros,
    obtenerClientesParaRemitir,
    loadingNuevoPaciente,
    crearYVincularPaciente,
    drofarTraza,
    obtenerDireccionesTrazaDrofar,
    clienteTraza,
    obtenerDireccionesTrazaCliente,
    clienteEnvio,
    obtenerDireccionesEnvioCliente,
    pacienteEnvio,
    obtenerDireccionesEnvioPaciente,
    direccionEnvioPaciente,
    crearActualizarDireccionPaciente,
  }
}