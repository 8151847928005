import { Link } from 'react-router-dom';
import moment from 'moment';

import { currencyFormat } from 'utilities';
import routes from 'app/routing/routes';

import '../reports.css'

const useGetColumns = () => {
  let COLUMNS =  [
    {
      Header:  <div className="text-center">ID Pedido</div>,
      id: 'PedidoId',
      hasOrder:true,
      accessor: (row) => (
        <div className='text-center'>
          <Link to={`${routes.VENTAS_PEDIDOS}/${row.PedidoId}`}>
            {row.PedidoId}
          </Link>
        </div>
      ),
    },
    {
      Header:  <div className="text-center">Fecha</div>,
      id: 'PedidoFecha',
      accessor: (row) => (
        <div className='text-center'>
          {row.PedidoFecha ? moment(row.PedidoFecha).format("DD/MM/YYYY") : '-'}
        </div>
      )
    },
    {
      Header:  <div className="text-center">Fecha autorización</div>,
      id: 'PedidoFechaAutorizacion',
      accessor: (row) => (
        <div className='text-center'>
          {row?.PedidoFechaAutorizacion ? moment(row.PedidoFechaAutorizacion).format("DD/MM/YYYY") : '-'}
        </div>
      )
    },
    {
      Header:  <div className="text-center">Cliente</div>,
      id: 'cliente',
      accessor: (row) => (
        <div className=''>
          <strong className='m-0 mb-2'>[{row.ClienteId}] {row.ClienteNombre}</strong>
          <p className='m-0'>{row.TipoDocu}: {row.NroDocu}</p>
        </div>
      )
    },
    {
      Header: <div className="text-center">Tipo Entrega</div>,
      id: 'EntregaEspecial',
      accessor: (row) => (
        <div className='text-center'>
          <span className={`badge ${row.entrega_label_color} w-100 justify-content-center fw-normal`}>
            {row.EntregaEspecial}
          </span>
        </div>
      )
    },
    {
      Header: <div className="text-center">Remito</div>,
      id: 'NroRemito',
      className: 'col-comprobante',
      accessor: (row) => (
        <div className='text-center'>
          {row?.NroRemito ? row.NroRemito : '-'}
        </div>
      )
    },
    {
      Header: <div className='text-center'>F. remito</div>,
      id: 'FechaRemito',
      accessor: (row) => (
        <div className='text-center'>
          {row?.FechaRemito ? moment(row.FechaRemito).format('DD/MM/YYYY') : '-'}
        </div>
      ),
    },
    {
      Header: <div className='text-center'>Días despacho</div>,
      id: 'dias_despacho',
      accessor: (row) => (
        <div className='text-center'>
          {row.dias_despacho}
        </div>
      ),
    },
    {
      Header: <div className="text-center">Factura</div>,
      id: 'NroComp',
      className: 'col-comprobante',
      accessor: (row)=>(
        <div className="break-word text-center">
          {row?.NroComp ? row.NroComp : '-'}
        </div>
      )
    },
    {
      Header: <div className="text-center">F. factura</div>,
      id: 'FechaComprobante',
      accessor: (row)=>(
        <div className="break-word text-center">
          {row?.FechaComprobante ? moment(row.FechaComprobante).format('DD/MM/YYYY') : '-'}
        </div>
      )
    },
    {
      Header: <div className="text-end">Total</div>,
      id: 'importe_total',
      accessor: (row) => (
        <div className='text-end'>
          {row.total === 0 ? '' : currencyFormat.format(row.importe_total)}
        </div>
      ),
    },
    // {
    //   Header:  <div className="text-center">Vendedor</div>,
    //   id: 'Vendedor',
    //   accessor: (row) => (
    //     <div className='text-center'>
    //       {row.Vendedor}
    //     </div>
    //   )
    // },
    {
      Header:  <div className="text-center">Estado pedido</div>,
      id: 'EstadoPedido',
      accessor: (row) => (
        <span className={`badge ${row.label_color} w-100 justify-content-center fw-normal`}>
          {row.EstadoPedido}
        </span>
      )
    },
  ]

  return {
    COLUMNS,
  }

}

export default useGetColumns