import { useCallback } from 'react'
import { toast } from 'react-toastify'
import Swal from 'sweetalert2'

import { useAuth } from 'app/modules/auth'
import { useSalesOrders, usePedidos } from 'hooks'

import { mostrarAdvertencia } from './utils'
import { SalesOrderStatusI } from 'interfaces'

export const useActions = () => {
  const { currentUser } = useAuth()

  const {
    loading,
    handleUpdateSalesOrderStatus,
    handleCheckSalesOrderPVP,
    handleCreateOutOrder,
    handleCreatePartial,
    handleCancelSalesOrderSpecial,
    handleCancelacionParcial,
    handlePrintSalesOrder,
    handleCloneSalesOrder,
  } = useSalesOrders()

  const { handleFacturaAnticipadaPedido } = usePedidos()

  /**
   * @async Cancelar un pedido
   * @param idPedido ID del Pedido
   * @param idEstado ID del estado del Pedido
   * @returns {Promise<void>}
   */
  const cancelarPedido = useCallback(async (idPedido, idEstado) => {
    try {
      const resp = await mostrarAdvertencia({
        text: `¿Confirma la cancelación del pedido #${idPedido}?`,
        showDenyButton: true,
        denyButtonText: 'No, cerrar',
        confirmButtonText: 'Sí, cancelar',
        input: 'textarea',
        inputLabel: 'Motivo',
        inputErrorMessage: 'Por favor, completar motivo de cancelación',
      })

      if (resp.isConfirmed) {
        await handleUpdateSalesOrderStatus(idPedido, idEstado, { observaciones: resp.value })
        toast.success(`Pedido #${idPedido} cancelado`)
      }
    } catch (error) {
      toast.error(error.message)
      throw error // Propaga el error
    }
  }, [handleUpdateSalesOrderStatus])

  /**
   * @async Autorizar rentabilidad de un pedido
   * @param idPedido ID del Pedido
   * @returns {Promise<void>}
   */
  const autorizarRentabilidadPedido = useCallback(async (idPedido) => {
    try {
      const pvp = await handleCheckSalesOrderPVP(idPedido)

      let text = `¿Autoriza la rentabilidad del pedido #${idPedido}?`

      if (pvp.data.result.length !== 0) {
        let qty = pvp.data.result.length
        text = `El pedido #${idPedido} tiene (${qty}) detalle${qty > 1 ? 's' : ''} con PVP desactualizado, ¿quiere autorizar la rentabilidad igualmente?`
      }

      const resp = await mostrarAdvertencia({
        text: text,
        showDenyButton: true,
        denyButtonText: 'No, cerrar',
        confirmButtonText: 'Sí, autorizar',
      })

      if (resp.isConfirmed) {
        await handleUpdateSalesOrderStatus(idPedido, SalesOrderStatusI.PENDIENTE_AUTORIZACION_CREDITO)
        toast.success(`Rentabilidad del pedido #${idPedido} autorizada correctamente`)
      }
    } catch (error) {
      toast.error(error.message)
      throw error // Propaga el error
    }
  }, [handleUpdateSalesOrderStatus])

  /**
   * @async Autorizar crédito de un pedido
   * @param idPedido ID del Pedido
   * @returns {Promise<void>}
   */
  const autorizarCreditoPedido = useCallback(async (idPedido) => {
    try {
      const pvp = await handleCheckSalesOrderPVP(idPedido)

      let text = `¿Autoriza el crédito del pedido #${idPedido}?`

      if (pvp.data.result.length !== 0) {
        let qty = pvp.data.result.length
        text = `El pedido #${idPedido} tiene (${qty}) detalle${qty > 1 ? 's' : ''} con PVP desactualizado, ¿quiere autorizar el crédito igualmente?`
      }

      const resp = await mostrarAdvertencia({
        text: text,
        showDenyButton: true,
        denyButtonText: 'No, cerrar',
        confirmButtonText: 'Sí, autorizar',
        reverseButtons: true
      })

      if (resp.isConfirmed) {
        await handleCreateOutOrder(idPedido)
        toast.success(`Crédito del pedido #${idPedido} autorizado correctamente`)
      }
    } catch (error) {
      toast.error(error.message)
      throw error // Propaga el error
    }
  }, [handleCreateOutOrder])

  /**
   * @async No autorizar un Pedido (estado NO_AUTORIZADO)
   * @param idPedido ID del Pedido
   * @param renta (bool) -> (true): Rentabilidad ; (false): Crédito [Esto es para el mensaje que se va a mostrar]
   * @returns {Promise<void>}
   */
  const noAutorizarPedido = useCallback(async (idPedido, renta = true) => {
    try {
      const resp = await mostrarAdvertencia({
        text: `¿Confirma el rechazo de la autrozaición de ${renta ? 'rentabilidad' : 'crédito'} del pedido #${idPedido}?`,
        showDenyButton: true,
        denyButtonText: 'No, cancelar',
        confirmButtonText: 'Si, rechazar',
        input: 'textarea',
        inputLabel: 'Motivo',
        inputErrorMessage: 'Por favor, completar motivo de rechazo',
      })

      if (resp.isConfirmed) {
        await handleUpdateSalesOrderStatus(idPedido, SalesOrderStatusI.NO_AUTORIZADO, { observaciones: resp.value })
        toast.info('Autorización rechazada correctamente')
      }
    } catch (error) {
      toast.error(error.message)
      throw error // Propaga el error
    }
  }, [handleUpdateSalesOrderStatus])

  /**
   * @async Crear Orden de egreso a partir de un Pedido
   * @param idPedido ID del Pedido
   * @param {
   *    address_id_traza: number | null,
   *    address_id_ship: number | null,
   * } pedido Información del Pedido (tiene más datos)
   * @returns {Promise<void}
   */
  const crearOrdenEgreso = useCallback(async (idPedido, pedido) => {
    try {
      if (!pedido?.address_id_traza || !pedido?.address_id_ship) {
        toast.error('El domicilio de trazabilidad / entrega es obligatorio')
        return
      }

      const response = await handleCreateOutOrder(idPedido, currentUser.id)
      toast.success(`Orden de egreso #${response.data.id} creada correctamente`)
    } catch (error) {
      toast.error(error.message)
      throw error // Propaga el error
    }
  }, [handleCreateOutOrder])

  /**
   * @async Crear Orden de egreso parcial a partir de un Pedido
   * @param idPedido ID del Pedido
   * @param {
   *    detail_reserved_and_user: any
   * } pedido Información del Pedido (tiene más datos)
   * @returns {Promise<void>}
   */
  const crearOrdenEgresoParcial = useCallback(async (idPedido, pedido) => {
    try {
      const resp = await mostrarAdvertencia({
        text: `¿Confirma la creación de una Orden de egreso parcial con los artículos disponibles para el pedido #${idPedido}?`,
        showDenyButton: true,
        denyButtonText: 'No, cancelar',
        confirmButtonText: 'Sí, crear',
      })

      if (resp.isConfirmed) {
        const _reserva = pedido.detail_reserved_and_used.reduce((acc, val) => +acc + +val.qty_reserved, 0)

        if (_reserva <= 0) {
          toast.error(`El pedido #${idPedido} no tiene artículos reservados para crear una orden de egreso parcial`)
          return
        }

        const response = await handleCreatePartial(idPedido)
        toast.success(`Orden de egreso #${response.data.id} creada correctamente`)
      }
    } catch (error) {
      toast.error(error.message)
      throw error // Propaga el error
    }
  }, [handleCreatePartial])

  /**
   * @async Cancelación (especial) de un Pedido
   * @param idPedido ID del Pedido
   * @returns {Promise<void>}
   */
  const cancelacionEspecialPedido = useCallback(async (idPedido, anticipada = false) => {
    try {
      const resp = await mostrarAdvertencia({
        html: `Va a cancelar el <strong>Pedido #${idPedido}</strong>, ¿confirma?
          <p class='mt-2'>Esta cancelación también va a impactar en la Orden de egreso asociada al Pedido.</p>
          ${anticipada ? `<p class='mt-4 fst-italic'>Nota: Deberá hacer la NC manualmente si generó una factura anticipada` : ''}
        `,
        input: 'textarea',
        inputLabel: 'Motivo',
        inputErrorMessage: 'Por favor, completar motivo de cancelación',
        showDenyButton: true,
        denyButtonText: 'No, cerrar',
        confirmButtonText: 'Sí, cancelar',
      })

      if (resp.isConfirmed) {
        const response = await handleCancelSalesOrderSpecial(idPedido, { observaciones: resp.value })
        toast.info(response.data.message)
      }
    } catch (error) {
      toast.error(error.message)
      throw error // Propaga el error
    }
  }, [handleCancelSalesOrderSpecial])

  /**
   * @async Cancelación (parcial) de un Pedido
   * @param idPedido ID del Pedido
   * @returns {Promise<void>}
   */
  const cancelacionParcialPedido = useCallback(async (idPedido, anticipada = false) => {
    try {
      const resp = await mostrarAdvertencia({
        html: `Va a cancelar parcialmente el <strong>Pedido #${idPedido}</strong>, ¿confirma?
          <p class='mt-4'>La cancelación parcial puede llegar a cancelar las órdenes de egreso con el estado:</p>
          <p class='m-0 p-0 fw-bold'>En revisión</p>
          <p class='m-0 p-0 fw-bold'>Reserva parcial</p>
          <p class='m-0 p-0 fw-bold'>Coordinación de entrega</p>
          <p class='m-0 p-0 fw-bold'>Pendiente de preparación</p>
          ${anticipada ? `<p class='mt-4 fst-italic'>Nota: Deberá hacer la NC manualmente si generó una factura anticipada` : ''}
        `,
        input: 'textarea',
        inputLabel: 'Motivo',
        inputErrorMessage: 'Por favor, completar motivo de cancelación',
        showDenyButton: true,
        denyButtonText: 'No, cerrar',
        confirmButtonText: 'Sí, cancelar',
      })

      if (resp.isConfirmed) {
        const response = await handleCancelacionParcial(idPedido, { observaciones: resp.value })
        toast.info(response.data.message)
      }
    } catch (error) {
      toast.error(error.message)
      throw error // Propaga el error
    }
  }, [handleCancelacionParcial])

  /**
   * @async Imprimir pedido
   * @param idPedido ID del Pedido
   * @returns {Promise<void>}
   */
  const imprimirPedido = useCallback(async (idPedido) => {
    try {
      const response = await handlePrintSalesOrder(idPedido)

      toast.success('¡Pedido descargado!')

      // Crear un Blob a partir de la respuesta
      const blob = await response.data

      // Crear un enlace de descarga
      const url = URL.createObjectURL(blob)
      const a = document.createElement('a')
      a.href = url
      a.download = `pedido-${idPedido}.pdf`
      a.style.display = 'none'
      document.body.appendChild(a)
      a.click()
      URL.revokeObjectURL(url)
    } catch (error) {
      toast.error('Error al descargar el pedido')
      throw error // Propaga el error
    }
  }, [handlePrintSalesOrder])

  /**
   * @async Clonar pedido
   * @param idPedido ID del Pedido
   * @returns {Promise<void>}
   */
  const clonarPedido = useCallback(async (idPedido) => {
    try {
      await handleCloneSalesOrder(idPedido, currentUser.id)
      toast.success(`Pedido #${idPedido} clonado correctamente`)
    } catch (error) {
      toast.error(error.message)
      throw error // Propaga el error
    }
  }, [handleCloneSalesOrder])

  const facturaAnticipadaPedido = useCallback(async (idPedido) => {
    const resp = await Swal.fire({
      title: "Advertencia",
      html: `Va a generar la factura anticipada para el <strong>Pedido #${idPedido}</strong>, ¿está de acuerdo?`,
      icon: "warning",
      showDenyButton: true,
      denyButtonText: "No, cancelar",
      confirmButtonText: "Sí, facturar",
      customClass: { confirmButton: 'btn btn-primary', denyButton: "btn btn-secondary" },
      reverseButtons: true
    });

    if (resp.isConfirmed) {
      try {
        const response = await handleFacturaAnticipadaPedido(idPedido)
        toast.success(response.data.message)
      } catch (error) {
        toast.error(error.message)
        throw error // Propaga el error
      }
    }
  }, [handleFacturaAnticipadaPedido])

  return {
    loading,
    cancelarPedido,
    autorizarRentabilidadPedido,
    autorizarCreditoPedido,
    noAutorizarPedido,
    crearOrdenEgreso,
    crearOrdenEgresoParcial,
    cancelacionEspecialPedido,
    cancelacionParcialPedido,
    imprimirPedido,
    clonarPedido,
    facturaAnticipadaPedido,
  }
}