import { useEffect, useMemo, useState } from 'react'
import { useLocation, useNavigate, useParams } from 'react-router-dom'
import { Formik, Form } from 'formik'
import { Button, Dropdown } from 'react-bootstrap'
import { toast } from 'react-toastify';
import moment from 'moment';
import Swal from 'sweetalert2'
import { helix } from 'ldrs'
import { AccountBalanceOutlined, AssignmentOutlined, Inventory2Outlined } from '@mui/icons-material'

import { useAuth } from 'app/modules/auth';
import { useIncomeOrder, useGetIncomeOrderDetail, useGetRequestDetail, useOutOrders, useTransactionalDocuments } from 'hooks'

import { DetailContainer, TabsContainer } from 'containers'

import GeneralTab from './general/GeneralTab'
import ArticlesTab from './articles/ArticlesTab'
import { currentDate } from 'utilities';
import AnmatTab from './anmat/AnmatTab'

import { labelColor } from '../List'
import ConfirmReturnedModal from './partials/ConfirmReturnedModal';
import { formikInitialValues, order_status, schema } from './constants'

import { OrderStatusI, RoleI } from 'interfaces';
import routes from 'app/routing/routes'

const detallesIguales = (detalleOE, detalleDevolucion)=>{
    const sortedA = [...detalleOE].sort((a, b) => a.product_var_id - b.product_var_id);
    const sortedB = [...detalleDevolucion].sort((a, b) => a.product_var_id - b.product_var_id);

    if (sortedA.length != sortedB.length) return false;

    return sortedA.every((itemA, index) => {
        const itemB = sortedB[index];
        return +itemA.product_var_id == +itemB.product_var_id && +itemA.qty == +itemB.qty;
    });
}

const IncomeDetail = () => {
  const { id } = useParams()
  const { state } = useLocation()

  const { currentUser } = useAuth()

  const [initialValues, setInitialValues] = useState(formikInitialValues)
  const [editEnable, setEditEnable] = useState(false)
  const [hasAnmat, setHasAnmat] = useState(false)
  const [showConfirmReturned, setShowConfirmReturned] = useState(false);
  const { request } = useGetRequestDetail(state?.in_request_id)
  const {handleGetOutOrder, loading:loadingOutOrder, handleOutOrderGenerateInvoice} = useOutOrders();
  const {incomeOrder, refreshIncomeOrder} = useGetIncomeOrderDetail(id)
  const {handleDescartarDiferido, handleCreatePartialCreditNote, handleCreateCreditNote} = useTransactionalDocuments();
  const {
    loading,
    handleCreateIncomeOrderDetail,
    handleDeleteIncomeOrderDetail,
    handleUpdateIncomeOrderDetail,
    handleUpdateIncomeOrder,
    handleDuplicateIncomeOrderDetail,
    handleDeleteSerialIncomeOrderDetail,
    handleUpdateDetailStorageSpace,
    handleSetInvoice,
    handleSetWaybill,
    handleConfirmIncomeOrder,
    handleCancelarOrdenIngreso,
  } = useIncomeOrder()

  const navigate = useNavigate()
  const tab = state ? state.tab : 'general'

  const onHideConfirmReturned = ()=>{
    setShowConfirmReturned(false)
  }

  const sendToStock = async() => {
      // setShowConfirmReturned(true)

      let _outOrder = null;
      let _confirm = null;
      const {returned_request_reason, returned_request_reason_detail, returned_request_reason_sector} = request;
      
      let _observations = returned_request_reason ?  returned_request_reason + ' - ' :''
      _observations += returned_request_reason_detail ?  returned_request_reason_detail + ' - ' :''
      _observations += returned_request_reason_sector ?  returned_request_reason_sector :'';



      try {
        if (!incomeOrder.detail || incomeOrder.detail.length < 1) {
          toast.error("El pedido no tiene artículos para ingresar")
          return;
        }
        const inputValue = _observations;

        let swalData = {
          title: "Advertencia",
          text: "¿Confirma el envío de los artículos al stock?",
          icon: "warning",
          showDenyButton: true,
          denyButtonText: "No, cancelar",
          confirmButtonText: "Si, enviar a stock",
          customClass: { confirmButton: 'btn btn-primary', denyButton: "btn btn-secondary" },
          reverseButtons: true
        }
        if(incomeOrder.out_order_id!=null){ 
          swalData = {
            ...swalData,
            input: "textarea",
            inputValue,
            inputLabel: "Motivo NC",
            inputAttributes: {
              autocomplete: "off",          
            },
            inputValidator: (value) => {
              if (!value || value.trim() == '') {
                return "Por favor, completar motivo para la NC"
              }
            },
          }
        }

        const resp = await Swal.fire( swalData )

        if (resp.isConfirmed) {
          // primero manda a stock
          _confirm = await handleConfirmIncomeOrder(id);
          
          refreshIncomeOrder()
          
          // si es una devolucion y no tiró error, procede con la refacturacion o nota de crédito
          // si el handleConfirmIncomeOrder tiró error, hay que hacer el proceso de refacturacion o NC en la solapa anmat
          // "Forzar ingreso"
          if(incomeOrder.out_order_id){ 
            // Es devolucion
            _outOrder = await handleGetOutOrder(incomeOrder.out_order_id)

            if(_outOrder?.data?.result){
              const {afip_id, afip_estado, afip_codigo_interno_tipo_comprobante, afip_codigo_tipo_comprobante, detail:outOrderDetail} = _outOrder.data.result

              if(afip_estado=='SUCCESS' || afip_estado=='SUCCESS_WITH_OBSERVATIONS'){
                  if( afip_codigo_interno_tipo_comprobante==1){
                    //hacer nc
                    //afip_codigo_tipo_comprobante mayor a 200, manda fceAnulacion:true
                    const obTransport = {
                      in_order_id: incomeOrder.id,
                      fceAnulacion: afip_codigo_tipo_comprobante>200 ? true : false,
                      observaciones: resp.value,
                      items: incomeOrder.detail?.map(d=>({
                        product_var_id:d.product_var_id, 
                        qty:d.qty
                      }))
                    } 
                    if(detallesIguales(outOrderDetail, incomeOrder.detail_grouped)){
                      const {in_order_id, items, ...rest} = obTransport;
                      await handleCreateCreditNote(afip_codigo_interno_tipo_comprobante, afip_id, rest)
                    }
                    else{
                      await handleCreatePartialCreditNote(afip_codigo_interno_tipo_comprobante, afip_id, obTransport)
                    }
                    
                  }
                  else{
                    //refacturar
                  }
              }
              else if(afip_estado=='DEFERRED'){
                // es comprobante diferido, descartar y refacturar
                await handleDescartarDiferido(afip_id)
                await handleOutOrderGenerateInvoice(incomeOrder.out_order_id)
                if(detallesIguales(outOrderDetail, incomeOrder.detail)){
                  console.log("TOTAL")
                }
                else{
                  console.log("PARCIAL")
                // await handleCreatePartialCreditNote(afip_codigo_interno_tipo_comprobante, afip_id, obTransport)
                }
              }
            }
            else{
              toast.error("Error al obtener la órden de egreso para realizar la devolución")
              return;
            }

          }
          toast.success("El pedido se ingresó en Stock", { theme: "colored" })
        }
      } 
      catch (err) {
        if(_confirm){
          toast.error("El pedido se ingresó en Stock, pero falló la generación de comprobantes")
        }
        else{
          toast.error(err.message, {theme: "colored"})
        }
      }
      finally{
        refreshIncomeOrder();
      }
  }

  const actionCancel = async () => {
    const res = await Swal.fire({
      title: "Advertencia",
      // text: `¿Confirma la cancelación de la orden de ingreso #${id}?`,
      html: `
        <p class='m-0'>¿Confirma la cancelación de la <strong>Orden de ingreso #${id}</strong>?</p>
      `,
      icon: "warning",
      input: 'textarea',
      inputLabel: 'Motivo',
      inputValue: '',
      inputValidator: (value) => {
        if (!value) {
          return 'Completar el motivo de cancelación'
        }
      },
      showDenyButton: true,
      denyButtonText: "No, cerrar",
      confirmButtonText: "Si, cancelar",
      customClass: { confirmButton: 'btn btn-primary', denyButton: "btn btn-secondary"},
      reverseButtons: true,
    })

    if (res.isConfirmed) {
      try {
        const data = {
          revocation_notes: res.value
        }
        const response = await handleCancelarOrdenIngreso(id, data)

        toast.success(response.data.message)
      } catch (error) {
        toast.error(error.message)
      } finally {
        refreshIncomeOrder()
      }
    }
  }

  const availableActions = () => {
    let actions = []

    if (incomeOrder && incomeOrder.order_status_id == OrderStatusI.BORRADOR) {
      actions.push({
        label: "Enviar a stock",
        icon: "bi bi-boxes text-success",
        action: () => sendToStock()
      })
    }

    if ([OrderStatusI.BORRADOR, OrderStatusI.ERROR_TRAZA].includes(incomeOrder?.order_status_id)) {
      let authRoles = [RoleI.ADMIN, RoleI.OPERACIONES_GTE]

      if (currentUser.roles.some(r => authRoles.includes(r.id))) {
        actions.push({
          label: 'Cancelar',
          icon: "bi bi-x-circle text-danger",
          action: () => actionCancel()
        })
      }
    }

    return actions
  }

  const addDetail = async (data) => {
    try {
      const transport = { ...data, in_order_id: id }
      await handleCreateIncomeOrderDetail(transport)
      refreshIncomeOrder()
    } catch (err) {
      throw err
    }
  }

  const deleteDetail = async (art) => {
    try {
      const resp = await handleDeleteIncomeOrderDetail(art)
      refreshIncomeOrder()
    } catch (err) {
      throw err
    }
  }

  const deleteSerialDetail = async (art) => {
    try {
      const resp = await handleDeleteSerialIncomeOrderDetail(art)
      refreshIncomeOrder()
    } catch (err) {
      throw err
    }
  }

  const updateInvoice = async (supplier_invoice) => {
    try {
      const data = {
        ...supplier_invoice,
        id: id,
      }
      const response = await handleSetInvoice(data)
      toast.success(response.message)
      refreshIncomeOrder()
    } catch (error) {
      toast.error(error.message)
    }
  }

  const updateWaybill = async (waybill) => {
    try {
      const data = {
        ...waybill,
        id: id,
      }
      const response = await handleSetWaybill(data)
      toast.success(response.data.message)
      refreshIncomeOrder()
    } catch (error) {
      toast.error(error.message)
    }
  }

  const updateDetail = async (data) => {
    try {
      const transport = { ...data, in_order_id: id }
      const resp = await handleUpdateIncomeOrderDetail(transport)
      refreshIncomeOrder()
    } catch (err) {
      throw err
    }
  }

  const updateDetailStorageSpace = async (data) => {
    try{
      const transport = { ...data, in_order_id: id}
      const resp = await handleUpdateDetailStorageSpace(transport)
      refreshIncomeOrder()
    } catch (err) {
      throw err
    }
  }

  const duplicateDetail = async (data) => {
    try {
      const transport = { ...data, in_order_id: id }
      const resp = await handleDuplicateIncomeOrderDetail(transport)
      refreshIncomeOrder()
    } catch (err) {
      throw err
    }
  }

  const updateIncomeOrder = async (data) => {
    try {
      const response = await handleUpdateIncomeOrder(data)

      toast.success("Registro guardado correctamente")
      if (response) {
        navigate(`${routes.LOGISTIC_INCOME}`)
      }
    } catch (err) {
      toast.error(err.message)
    }
  }

  const handleSubmit = (values) => {
    const { id, addDetail, deleteDetail, deleteSerialDetail, updateDetail, updateDetailStorageSpace, duplicateDetail, refreshIncomeOrder, ...rest } = values

    const modDetail = rest.detail.map(art => {
      const { id, in_order_id, product_var_id, ...resto } = art
      return { ...resto, detail_id: id, var_id: product_var_id }
    })

    const updData = { ...rest, detail: modDetail }

    updateIncomeOrder({ in_order_id: id, ...updData })
  }

  const tabsContent =  [
    <GeneralTab editEnable={editEnable} income_order_id={id} updateInvoice={updateInvoice} updateWaybill={updateWaybill} disabled={!incomeOrder?.id || loading || loadingOutOrder} hasAnmat={hasAnmat} />,
    <ArticlesTab editEnable={editEnable} />,
    <AnmatTab editEnable={editEnable} refreshIncomeOrder={refreshIncomeOrder} request={request} />
  ]

  const tabs = [
    { value: 'general', label: "General", icon: <AssignmentOutlined />, show: true,  disabled:(loading || !incomeOrder?.id) },
    { value: 'articles', label: "Articulos", icon: <Inventory2Outlined />, show: true,  disabled:(loading || !incomeOrder?.id) },
    { value: 'anmat', label: "Anmat", icon: <AccountBalanceOutlined />, show: hasAnmat ? true:false,  disabled:(loading || !incomeOrder?.id) }
  ]

  useEffect(() => {

      if (incomeOrder) {
        
        const {
          id: in_order_id,
          supplier_id,
          supplier_name,
          distributor_id,
          distributor_name,
          payment_condition_label,
          supplier_invoice,
          supplier_invoice_date,
          carrier,
          waybill_number,
          shipment_address_id,
          notes,
          internal_notes,
          issue_date,
          detail,
          detail_grouped,
          in_request,
          storage_space_id,
          order_status_id,
          order_type_name,
          order_type_label
        } = incomeOrder

        const traced = detail.filter(d => d.product_var_traced == 1)
        if (traced.length > 0) {
          setHasAnmat(true)
        }

        const updatedDetailGrouped = detail_grouped.map((group) => ({
          ...group,
          series: detail
            .filter((item) => item.lot_number === group.lot_number &&  item.storage_space_id === group.storage_space_id && item.product_var_id === group.product_var_id)
            .map((item) => ({ id:item.id, serial_number: item.serial_number, in_order_id: id})),
          detail_id: detail
            .filter((item) => item.lot_number === group.lot_number
              && item.product_var_id === group.product_var_id
              && item.storage_space_id === group.storage_space_id
              && item.product_var_traced != 1
            )
            .map((item) => item.id)
        }));

        if (( incomeOrder.order_status_id == order_status.find(status => status.name === 'BORRADOR').id || 
              incomeOrder.order_status_id == order_status.find(status => status.name === 'ERROR_TRAZA').id)) {
          setEditEnable(true)
        } else {
          setEditEnable(false)
        }

        const _initial = {
            in_order_id,
            supplier_id: in_request.supplier_id,
            supplier_name,
            distributor_id,
            distributor_name,
            payment_condition_label,
            notes,
            internal_notes,
            order_status_id,
            income_date: currentDate ? moment(currentDate).format("YYYY-MM-DD") : '', //.format('DD/MM/YYYY'):'',
            issue_date: issue_date ? moment(issue_date).format("YYYY-MM-DD") : '', //.format('DD/MM/YYYY'):'',
            supplier_invoice,
            supplier_invoice_date: supplier_invoice_date ? moment(supplier_invoice_date).format("YYYY-MM-DD") : '',
            waybill_number,
            carrier: carrier || '',
            in_request_id: request?.id,
            // in_request_id:in_request_id,
            storage_space_id: storage_space_id ? storage_space_id : null,
            // default_storage_space_id:undefined,
            shipment_address_id,
            detail,
            detail_grouped: updatedDetailGrouped,
            addDetail,
            deleteDetail,
            deleteSerialDetail,
            updateDetail,
            updateDetailStorageSpace,
            duplicateDetail,
            refreshIncomeOrder,
            request_articles: request?.detail,
            order_type_name,
            order_type_label,
            order_type_id:in_request?.order_type_id,
            out_order_id:in_request?.out_order_id,
            sales_order_type_id:in_request?.sales_order_type_id,
            in_req_customer_supplier_id:in_request?.customer_supplier_id,
            created_at:in_request?.created_at
        }

        setInitialValues(_initial)

      }
  }, [incomeOrder])

  useEffect(() => {
    helix.register()
  }, [])

  return (
    <>
      {/* <ConfirmReturnedModal show={showConfirmReturned} onHide={onHideConfirmReturned}/> */}
      <Formik
        initialValues={initialValues}
        validationSchema={schema}
        onSubmit={handleSubmit}
        enableReinitialize
      >
        {({ values, errors, touched }) => (
          <Form className="d-flex flex-column">
            <DetailContainer
              title={'Orden de ingreso ' + id || ''}
              statusV2={incomeOrder &&
                <>
                  <div className={`badge ${labelColor(incomeOrder.order_status_id)} justify-content-center fw-normal`} style={{width:"150px"}}>
                    {incomeOrder.order_status_label}
                  </div>
                </>
              }
              buttons={
                <div className='d-flex mt-5'>
                  <Button
                    type="button"
                    variant="secondary"
                    style={{ height: "38px" }}
                    className='me-3'
                    disabled={loading || loadingOutOrder}
                    onClick={() => navigate(routes.LOGISTIC_INCOME)}
                  >
                    <i className="ki-duotone ki-left"/>
                    Volver
                  </Button>

                  {availableActions().length > 0 &&
                    <Dropdown>
                      <Dropdown.Toggle variant="primary" id="dropdown-basic"  disabled={loading || loadingOutOrder}>Acciones</Dropdown.Toggle>
                      <Dropdown.Menu style={{ width: "200px" }} >
                        {availableActions().map((data, index) => (
                          <Dropdown.Item key={index} onClick={() => {data.action()}} disabled={loading}>
                            <i className={`${data.icon} pe-3`}/>
                            {data.label}
                          </Dropdown.Item>
                        ))}
                      </Dropdown.Menu>
                    </Dropdown>
                  }
                </div>
              }
            >
              {(loading || !incomeOrder?.id || loadingOutOrder) && <l-helix color="var(--bs-primary)" style={{ position: "absolute", left: "50%", marginTop: "100px" }}></l-helix>}

              <TabsContainer
                initialValue={tab?tab:'general'}
                tabs={tabs}
                tabsContent={tabsContent}
              />
            </DetailContainer>
          </Form>
        )}
      </Formik>
    </>
  )
}

export default IncomeDetail