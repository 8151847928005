import { Link } from "react-router-dom"
import moment from 'moment'

import { Tooltip } from 'components'

import routes from "app/routing/routes"
import { currencyFormat } from "utilities"

const COLUMNS = [
  {
    Header: <div style={{ width: '70px' }}>Nro.</div>,
    id: 'IdOrdenEgreso',
    accessor: (row) => (
      <Tooltip text='Ver orden'>
        <Link to={`${routes.OUT_ORDERS}/${row.IdOrdenEgreso}`} target='_blank'>
          <strong>{row.IdOrdenEgreso}</strong>
        </Link>
      </Tooltip>
    )
  },
  {
    Header: <div style={{ width: '200px' }}>Cliente</div>,
    id: 'Cliente',
    accessor: (row) => (
      <Tooltip text='Ver cliente'>
        <Link to={`${routes.CUSTOMER_SUPPLIERS}/${row.IdCliente}`} target='_blank'>
          <strong>[{row.IdCliente}] {row.Cliente}</strong>
        </Link>
      </Tooltip>
    )
  },
  {
    Header: <div style={{ width: '150px' }}>Remito</div>,
    id: 'IdRemito',
    accessor: (row) => (
      <div>
        {row?.IdRemito && row?.Remito
          ?
            <>
              <p className='m-0'><strong>{row.Remito}</strong></p>
              <p className='m-0 mt-2'><i>Fecha: {moment(row?.FechaRemito).format('DD/MM/YYYY')}</i></p>
            </>
          : <i>Sin remito válido</i>
        }
      </div>
    )
  },
  {
    Header: <div style={{ width: '250px' }}>Dirección de entrega</div>,
    id: 'IdEnvio',
    accessor: (row) => (
      <div>
        <Tooltip text='Ver dirección'>
          <Link to={`${routes.SHIPMENT_ADDRESSES}/${row.IdEnvio}`} target='_blank'>
            <strong>{row.NombreEnvio}</strong>
          </Link>
        </Tooltip>

        <p className='m-0 mt-4'><strong>Domicilio:</strong> {row.DomicilioEnvio?.trim()}{row.CPEnvio?.trim() ? ` (CP: ${row.CPEnvio?.trim()})` : ''}</p>
        <p className='m-0 mt-4'><strong>Localidad:</strong> {row.LocalidadEnvio?.trim()}</p>
        <p className='m-0 mt-4'><strong>Provincia:</strong> {row.ProvinciaEnvio?.trim()}</p>
      </div>
    )
  },
  {
    Header: <div className='text-end' style={{ width: '120px' }}>Valor entregado</div>,
    id: 'ValorEntregado',
    accessor: (row) => (
      <div className='text-end'>
        {currencyFormat.format(row.ValorEntregado)}
      </div>
    )
  },
  {
    Header: <div className='text-end' style={{ width: '120px' }}>Valor devuelto</div>,
    id: 'ValorDevuelto',
    accessor: (row) => (
      <div className='text-end'>
        {currencyFormat.format(row.ValorDevuelto)}
      </div>
    )
  },
  {
    Header: <div className='text-end' style={{ width: '120px' }}>Valor final</div>,
    id: 'ValorFinal',
    accessor: (row) => (
      <div className='text-end'>
        {currencyFormat.format(row?.ValorFinal || 0)}
      </div>
    )
  },
  {
    Header: <div className='text-end' style={{ width: '120px' }}>Facturado consolidado</div>,
    id: 'FacturadoConsolidado',
    accessor: (row) => (
      <div className='text-end'>
        {currencyFormat.format(row?.FacturadoConsolidado || 0)}
      </div>
    )
  },
  {
    Header: <div className='text-end' style={{ width: '120px' }}>Facturado pendiente</div>,
    id: 'FacturadoPendiente',
    accessor: (row) => (
      <div className='text-end'>
        {currencyFormat.format(row?.FacturadoPendiente || 0)}
      </div>
    )
  },
  {
    Header: <div className='text-end' style={{ width: '120px' }}>Dif. Real</div>,
    id: 'DiferenciaReal',
    accessor: (row) => (
      <div className='text-end'>
        {currencyFormat.format(row?.DiferenciaReal || 0)}
      </div>
    )
  },
  {
    Header: <div className='text-end' style={{ width: '120px' }}>Dif. Real + Pend.</div>,
    id: 'DiferenciaRealPendiente',
    accessor: (row) => (
      <div className='text-end'>
        {currencyFormat.format(row?.DiferenciaRealPendiente || 0)}
      </div>
    )
  },
  {
    Header: <div className='text-center' style={{ width: '120px' }}>Estado</div>,
    id: 'EstadoControlGestion',
    accessor: (row) => (
      <div className='text-center'>
        <span className={`badge ${row.EstadoControlGestionColor} w-100 justify-content-center m-0 fw-normal`}>
          {row.EstadoControlGestion}
        </span>

        {row.IdUsuarioControlGestion && <p className='m-0 p-0 mt-2'>{row.UsuarioControlGestion}</p>}
      </div>
    )
  },
  {
    Header: <div className='text-center'>Obs.</div>,
    id: 'ObservacionControlGestion',
    accessor: (row) => (
      <div className='text-center'>
        <Tooltip text={row?.ObservacionControlGestion || <i>Sin observaciones</i>}>
          <span className='bi bi-info-circle text-primary' />
        </Tooltip>
      </div>
    )
  }
]

export {
  COLUMNS,
}