import { Link } from 'react-router-dom'
import { useIntl } from 'react-intl'
import moment from 'moment'

import { Tooltip } from 'components'

import { currencyFormat, formatTableDate } from 'utilities'
import routes from 'app/routing/routes'

export const useGetColumns = () => {
  const intl = useIntl()

  const COLUMNS = [
    {
      Header: intl.formatMessage({ id: 'COLUMN.OUT_ORDERS.ID' }),
      id: 'id',
      accessor: (row) => (
        <Link to={`${routes.OUT_ORDERS}/${row.id}`}>
          {row.id}
        </Link>
      ),
      hasOrder: true,
    },
    {
      Header: <div className='text-center'>Fecha creación</div>,
      id: 'created_at',
      accessor: (row) => (
        <p className='text-center m-0'>
          {moment(row.created_at).format('DD/MM/YYYY')}
        </p>
      ),
      hasOrder: true,
    },
    {
      Header: <div className='text-center'>Fecha entrega</div>,
      id: 'dispatch_date',
      accessor: (row) => (
        <p className='text-center m-0'>
          {row.dispatch_date ? moment(row.dispatch_date).format('DD/MM/YYYY') : '-'}
        </p>
      ),
    },
    {
      Header: <div className='text-center'>ID Pedido</div>,
      id: 'sales_order_id',
      accessor: (row) => (
        <div className='text-center'>
          <Link to={`${routes.VENTAS_PEDIDOS}/${row.sales_order_id}`}>
            {row.sales_order_id}
          </Link>
        </div>
      ),
      hasOrder: true,
    },
    {
      Header: intl.formatMessage({ id: 'COLUMN.OUT_ORDERS.CLIENT' }),
      id: 'customer_supplier_id',
      accessor: (row) => (
        <>
          <strong>{row.customer_supplier_business_name}</strong> - {row.customer_supplier_documentation_type} {row.customer_supplier_documentation}
        </>
      ),
    },
    {
      Header: 'Dirección',
      id: 'traza_address',
      accessor: (row) => (
        <>
          <strong>{row.traza_name}</strong> - {row.traza_address}, {row.traza_full_locality}
        </>
      )
    },
    {
      Header: <p className='text-center m-0'>{intl.formatMessage({ id: 'COLUMN.OUT_ORDERS.TOTAL_LINES' })}</p>,
      id: 'lines',
      accessor: (row) => (
        <p className='text-center m-0'>
          {row.lines === 0 ? '-' : row.lines}
        </p>
      ),
    },
    {
      Header: intl.formatMessage({ id: 'COLUMN.OUT_ORDERS.TOTAL' }),
      id: 'total',
      accessor: (row) => (
        <>
          {row.total === 0 ? '-' : currencyFormat.format(row.total)}
        </>
      ),
    },
    // {
    //   Header: <p className='text-center m-0'>{intl.formatMessage({ id: 'COLUMN.OUT_ORDERS.STATUS' })}</p>,
    //   id: 'out_order_status_id',
    //   accessor: (row) =>(
    //     <p className={`badge ${row.label_color} w-100 justify-content-center m-0 fw-normal`}>
    //       {row.out_order_status_label}
    //     </p>
    //   ),
    // },
    {
      Header: "",
      id: 'has_regrigeration',
      accessor: (row) => (
        <>
          {Boolean(row.has_refrigeration)
            ? <Tooltip text='Requiere frío'><span><i className='bi bi-snow text-primary fs-3'></i></span></Tooltip>
            : <span></span>
          }
        </>
      )
    }
  ]

  return {
    COLUMNS
  }
}