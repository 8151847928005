import service from "services/service"

const PATH = {
  egresosConsolidados: '/control-gestion/egresos-consolidados',
  estados: '/control-gestion/estados',
}

export class EgresosConsolidados {
  static obtenerEgresosConsolidados = async (params) => {
    const response = await service.get(PATH.egresosConsolidados, {
      params
    })

    return response
  }

  static actualizarEstadoControlGestion = async (id, data) => {
    const response = await service.post(`${PATH.egresosConsolidados}/${id}/estado-control-gestion`, data)
    return response
  }

  static actualizarObservacionControlGestion = async (id, data) => {
    const response = await service.post(`${PATH.egresosConsolidados}/${id}/observacion-control-gestion`, data)
    return response
  }

  static obtenerEstadosControlGestion = async (params) => {
    const response = await service.get(PATH.estados, {
      params
    })

    return response
  }
}