import { useState, useEffect } from "react"
import { Button, Form, FormControl, FormLabel, Modal, OverlayTrigger, Table, Tooltip } from "react-bootstrap"
import moment from 'moment'
import { helix } from 'ldrs'

import { useArticles } from "hooks";

import { currencyFormat, decimalFormatter } from "utilities";
import { calculosVenta } from "utilities/calculosVenta"

import { OrderTypeI } from "interfaces";
import './article.css';

const  ArticlesModal = ({ show, onClose, onSave, pedido }) => {
  const { loading, handleSearchArticle, handleGetArticles } = useArticles()
  const [articlesOptions, setArticlesOptions] = useState([])
  const [selectedRows, setSelectedRows] = useState([])
  const [txtSearch, setTxtSearch] = useState('')

  const handleKeyDown = async (event) => {

    if ((event.keyCode === 13 && event.target?.value?.length > 0) || (event.target?.name === 'btnSearch' && txtSearch.length > 0)) {
      const queryMD = {
        text: txtSearch,
        pageNumber: 1,
        pageSize: 1000,
        // add_stock: 1,
        // add_price: 1,
        // add_cost: 1,
        // business_unit_id: pedido.business_unit_id,
        // customer_suppliers_id: pedido.order_type_id == OrderTypeI.PEDIDO_EGRESO ?  pedido.customer_supplier_id : 1,
        customer_id: pedido?.customer_supplier_id || undefined, // Se usa para traer los descuentos del cliente, ya que customer_supplier_id se usa para obtener el stock
        // business_unit_id:pedido?.business_unit_id || undefined,
        // add_in_order_pending_qty: 1,
        // add_missing_stock: 1
      }

      const data = await handleSearchArticle({ params: queryMD })

      if (data && data.result && data.result.length) {
        const val = data.result
          .filter(art => pedido.order_type_id == OrderTypeI.PEDIDO_EGRESO
            ? (art.customer_suppliers_id == pedido.customer_supplier_id || art.customer_suppliers_id == 1 || art.customer_suppliers_id == null)
            : (art.customer_suppliers_id == 1 || art.customer_suppliers_id == null)
          )
          .map(r => ({
            ...r,
            profitability_rate: r?.profitability_rate || 0.00000,
            rentabilidad: false,
            selected: false,
            qty: r.unidades ? parseInt(r.unidades) : 1,
            boxes: 1,
            product_var:null
          }))

        /* Cargamos el product var para calculos de rentabilidad, costo, etc */  
        const queryMD = {
          ids: val.map(i => i.id).join(','),
          pageNumber: 1,
          pageSize: 1000,
          add_cost: 1,
          add_price: 1,
          // customer_id: pedido?.customer_supplier_id || undefined, // Se usa para traer los descuentos del cliente, ya que customer_supplier_id se usa para obtener el stock
          business_unit_id: pedido?.business_unit_id || undefined,
          // add_in_order_pending_qty: 1,
          // add_missing_stock: 1
        }
        const data2 = await handleGetArticles({ queryMD })

        if(data2?.result?.length>0){
          for(let articulo of val){
            articulo.product_var = data2.result.find(a=>a.id==articulo.id) || null
          }  
        }
        setArticlesOptions(val)
      }
      else{
        setArticlesOptions([])
      }
    }
  }

  const handleSave = async () => {
    if (articlesOptions.filter(a => Boolean(a.selected))?.length < 1) {
      return
    }

    const queryMD = {
      ids: articlesOptions.filter(a => Boolean(a.selected)).map(i => i.id).join(','),
      pageNumber: 1,
      pageSize: 1000,
      add_stock: 1,
      add_price: 1,
      add_cost: 1,
      // customer_id: pedido?.customer_supplier_id || undefined, // Se usa para traer los descuentos del cliente, ya que customer_supplier_id se usa para obtener el stock
      business_unit_id: pedido?.business_unit_id || undefined,
      // add_in_order_pending_qty: 1,
      // add_missing_stock: 1
    }
    const data = await handleGetArticles({ queryMD })

    if (data && data.result && data.result.length) {
      const ob = data.result

      const arts = ob.map(a => {
        const seleccionados = articlesOptions.filter(a => Boolean(a.selected))
        const actual = seleccionados.filter(s => s.id == a.id)[0]

        //const discount = Boolean(actual.rentabilidad) ? 0 : actual.discount
        //const profitability_rate = Boolean(actual.rentabilidad) ? actual.profitability_rate : 0
        const calcValues = calculosVenta(a.actual_pvp, actual.boxes, a.vat_rate, a.list_cost, actual.discount, null, 
          (actual.discount==0 || (actual.discount!=0 && actual.profitability_rate==0) ) ? false:true, actual.profitability_rate)
        const data = {
          product_var_id: a.id,
          name: a.name,
          line: null,
          pvp: a.actual_pvp,
          pvp_original: a.actual_pvp,
          list_cost: a.list_cost,
          qty: Number(actual.boxes),
          units: actual.qty,
          discount: parseFloat(actual.discount), //Boolean(actual.rentabilidad) ? 0 : parseFloat(actual.discount),
          vat: a.vat,
          vat_rate: a.vat_rate,
          vat_amount: calcValues.vat_amount,
          unit_vat_amount: calcValues.unit_vat_amount,
          price: calcValues.unit_price,
          profitability: calcValues.profitability,
          profitability_rate: calcValues.profitability_rate,
          subtotal: calcValues.subtotal ? parseFloat(calcValues.subtotal) : null,
          total: calcValues.total ? parseFloat(calcValues.total) : null,
        }

        return data
      })

      const invalid = arts.filter(a => a.qty === '' || !a.qty)
      if (invalid.length > 0) {
        return
      }

      const total = arts.reduce((acc, article) => acc + (article.total || 0), 0)

      onSave({ articles: arts, total: parseFloat(total) })
      setTxtSearch('')
      onClose()
    }
  }

  const handleQuantityChange = (productId, event) => {
    let value = event.target.value.replace(/\D/g, '')

    const updatedProducts = articlesOptions.map(
      product => product.id === productId
        ?
          {
            ...product,
            boxes: value,
            selected: value ? true : false
          }
        : product
    )

    setArticlesOptions(updatedProducts)
  }

  const handleDescuentoRenta = (productId) => {
    const updatedProducts = articlesOptions.map(product => product.id === productId ? { ...product, rentabilidad: !product.rentabilidad } : product)
    setArticlesOptions(updatedProducts)
  }

  const handleDiscountChange = (productId, e) => {
    let value = !e.target.value || isNaN(e.target.value) || e.target.value === '' ? '' : e.target.value

    const updatedProducts = articlesOptions.map(product => product.id === productId ? { ...product, discount: value } : product)

    setArticlesOptions(updatedProducts)
  }

  const discountChange = async(product, e)=>{
    let value = !e.target.value || isNaN(e.target.value) || e.target.value === '' ? '' : e.target.value
    if(value>=100){
      return 
    }

    if(product.product_var){
      let _precio = product.product_var.pvp * ((+1 - +value/100))
      let _profitability_rate = (+1 - (+product.product_var.list_cost / +_precio)) * 100

      const updatedProducts = articlesOptions.map(opt => opt.id === product.id ? { 
        ...opt, 
        profitability_rate: parseFloat(_profitability_rate).toFixed(5) ,
        discount: value,
      } : opt)

      setArticlesOptions(updatedProducts)  
    }
  }

  const profitabilityChange = async(product, e)=>{
        let value = !e.target.value || isNaN(e.target.value) || e.target.value === '' ? '' : e.target.value
        if(value>=100){
          return
        }
        if(product.product_var){
          let _precio = (+100  * +product.product_var.list_cost) / (+100 - +value)
          let _descuento = (+1 - +_precio/+product.product_var.pvp) * 100
 
          const updatedProducts = articlesOptions.map(opt => opt.id === product.id ? { 
            ...opt, 
            profitability_rate: value ,
            discount: parseFloat(_descuento).toFixed(5),
          } : opt)

          setArticlesOptions(updatedProducts)  
        }        
  }


  const handleProfitabilityChange = (product, e) => {
    let value = !e.target.value || isNaN(e.target.value) || e.target.value === '' ? '' : e.target.value

    const updatedProducts = articlesOptions.map(product => product.id === product.id ? { 
      ...product, 
      profitability_rate: value 
    } : product)

    setArticlesOptions(updatedProducts)
  }

  const handleSelectionChange = (productId) => {
    const updatedProducts = articlesOptions.map(product => product.id === productId ? { ...product, selected: !product.selected } : product)
    setArticlesOptions(updatedProducts)
  }

  useEffect(() => {
    if (show) {
      setArticlesOptions([])
      setSelectedRows([])
      setTxtSearch('')
      helix.register()
    }
  }, [show])

  if (!show) return null

  return (
    <Modal size="xl" show={show} onHide={onClose} backdrop='static' scrollable >
      <Modal.Header>
        <div style={{ width: '100%' }}>
          <Modal.Title>Agregar artículo(s) al pedido</Modal.Title>

          <div className="mt-6">
            <p className="mb-1">
              <strong>Unidad de negocio:</strong> {pedido.business_unit_name}
            </p>
            <p className="mb-0">
              <strong>Dueño:</strong> {pedido.order_type_id == OrderTypeI.PEDIDO_EGRESO ? pedido.customer_supplier_business_name : 'Drofar'}
            </p>
          </div>
        </div>
      </Modal.Header>

      <Modal.Body>
        <div className='row'>
          <div className='col-10'>
            <FormLabel>ID / Articulo / Droga</FormLabel>
            <FormControl
              type="text"
              onKeyDown={handleKeyDown}
              autoComplete="off"
              placeholder="ID o Nombre del artículo/droga"
              value={txtSearch}
              onChange={(e) => setTxtSearch(e.target.value)}
              autoFocus
            />
          </div>

          <div className='col mt-6'>
            <Button onClick={handleKeyDown} name="btnSearch">
              <i className="bi bi-search me-2" />
              Buscar
            </Button>
          </div>
        </div>

        <div className="row mt-10">
          <div className='col mt-5'>
            {loading && <l-helix color="var(--bs-primary)" style={{ position: "absolute", left: "50%", top: "180px" }}></l-helix>}

            <Table striped bordered hover>
              <thead>
                <tr>
                  <th>ID</th>
                  <th>Nombre</th>
                  <th className="text-center">Disp. Venta</th>
                  <th className="text-center">Precio</th>
                  <th className="text-center">Próx. Venc.</th>
                  <th className="text-center" style={{ width: '75px' }}>Cajas</th>
                  <th className="text-center" style={{ width: '75px' }}>Unidades</th>
                  <th className="text-center">Desc.</th>
                  <th className="text-center">Renta</th>
                  <th className="text-center" style={{ width: '50px' }}></th>
                </tr>
              </thead>

              <tbody>
                {articlesOptions.map(product => (
                  <tr key={product.id}>
                    <td className="align-middle">{product.id}</td>
                    <td className="align-middle">
                      {product.name}
                      {product?.observations
                        ?
                          <OverlayTrigger
                            overlay={
                              <Tooltip id="waves-print-tooltip">
                                <div className=''>
                                  <p>{product.observations}</p>
                                </div>
                              </Tooltip>
                            }
                          >
                            <span className="ms-5">
                              <i className="fa-regular fa-circle-question text-primary" />
                            </span>
                          </OverlayTrigger>
                        : null
                      }
                      {product.product_var?.en_falta === 1 && (
                        <div className="">
                          <OverlayTrigger
                            placement="top"
                            overlay={<Tooltip>Artículo en falta por el laboratorio</Tooltip>}
                          >
                            <i className='fa-regular fa-circle-question text-primary' />
                          </OverlayTrigger>
                        </div>
                      )}
                    </td>

                    <td className="align-middle text-center">
                      {+product.DisponibleVentas > 0 ? +product.DisponibleVentas : 0}

                      <OverlayTrigger
                        overlay={
                          <Tooltip width="300">
                            <div className=''>
                              <table className='table table-rounded table-striped'>
                                <thead>
                                  <tr className=' text-center'>
                                    <th>Disponible</th>
                                    <th>En Autorización</th>
                                    <th>Pedido Compra</th>
                                    <th>Faltante</th>
                                    <th>Reservado</th>
                                    <th>No disponible</th>
                                  </tr>
                                </thead>
                                <tbody>
                                  <tr className='align-middle text-center'>
                                    <td>{product.Disponible || 0}</td>
                                    <td>{product.PendienteAutorizacion || 0}</td>
                                    <td>{product.PendienteIngreso || 0}</td>
                                    <td>{product.FaltaStock || 0}</td>
                                    <td>{product.Reservado || 0}</td>
                                    <td>{product.NoDisponibile || 0}</td>
                                  </tr>
                                </tbody>
                              </table>
                            </div>
                          </Tooltip>
                        }
                      >
                        <span className="ms-5">
                          <i className="fa-regular fa-circle-question text-primary" />
                        </span>
                      </OverlayTrigger>
                    </td>

                    <td className='d-flex flex-row justify-content-between h-100'>
                      {currencyFormat.format(product.pvp)}

                      <OverlayTrigger
                        overlay={
                          <Tooltip width="300">
                            <div className=''>
                              <strong>Últ. Act. PP:</strong>
                              <p className="m-0">{product?.FechaActuPrecio ? moment(product.FechaActuPrecio).format('DD/MM/YYYY') : ''}</p>
                            </div>
                          </Tooltip>
                        }
                      >
                        <span className="ms-5">
                          <i className="fa-regular fa-circle-question text-primary" />
                        </span>
                      </OverlayTrigger>
                    </td>

                    <td className='align-middle text-center'>{product?.FechaVencimientoProxima ? moment(product.FechaVencimientoProxima).format("DD/MM/YYYY") : ""}</td>

                    <td className="align-middle">
                      <Form.Control
                        type="number"
                        maxLength={5}
                        value={product.boxes || ''}
                        onChange={(e) => handleQuantityChange(product.id, e)}
                        className={(product.selected && !product.boxes) ? 'border-danger' : ''}
                        style={{ width: '75px' }}
                      />
                    </td>

                    <td className="align-middle">
                      <Form.Control
                        type="number"
                        maxLength={5}
                        value={(product.boxes && product.qty)? product.boxes * product.qty : ''}
                        className={(product.selected && !product.qty) ? 'border-danger' : ''}
                        style={{ width: '75px' }}
                        disabled
                      />
                    </td>
                    <td className="">
                    <Form.Control type='text' className='form-control' value={product?.discount} onChange={e=>discountChange(product, e)} maxLength={9} />
                    </td>
                    <td className="">
                    <Form.Control type='text' className='form-control' value={product?.profitability_rate} onChange={e=>profitabilityChange(product, e)} maxLength={9} />
                      {/* <FormControl
                        type="text"
                        autoComplete="off"
                        maxLength={8}
                        value={product.rentabilidad
                          ? product?.profitability_rate || ''
                          : product?.discount || ''
                        }
                        onChange={(e) => {
                          if (product.rentabilidad) {
                            handleProfitabilityChange(product, e)
                          } else {
                            handleDiscountChange(product.id, e)
                          }
                        }}
                        className={''}
                        style={{ width: '80px' }} 
                      />*/}

                      {/* <Form.Check
                        type="switch"
                        checked={product.rentabilidad}
                        onChange={() => handleDescuentoRenta(product.id)}
                        className="chk-selector"
                        style={{ width: '20px', marginTop: '10px', marginLeft: '2px' }}
                      /> */}
                    </td>

                    <td className="align-middle text-center">
                      <Form.Check
                        type="checkbox"
                        checked={product.selected}
                        onChange={() => handleSelectionChange(product.id)}
                        className="chk-selector"
                        style={{ width: '20px' }}
                      />
                    </td>
                  </tr>
                ))}
              </tbody>
            </Table>
          </div>
        </div>
      </Modal.Body>

      <Modal.Footer style={{ borderTop: "0 none" }}>
        <Button variant="secondary" onClick={onClose} className="me-3" disabled={loading}>
          <i className="bi bi-slash-circle" />
          Cerrar
        </Button>

        <Button variant="primary" onClick={handleSave} disabled={loading}>
          <i className="bi bi-database-add me-2" />
          Agregar artículo
        </Button>
      </Modal.Footer>
    </Modal>
  )
}

export default ArticlesModal