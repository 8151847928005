import * as Yup from 'yup'

const formikInitialValues = {
  id: 0,
  name: '',
  reg: {},
  ab:{},
  reg_id:'',
  nom:'',
  product_type_id: null,
  refrigerated:0,
  imported:false,
  traced:false,
  score_line:'',
  score_line_cancelled:false,
  //cancel_date: false,
  presentation:'',
  ab_link:false,
  gtin:'',
  bar:'',
  sku:'',
  unit:'',
  distribution_channel:undefined,
  pot:'',
  form:undefined,
  via:undefined,
  control:undefined,
  vat:true,
  vat_rate:21,
  purchase_vat:true,
  purchase_vat_rate:21,
  profitablity_min:'',
  var_name:'',
  tam:null,
  purchase_add_cost:0,
  purchase_discount_base:0,
  purchase_discount_extra:0,
  psl:'',
  alc:'',
  manual_price_on:true,
  manual_price:{price:'', date_from:''},
  initial_cost_type:'Porcentual',
  manual_list_cost:{list_cost:"", date_from:"", date_to:""},
  customer_supplier_id:undefined,
  ab_pvp:'',
  ab_pvp_start:'',
  purchase_add_cost_type:0,
  purchase_add_cost:'',
  purchase_add_cost_percent:'',
  // name: '',
  // ab: null,
  // nom:'',
  // product_type: {},
  // refrigerated:0,
  // imported:undefined,
  // traced:undefined,
  // score_line:'',
  // score_line_cancelled:undefined,
  // presentation:'',
  // vat:undefined,
  // ab_link:false,
  // gtin:'',
  // bar:'',
  // sku:'',
  // unit:'',
  // distribution_channel:undefined,
  // pot:'',
  // form:undefined,
  // via:undefined,
  // control:undefined,
  // vat_rate:undefined,
  // profitablity_min:undefined,
  // var_name:'',
  // venta_descuento_drogueria:31.03448
}
const updateSchema = Yup.object().shape({
  id: Yup.number().required('Campo requerido'),
  name:Yup.string().required('Campo requerido'),
  
})

const psl = (actual_pvp, purchase_discount_base)=>{
    const _psl = +actual_pvp - (+actual_pvp * +purchase_discount_base /100);
    return parseFloat(_psl).toFixed(2);
}
const alc = (psl, purchase_discount_extra, list_cost)=>{
  // Costo de lista = (PSL - [bonif compra])*1,005 + (PSL/1,21[% costo asociado ]) --calculado
  const _alc = (+psl - (+purchase_discount_extra/100)) * 1.005 + (+psl/1.21 * (+list_cost) /100) ;
  return parseFloat(_alc).toFixed(2);
}
export {
  formikInitialValues,
  updateSchema,
  psl,
  alc
}

