import { useState, useEffect } from 'react'
import { useLocation, useParams } from 'react-router-dom'
import { Formik, Form } from 'formik'
import moment from "moment"
import { toast } from 'react-toastify'
import { helix } from 'ldrs'

import { useArticles, useGetArticleDetail } from 'hooks/Inventory'

import { DetailContainer } from 'containers'
import ParentProduct from './partials/ParentProduct'
import TabProductVar from './partials/TabProductVar'

import { vatOptions } from '../constants'
import { formikInitialValues, updateSchema } from './constants'
import { currentDate } from 'utilities'


const ArticleDetail = () => {
  const { id } = useParams()
  const [initialValues, setInitialValues] = useState(formikInitialValues)
  const location = useLocation();
  const { product_id } =  location.state;
  const { handleUpdateArticle } = useArticles()
  const { article, loading, refreshArticle } = useGetArticleDetail({ product_id, product_var_id: id }, false)

  const updateProduct = async (values) => {

    const _unit = values.unit?.toString().replace(/\D/g, '')

    if (!_unit || +_unit<=0) {
      toast.error("Unidades es un campo numérico obligatorio")
      return
    }

    const data = {
      id: values.id,
      product_id: product_id,
      name: values.name,
      reg_id: values.ab?.reg,
      score_line_cancelled: values.score_line_cancelled ? 1 : 0,
      score_line: values.score_line?.toString(),
      en_falta: values.en_falta ? 1 : 0,
      vat: values.vat,
      vat_rate: vatOptions.filter(v => v.value == values.vat)[0].percent,
      presentation: values.presentation,
      unit: values.unit,
      unit_type_id: values.unit_type?.value,
      refrigerated: values.refrigerated ? 1 : 0,
      traced: values.traced ? 1 : 0,
      customer_supplier_id: values.customer_supplier_id,
      notes: values.notes,
      via_id: values.via_id,
      control_id: values.control_id,
      snomed: values.snomed,
      action_id: values.action_id,
      imported: values.imported ? 1 : 0,
      size_id: values.size_id,
      pot: values.pot,
      pot_id: values.pot_id,
      form_id: values.form_id,
      sku: values.sku,
      gtin: values.gtin,
      bar: values.bar,
      profitablity_min: values.profitablity_min ? parseFloat(values.profitablity_min) : null,

      manual_price: values.manual_price_on
        ?
          {
            ...values.manual_price,
            date_from: values.manual_price.date_from ? moment(values.manual_price.date_from, 'DD/MM/YYYY').format("YYYYMMDD") : moment().format("YYYYMMDD")
          }
        : undefined,
      manual_list_cost: values.initial_cost_type == 1 // 1: 'Fijo'
        ?
          {
            ...values.manual_list_cost,
            // date_from: values.manual_list_cost.date_from ? moment(values.manual_list_cost.date_from).format("YYYYMMDD") : undefined,
            date_from: moment(currentDate).format("YYYYMMDD"),
            date_to: values.manual_list_cost.date_to ? moment(values.manual_list_cost.date_to).format("YYYYMMDD") : undefined
          }
        : undefined,
      purchase_discount_base: values.purchase_discount_base,
      purchase_vat: values.purchase_vat,
      purchase_vat_rate: vatOptions.filter(v => v.value == values.purchase_vat)[0].percent,
      purchase_discount_extra: values.purchase_discount_extra,
      purchase_add_cost: values.purchase_add_cost_type == 1 ? +values.purchase_add_cost : undefined, // 1: 'Fijo'
      purchase_add_cost_percent: values.purchase_add_cost_type == 1 ? undefined : +values.purchase_add_cost_percent, // 1: 'Fijo'
      // purchase_add_cost: +values.purchase_add_cost > 0 ? +values.purchase_add_cost : undefined,
      // purchase_add_cost_percent: +values.purchase_add_cost_percent > 0 ? values.purchase_add_cost_percent : undefined,
    }

    try {
      const response = await handleUpdateArticle(data)
      setInitialValues(initialValues)
      toast.success("Registro actualizado correctamente", { theme: "colored" })
    } catch (error) {
      console.log(error)
      toast.error(error.message, { theme: "colored" })
    } finally {
      setInitialValues(formikInitialValues)
      await refreshArticle()
    }
  }

  useEffect(() => {
    if (article) {
      console.log(article)
      if (article.ab) {
        const { reg, acc, upot, control, via, for: form, tam, nom, price, priceStart, pot } = article.ab;

        const _values = {
          ...initialValues,
          ...article,
          reg_id: reg,
          acc,
          upot,
          control,
          via,
          form,
          tam,
          nom,
          ab_link: true,
          parent_product: {
            id: article.product_id,
            maker_id: article.maker_id,
            family_id: article.family_id,
            maker_name: article.maker_name,
            family_name: article.family_name
          },
          price: price,
          price_start: priceStart ? moment(priceStart).format("DD/MM/YYYY") : null,
          ab_pvp: price,
          ab_pvp_start: priceStart ? moment(priceStart).format("DD/MM/YYYY") : null,
          unit: article.unit || '',
          purchase_add_cost_type: (article.purchase_add_cost && article.purchase_add_cost > 0) ? 1 : 0, // 1: 'Fijo'
          initial_cost_type: article.manual_list_cost ? 1 : 0,// 1: 'Fijo'
          manual_list_cost: article.manual_list_cost
            ?
              {
                ...article.manual_list_cost,
                date_from: article.manual_list_cost.date_from?moment(article.manual_list_cost.date_from).format("YYYY-MM-DD"):'',
                date_to: article.manual_list_cost.date_to?moment(article.manual_list_cost.date_to).format("YYYY-MM-DD"):''
              }
            : {},
          manual_price: article.manual_price_on
            ?
              {
                ...article.manual_price,
                date_from: moment(article.manual_price.date_from).format("DD/MM/YYYY")
              }
            : {
                price:"",
                date_from:moment().format("DD/MM/YYYY")
              },
          size_id: article.variationMed ? article.size_id : tam,
          action_id: article.variationMed ? article.action_id : acc,
          pot: article.variationMed ? article.pot : pot,
          pot_id: article.variationMed ? article.pot_id : upot,
          control_id: article.variationMed ? article.control_id : control,
          form_id: article.variationMed ? article.form_id : form,
          via_id: article.variationMed ? article.via_id : via,
        }

        setInitialValues(_values)
      } else {
        const _values_not_ab = {
          ...initialValues,
          ...article,
          initial_cost_type: article.manual_list_cost ? 1 : 0,// 1: 'Fijo'
          manual_list_cost: article.manual_list_cost
            ?
              {
                ...article.manual_list_cost,
                date_from: article.manual_list_cost.date_from?moment(article.manual_list_cost.date_from).format("YYYY-MM-DD"):'',
                date_to: article.manual_list_cost.date_to?moment(article.manual_list_cost.date_to).format("YYYY-MM-DD"):''
              }
            : {},
          purchase_add_cost_type: (article.purchase_add_cost && article.purchase_add_cost > 0) ? 1 : 0,// 1: 'Fijo'
          manual_price: article.manual_price_on
            ?
              {
                ...article.manual_price,
                date_from: moment(article.manual_price.date_from).format("DD/MM/YYYY")
              }
            :  {
                price:"",
                date_from:moment().format("DD/MM/YYYY")
              },
          parent_product: {
            id: article.product_id,
            maker_id: article.maker_id,
            family_id: article.family_id,
            maker_name: article.maker_name,
            family_name: article.family_name
          }
        }

        setInitialValues(_values_not_ab)
      }
    }
  }, [article, id])

  useEffect(() => {
    helix.register()
  }, [])

  return (
    <>
      <DetailContainer title={article?.name || "Editar Artículo"} status={"Activo"}>
        <Formik
          initialValues={initialValues}
          validationSchema={updateSchema}
          onSubmit={updateProduct}
          enableReinitialize
        >
          {({ errors, touched, values }) => (
            <Form className="d-flex flex-column">
              <ParentProduct />

              {loading
                ?
                  <>
                    <div className='m-10' style={{ position: "relative", height: "200px" }}>
                      <l-helix color="var(--bs-primary)" style={{ position: "absolute", left: "50%", top: "50px" }} />
                    </div>
                  </>
                :
                  <>
                    <TabProductVar />
                  </>
              }

              <button className="btn btn-primary align-self-end" type="submit" disabled={(!article?.name || loading)}>
                <i className={`bi ${!article?.name || loading ? 'bi-arrow-clockwise' : 'bi-save'} me-2`} />
                {(!article?.name || loading) ? 'Cargando...' : 'Guardar'}
              </button>
            </Form>
          )}
        </Formik>
      </DetailContainer>
    </>
  )
}

export default ArticleDetail