import { useState, useEffect } from 'react'
import { Formik } from 'formik'
import { Modal, Card, Button } from 'react-bootstrap'

import { ContactInfo } from 'components'

import { contactInitialValues, schema } from './constants'

const ContactModal = ({ show, onClose, values = contactInitialValues, onSave, saving, submodal = false }) => {
  const [initialValues, setInitialValues] = useState(contactInitialValues)

  useEffect(() => {
    setInitialValues({
      contact: {
        ...contactInitialValues.contact,
        ...values?.contact,
      }
    })
  }, [setInitialValues, values])

  if (!show) return null

  return (
    <Modal size='xl' show={show} onHide={onClose} backdrop='static' centered>
      <Modal.Header closeButton>
        <Modal.Title>{values.contact?.id ? `Editar datos de contacto` : 'Datos de contacto'}</Modal.Title>
      </Modal.Header>

      <Modal.Body>
        <Formik
          initialValues={initialValues}
          validationSchema={schema}
          onSubmit={onSave}
          enableReinitialize
        >
          {({ values, errors, handleSubmit }) => {
            return (
              <>
                <ContactInfo updating={saving} />

                <Card.Footer className='d-flex align-items-center justify-content-end'>
                  <Button type='button' variant='secondary' className='me-3' onClick={onClose} disabled={saving}>
                    <i className="bi bi-slash-circle" />
                    Cancelar
                  </Button>

                  <Button type='submit' variant='primary' onClick={handleSubmit} disabled={saving}>
                    <i className='bi bi-save me-2' />
                    Guardar
                  </Button>
                </Card.Footer>
              </>
            )
          }}
        </Formik>
      </Modal.Body>
    </Modal>
  )
}

export default ContactModal